import { Box, useDisclosure } from '@chakra-ui/react';
import { ColumnDefResolved, HeaderContext } from '@tanstack/react-table';
import { Loader } from 'Molecules';
import { NestedTable } from 'Molecules/NestedTable';
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { EditIcon } from 'Tokens/Icons/Function';
import { useCompanyType } from 'utils/hooks';
import { MetricConfigModal } from '../MetricConfigModal/MetricConfigModal';
import { MetricConfigModalParent } from '../MetricConfigModal/MetricConfigModalParent';
import { SelectedMetric } from 'containers/Esrs/EsrsAssessment.hooks';
import {
  DataCollectionRowEnum,
  MaterialMetricPerDisclosure,
  MetricTag,
  TableMetricData,
} from '../DataCollection.d';
import { BreakDownTag } from '../DataCollectionUtils';
import { DREnums } from '../../DisclosureRequirements';
import { BulkEditModal } from '../MetricConfigModal/BulkEditModal';
import { useMaterialMetricsPerDR } from '../DataCollection.hooks';
import { isMetricStateMaterial } from 'containers/Esrs/utils';
import {
  DataCollectionDataGatheringLevelCell,
  DataCollectionFrequencyCell,
  MetricRefOrderNumber,
  MetricShortTitleView,
  DataCollectionCheckboxCell,
  DataCollectionCheckboxHeader,
  DataCollectionSettingsAndLearnMoreCell,
} from './DisclosureRequirementMetricsTableUtils';
import { StickyToolbar } from 'Atoms';

export const DisclosureRequirementMetricsTable = ({
  disclosureRequirementRef,
  setSelectedMetric,
  onDrawerOpen,
  setShowAlert,
  disclosureRequirementType,
  parentEsrsAssessmentId,
  companyStandardId,
  parentStandardId,
  selectedRows,
  setSelectedRows,
  isStandardMandatory,
}: {
  disclosureRequirementRef: string;
  setSelectedMetric: (metric: SelectedMetric) => void;
  onDrawerOpen: () => void;
  setShowAlert: Dispatch<SetStateAction<boolean>>;
  disclosureRequirementType: string;
  parentEsrsAssessmentId: string | undefined;
  companyStandardId: string;
  parentStandardId: string;
  selectedRows: TableMetricData[];
  setSelectedRows: Dispatch<SetStateAction<TableMetricData[]>>;
  isStandardMandatory: boolean;
}) => {
  const [selectedMetricData, setSelectedMetricData] = React.useState<TableMetricData>();
  const {
    onOpen: onConfigModalOpen,
    isOpen: osConfigModalOpen,
    onClose: onConfigModalClose,
  } = useDisclosure();
  const { isGroup } = useCompanyType();

  const { disclosureMetricData, metricDataLoading } = useMaterialMetricsPerDR({
    disclosureRequirementRef,
    companyStandardId,
    parentStandardId,
    isGroup,
    isStandardMandatory,
  });

  const hasParentCompany = useMemo(() => !!parentEsrsAssessmentId, [parentEsrsAssessmentId]);

  const metrics: MaterialMetricPerDisclosure[] = useMemo(() => {
    const materialOnlyMetrics = disclosureMetricData?.assessableMetrics.filter((materialMetric) =>
      materialMetric.materialMetrics.some((mm) => isMetricStateMaterial(mm.isMaterial))
    );
    return (
      materialOnlyMetrics?.filter(
        (metric) =>
          !(
            metric.parentMetricsCount.aggregate?.count !== 0 &&
            metric.parentMetrics.some((parentMetric) =>
              materialOnlyMetrics?.find((mm) => mm.reference === parentMetric.parentMetricRef)
            )
          )
      ) ?? []
    );
  }, [disclosureMetricData]);

  const recurseMetricData = (
    metric: MaterialMetricPerDisclosure,
    tag?: MetricTag
  ): TableMetricData => {
    if (tag) {
      const { title, shortTitle, ...restOfMetric } = metric;
      const mappedSubRows =
        tag.subTags[0]?.materialTagValues.map((val) => ({
          parentMetric: metric,
          ...recurseMetricData(metric, {
            value: val.tagValue,
            type: DataCollectionRowEnum.tag,
            subTags: tag.subTags.slice(1),
            parentMetric: metric,
          }),
        })) ?? [];
      return {
        title: tag.value,
        shortTitle: tag.value,
        isChild: true,
        type: DataCollectionRowEnum.tag,
        subRows: mappedSubRows,
        ...restOfMetric,
      };
    }

    const tags = metric.materialMetrics.find(
      (mm) => mm.materialStandardId === companyStandardId
    )?.materialMetricTags;

    const mappedSubRows = tags?.length
      ? tags[0].materialTagValues.map((tagVal) => ({
          parentMetric: metric,
          ...recurseMetricData(metric, {
            value: tagVal.tagValue,
            type: DataCollectionRowEnum.tag,
            subTags: tags.slice(1),
            parentMetric: metric,
          }),
        }))
      : metric.childrenMetrics?.map((cMetric) => ({
          parentMetric: metric,
          ...recurseMetricData(cMetric.childMetric as MaterialMetricPerDisclosure),
        })) ?? [];

    return {
      ...metric,
      type: DataCollectionRowEnum.metric,
      isChild: (metric.parentMetricsCount?.aggregate?.count ?? 0) > 0,
      isExpanded: metric.childrenMetrics.some((cm) => !!cm.childMetric?.adminPanelTags.length),
      subRows: mappedSubRows,
    };
  };
  const tableRows = useMemo(
    () =>
      metrics.map((metric) => {
        return recurseMetricData(metric);
      }),
    [metrics]
  );

  const isMetricDisclosure = useMemo(
    () => disclosureRequirementType === DREnums.metric,
    [disclosureRequirementType]
  );

  const isSubsidiaryAndNonMetricDR = useMemo(
    () => !isMetricDisclosure && !isGroup,
    [isMetricDisclosure, isGroup]
  );

  const [showBulkEditor, setShowBulkEditor] = useState<boolean>(false);

  const columns: ColumnDefResolved<TableMetricData>[] = useMemo(
    () => [
      {
        // ignoring since I think this a type error from the lib
        // @ts-ignore
        header: (_: HeaderContext<TableMetricData, unknown>): React.JSX.Element => (
          <DataCollectionCheckboxHeader
            disclosureRequirementRef={disclosureRequirementRef}
            tableRows={tableRows}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
          />
        ),
        accessorKey: 'selectedRows',
        cell: ({ row }) => (
          <DataCollectionCheckboxCell
            row={row}
            disclosureRequirementRef={disclosureRequirementRef}
            tableRows={tableRows}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
          />
        ),
      },
      {
        header: 'Metric',
        accessorKey: 'shortTitle',
        enableSorting: false,
        meta: {
          width: '60%',
        },
        cell: ({ row }) => <MetricShortTitleView row={row} companyStandardId={companyStandardId} />,
      },
      {
        header: 'Ref.',
        accessorKey: 'reference',
        meta: {
          width: '8%',
        },
        cell: ({ row }) => <MetricRefOrderNumber row={row} />,
      },
      {
        header: 'Frequency',
        accessorKey: 'frequency',
        meta: {
          width: '10%',
        },
        cell: ({ row }) => (
          <DataCollectionFrequencyCell row={row} companyStandardId={companyStandardId} />
        ),
      },
      {
        header: 'Data gathering level',
        accessorKey: 'dataGathering',
        meta: {
          width: '14%',
        },
        cell: ({ row }) => (
          <DataCollectionDataGatheringLevelCell row={row} companyStandardId={companyStandardId} />
        ),
      },
      {
        header: 'Breakdown',
        accessorKey: 'breakdown',
        meta: {
          width: '10%',
        },
        cell: ({ row }) => {
          if (row.original.type === DataCollectionRowEnum.metric)
            return (
              <BreakDownTag
                metric={row.original}
                isGroupCompany={isGroup}
                companyStandardId={companyStandardId}
                parentStandardId={parentStandardId}
              />
            );
        },
      },
      {
        header: '',
        accessorKey: 'settings',
        meta: {
          width: '5%',
        },
        cell: ({ row }) => (
          <DataCollectionSettingsAndLearnMoreCell
            row={row}
            companyStandardId={companyStandardId}
            parentStandardId={parentStandardId}
            isSubsidiaryAndNonMetricDR={isSubsidiaryAndNonMetricDR}
            onDrawerOpen={onDrawerOpen}
            setSelectedMetric={setSelectedMetric}
            setSelectedMetricData={setSelectedMetricData}
            onConfigModalOpen={onConfigModalOpen}
          />
        ),
      },
    ],
    [
      isGroup,
      isMetricDisclosure,
      selectedRows,
      tableRows,
      parentStandardId,
      companyStandardId,
      disclosureRequirementRef,
    ]
  );

  const filteredColumns = useMemo(() => {
    if (isSubsidiaryAndNonMetricDR) return columns.filter((c) => c.accessorKey !== 'selectedRows');
    return columns;
  }, [columns]);

  const defaultExpadedRows = useMemo(() => {
    const expandedRows: Record<string, boolean> = {};
    tableRows.forEach((row, index) => (expandedRows[index] = row.isExpanded ?? false));
    return expandedRows;
  }, [tableRows]);

  useEffect(() => {
    if (Object.values(defaultExpadedRows).includes(true)) setShowAlert(true);
  }, [defaultExpadedRows]);

  if (metricDataLoading) return <Loader />;

  return (
    <Box w="100%" bg="bg.default" borderRadius="8px">
      <NestedTable
        columns={filteredColumns}
        data={tableRows ?? []}
        defaultExpanded={defaultExpadedRows}
        headerBorderColor="transparent"
      />

      {isGroup ? (
        <MetricConfigModalParent
          isOpen={osConfigModalOpen}
          onClose={onConfigModalClose}
          selectedMetricData={selectedMetricData}
          isMetricDisclosure={isMetricDisclosure}
          companyStandardId={companyStandardId}
          parentStandardId={parentStandardId}
        />
      ) : (
        <MetricConfigModal
          isOpen={osConfigModalOpen}
          onClose={onConfigModalClose}
          selectedMetricData={selectedMetricData}
          hasParentCompany={hasParentCompany}
          isMetricDisclosure={isMetricDisclosure}
          companyStandardId={companyStandardId}
          parentStandardId={parentStandardId}
        />
      )}

      {!!selectedRows.length && (
        <StickyToolbar
          onClick={() => setShowBulkEditor(true)}
          count={selectedRows.length}
          onClose={() => setSelectedRows([])}
          actionText="Edit"
          actionIcon={<EditIcon color="inherit" />}
        />
      )}

      <BulkEditModal
        isOpen={showBulkEditor}
        onClose={() => {
          setShowBulkEditor(false);
          setSelectedRows([]);
        }}
        selectedRows={selectedRows}
        isGroup={isGroup}
      />
    </Box>
  );
};

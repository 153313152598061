import { sumBy } from 'lodash';
import { RowFormat, SmallTableData } from './table-generator';
import {
  FinancialsPerType,
  FinancialsType,
  ResultsByActivityAndSC,
  enablingTagGoesToAdaptation,
  getProportion,
  getResultsByActivity,
} from './TaxonomyTableGenerator.hooks';
import { getEligibleAlignedActivities } from './TaxonomyTableActivities';
import { CompanyAssessmentResults, ObjectiveKeyEnum } from 'models';
import { checkIfAlwaysEnablingActivity } from 'utils/scores/taxonomyScore';

export const getAlignedEnabling = (eligibleAlignedRows: RowFormat[]): RowFormat => {
  const enablingAbsolute = sumBy(eligibleAlignedRows, (row) =>
    row.enabling === 'E' ? row.absolute : 0
  );
  const enablingProportion = sumBy(eligibleAlignedRows, (row) =>
    row.enabling === 'E' ? row.proportion : 0
  );

  const getTotalSubstantialContribution = (objectiveKey: string): { financial: number } => {
    return {
      financial: sumBy(eligibleAlignedRows, (row) =>
        row.enabling === 'E' ? row[objectiveKey]?.financial ?? 0 : 0
      ),
    };
  };

  return {
    absolute: enablingAbsolute,
    proportion: enablingProportion,
    enabling: 'E',
    mitigationSC: getTotalSubstantialContribution('mitigationSC'),
    adaptationSC: getTotalSubstantialContribution('adaptationSC'),
    waterSC: getTotalSubstantialContribution('waterSC'),
    circularSC: getTotalSubstantialContribution('circularSC'),
    pollutionSC: getTotalSubstantialContribution('pollutionSC'),
    biodiversitySC: getTotalSubstantialContribution('biodiversitySC'),
  };
};

export const getAlignedTransitional = (eligibleAlignedRows: RowFormat[]): RowFormat => {
  const transitionalAbsolute = sumBy(eligibleAlignedRows, (row) =>
    row.transitional === 'T' ? row.absolute : 0
  );

  const transitionalProportion = sumBy(eligibleAlignedRows, (row) =>
    row.transitional === 'T' ? row.proportion : 0
  );

  return {
    absolute: transitionalAbsolute,
    proportion: transitionalProportion,
    transitional: 'T',
    mitigationSC: { financial: transitionalProportion }, // transitional only counts for mitigation
  };
};

export const getTotalEligibleAligned = (
  eligibleAlignedRows: RowFormat[],
  total: number
): RowFormat => {
  const alignedSum = sumBy(eligibleAlignedRows, 'absolute');
  const proportion = getProportion(alignedSum, total);

  const getTotalSubstantialContribution = (objectiveKey: string): { financial: number } => {
    return {
      financial: sumBy(eligibleAlignedRows, (row) => row[objectiveKey]?.financial ?? 0),
    };
  };

  const row: RowFormat = {
    absolute: alignedSum,
    proportion,
    mitigationSC: getTotalSubstantialContribution('mitigationSC'),
    adaptationSC: getTotalSubstantialContribution('adaptationSC'),
    waterSC: getTotalSubstantialContribution('waterSC'),
    circularSC: getTotalSubstantialContribution('circularSC'),
    pollutionSC: getTotalSubstantialContribution('pollutionSC'),
    biodiversitySC: getTotalSubstantialContribution('biodiversitySC'),
  };
  return row;
};

export const getTotalEligibleNotAligned = (
  eligibleNotAlignedRows: RowFormat[],
  total?: number
): RowFormat => {
  const notAlignedSum = sumBy(eligibleNotAlignedRows, 'absolute');

  const row: RowFormat = {
    absolute: notAlignedSum,
    proportion: getProportion(notAlignedSum, total),
  };
  return row;
};

export const getTotalEligible = (
  totalEligibleNotAligned: RowFormat,
  totalEligibleAligned: RowFormat
): RowFormat => {
  const row: RowFormat = {
    absolute: totalEligibleAligned.absolute + totalEligibleNotAligned.absolute,
    proportion: totalEligibleAligned.proportion + totalEligibleNotAligned.proportion,
  };
  return row;
};

export const getNonEligible = (
  totalEligible: RowFormat,
  totalFinancials: FinancialsPerType,
  type: FinancialsType
): RowFormat => {
  const absolute = totalFinancials[type].total - totalEligible.absolute;
  const proportion = getProportion(absolute, totalFinancials[type].total);
  return {
    absolute,
    proportion,
  };
};
export const calculateSmallTableData = (
  resultsByActivityAndTag: ResultsByActivityAndSC,
  companyResults: CompanyAssessmentResults | undefined,
  activities: any,
  totalFinancials: FinancialsPerType,
  type: FinancialsType
): SmallTableData => {
  const objectivesWithSc = [
    'mitigationSC',
    'adaptationSC',
    'waterSC',
    'circularSC',
    'pollutionSC',
    'biodiversitySC',
  ];

  const objectivesWithoutSc = [
    'mitigation',
    'adaptation',
    'water',
    'circular',
    'pollution',
    'biodiversity',
  ];

  const eligibleAlignedActivities = getEligibleAlignedActivities(
    resultsByActivityAndTag,
    type,
    activities,
    totalFinancials[type].total
  );

  const activityResults = getResultsByActivity(companyResults);

  const eligiblePerObjective = objectivesWithoutSc.map((objective) => {
    let sum = 0;
    Object.entries(activityResults).forEach(([activityRef, results]) => {
      const possibleSCObjectives =
        results[0].cachedResult?.objectivesState?.possibleSubstantialContributionObjectives;
      if (possibleSCObjectives.includes(objective)) {
        if (objective === ObjectiveKeyEnum.adaptation && possibleSCObjectives.length > 1) {
          sum += results.reduce((acc, cur) => {
            if (
              (cur?.cachedResult?.activityTag === 'ENABLING' &&
                enablingTagGoesToAdaptation.includes(activityRef)) ||
              checkIfAlwaysEnablingActivity(activityRef)
            ) {
              return acc + cur?.cachedResult?.financials?.[type]?.eligible ?? 0;
            }
            return acc + cur?.cachedResult?.financials?.[type]?.rawAdaptation ?? 0;
          }, 0);
        } else {
          sum += results.reduce(
            (acc, cur) => acc + cur?.cachedResult?.financials?.[type]?.eligible ?? 0,
            0
          );
        }
      }
    });
    return sum;
  });

  const eligibleAlignedPerObjective = objectivesWithSc.map((objective) =>
    sumBy(eligibleAlignedActivities, (row) => row[objective]?.financial ?? 0)
  );

  return {
    mitigationAligned: eligibleAlignedPerObjective[0],
    mitigationEligible: eligiblePerObjective[0] / totalFinancials[type].total,
    adaptationAligned: eligibleAlignedPerObjective[1],
    adaptationEligible: eligiblePerObjective[1] / totalFinancials[type].total,
    waterAligned: eligibleAlignedPerObjective[2],
    waterEligible: eligiblePerObjective[2] / totalFinancials[type].total,
    circularAligned: eligibleAlignedPerObjective[3],
    circularEligible: eligiblePerObjective[3] / totalFinancials[type].total,
    pollutionAligned: eligibleAlignedPerObjective[4],
    pollutionEligible: eligiblePerObjective[4] / totalFinancials[type].total,
    biodiversityAligned: eligibleAlignedPerObjective[5],
    biodiversityEligible: eligiblePerObjective[5] / totalFinancials[type].total,
  };
};

// import { useApolloClient } from '@apollo/client';
import { useToast } from 'utils/hooks/useToast';
import {
  CompanyInvitation_Insert_Input_,
  Company_Insert_Input_,
  Invitation,
  PendingInvitationsPerEmailQuery_,
  useAcceptAllCompanyInvitationsMutation,
  User,
  UserRole_Enum_,
  useUpsertCompanyInvitationMutation,
  useUpsertCompanyUserMutation,
} from 'models';
import { useCallback, useEffect, useState } from 'react';
import { Tuple, upsertOmitMeta } from 'utils';
import { TFunction } from 'utils/translation';
import { Button } from 'Atoms';
import { useSwitchCompany } from 'Features/CompanySwitcher/CompanySwitcher.hooks';
import { nhost } from 'utils/nhost';
import { pick } from 'lodash';
// import { fetchPortfolios } from 'containers/Portfolios';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { RefreshIcon } from 'Tokens/Icons/Function';

export function useUpsertCompanyInvitation() {
  const [upsertCompanyInvitation, result] = useUpsertCompanyInvitationMutation({
    refetchQueries: 'active',
  });

  const wrapped = useCallback(
    (invite: CompanyInvitation_Insert_Input_, previous?: Invitation) =>
      upsertCompanyInvitation({
        variables: {
          companyInvitation: {
            ...(previous
              ? pick(previous, 'id', 'status', 'inviteToChildCompanies', 'userEmail')
              : {}),
            companyId: previous?.company.id,
            ...upsertOmitMeta(invite),
          },
        },
      }),
    [upsertCompanyInvitation]
  );
  return Tuple(wrapped, result);
}

export function useAddUserToCompanyMutation() {
  const [upsertCompanyUser, result] = useUpsertCompanyUserMutation();
  const [upsertUserInvite] = useUpsertCompanyInvitation();
  const wrapped = useCallback(
    async (
      company: Company_Insert_Input_,
      userId: string,
      skipRefreshSession?: boolean,
      invitation?: Invitation
    ) => {
      const results = await upsertCompanyUser({
        variables: {
          companyUser: {
            userId,
            role: UserRole_Enum_.Admin_,
            ...(company.id ? { companyId: company.id } : { company: { data: company } }),
          },
        },
      });
      if (!skipRefreshSession) await nhost.auth.refreshSession(); // Refresh companies claims in JWTtoken
      if (invitation) {
        await upsertUserInvite({ status: 'active' }, invitation);
      }
      return results;
    },
    [upsertCompanyUser, upsertUserInvite]
  );
  return Tuple(wrapped, result);
}

export function useAcceptAllInvitations() {
  const [acceptInvitations, result] = useAcceptAllCompanyInvitationsMutation();
  const wrapped = useCallback(
    async (invitations: Array<{ companyId: string; id: string }>, user: User) => {
      const results = await nhost.functions.call<{ companiesAdded: number }>('accept-all-invites', {
        invitations,
        user,
      });
      await nhost.auth.refreshSession(); // Refresh companies claims in JWTtoken
      location.reload();
      return results;
    },
    [acceptInvitations]
  );
  return Tuple(wrapped, result);
}

type InvitationValidity = {
  companyName?: string;
  isNewUser?: boolean;
  isValid: boolean;
  isExpired?: boolean;
  userEmail?: string;
  companyId?: string;
  logoUrl?: string;
  status?: string;
  inviteToChildCompanies?: boolean;
};

export enum InvitationError {
  expired = 'expired',
  network = 'network-error',
  invalid = 'invalid',
  missing = 'missing',
}

export function useValidateInvitation(invitationId?: string) {
  const [invitation, setInvitation] = useState<Invitation>();
  const [isError, setIsError] = useState<InvitationError>();

  const validateInvite = useCallback(async () => {
    try {
      const result = await nhost.functions.call('validate-invite', {
        invitationId: invitationId,
      });
      const validity = (result?.res?.data as any)?.result as InvitationValidity;
      if (validity.isValid === false) setIsError(InvitationError.invalid);
      else if (validity.isExpired === true) setIsError(InvitationError.expired);
      else {
        setInvitation({
          userExists: !validity?.isNewUser ?? false,
          id: invitationId ?? '',
          userEmail: validity?.userEmail ?? '',
          company: {
            name: validity?.companyName ?? '',
            id: validity?.companyId,
            logoUrl: validity?.logoUrl,
          },
          status: validity?.status ?? 'invalid',
          inviteToChildCompanies: validity?.inviteToChildCompanies ?? false,
        });
      }
    } catch (e) {
      setIsError(InvitationError.network);
    }
  }, [invitationId]);

  useEffect(() => {
    if (invitationId) {
      validateInvite();
    } else {
      setIsError(InvitationError.missing);
    }
  }, [invitationId]);

  return {
    invitation,
    loading: !isError && !invitation,
    error: isError,
  };
}

export function useAcceptInvitation(t: TFunction) {
  // const client = useApolloClient();
  const toast = useToast();
  const [addUserToCompany] = useAddUserToCompanyMutation();
  const switchCompany = useSwitchCompany();
  const onSuccess = (companyName: string, manualRedirect: string | null) => {
    toast({
      text: `${t('common:toast.success')} 
      ${t('common:toast.invitationSuccess.description', { companyName: companyName })}`,
      actions: manualRedirect ? (
        <Button
          variant="secondary"
          onClick={() => switchCompany(manualRedirect)}
          rightIcon={<RefreshIcon color="inherit" />}
        >
          {t('common:switchCompany')}
        </Button>
      ) : undefined,
    });
  };
  return useCallback(
    (invitation: Invitation, user?: User) => {
      return addUserToCompany({ id: invitation.company.id }, user?.id, false, invitation)
        .then(async ({ data }) => {
          // if (invitation?.inviteToChildCompanies) {
          // const portfolios = await fetchPortfolios(client, invitation.company.id);
          //   if (portfolios) {
          //     const companyIds = portfolios
          //       .map((portfolio: { portfolioCompanies: any[] }) =>
          //         portfolio.portfolioCompanies.map((c: { company: { id: any } }) => c.company?.id)
          //       )
          //       .flat()
          //       .filter((id: any) => !!id);
          //     Promise.all(
          //       companyIds.map((compId: any) => addUserToCompany({ id: compId }, user?.id, true))
          //     );
          //   }
          // }
          const manualRedirect =
            (data?.companyUser?.user?.companyUsers.length ?? 0) > 1 ? invitation.company.id : null;
          return onSuccess(invitation.company.name, manualRedirect);
        })
        .catch(() => {
          toast({
            text: `${t('common:toast.invitationFailed.title')} 
            ${t('common:toast.invitationFailed.description')}`,
            variant: 'danger',
          });
        });
    },
    [addUserToCompany, t, toast]
  );
}
export function useRemoveInvitationId() {
  const [searchParams] = useSearchParams();
  const invitationId = searchParams.get('invitationId');
  const navigate = useNavigate();

  return Tuple(
    invitationId,
    useCallback(() => navigate('/'), [])
  );
}
export function usePendingInvitations({ userEmail }: { userEmail: string }) {
  const [invitations, setInvitations] = useState<PendingInvitationsPerEmailQuery_['invitations']>();

  useEffect(() => {
    if (userEmail) {
      nhost.functions
        .call<{
          pendingInvitations: PendingInvitationsPerEmailQuery_['invitations'];
        }>('get-pending-invites', { userEmail: userEmail })
        .then((result) => {
          const pendingInvites =
            (
              result?.res?.data as unknown as {
                pendingInvites: PendingInvitationsPerEmailQuery_['invitations'];
              }
            )?.pendingInvites ?? [];
          setInvitations(pendingInvites);
        });
    }
  }, [userEmail]);

  return { invitations };
}

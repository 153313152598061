import { useState } from 'react';
import {
  EsrsAssessmentDuplicationOptions,
  defaultEsrsDuplicationOptions,
  useDuplicateEsrsAssessment,
} from './EsrsAssessment.hooks';
import { Modal } from 'Molecules';
import { useTranslation } from 'utils/translation';
import { VStack, Collapse, Box } from '@chakra-ui/react';
import { DateInput, FormField } from 'Atoms';
import { Typography } from 'Tokens';
import { SelectionItem } from 'containers/Assessments/pieces/DuplicateAssessmentModal';

type EsrsDuplicateModalProps = {
  esrsAssessmentId: string;
  isOpen: boolean;
  onClose: () => void;
  reportingYears: number[];
  originalReportingYear: number;
};

const getNewReportingYear = (esrsAssessmentReportingYear: number, reportingYears: number[]) => {
  const nextYear = esrsAssessmentReportingYear + 1;
  return reportingYears.includes(nextYear) ? Math.max(...reportingYears) + 1 : nextYear;
};

export const DuplicateEsrsAssessmentModal = ({
  esrsAssessmentId,
  isOpen,
  onClose,
  reportingYears,
  originalReportingYear,
}: EsrsDuplicateModalProps) => {
  const { t } = useTranslation(['esrs', 'common']);
  const duplicate = useDuplicateEsrsAssessment();

  const [options, setOptions] = useState<EsrsAssessmentDuplicationOptions>(
    defaultEsrsDuplicationOptions
  );

  const [duplicationType, setDuplicationType] = useState<'custom' | 'standard'>('standard');

  const [newReportingYear, setNewReportingYear] = useState<number>(
    getNewReportingYear(originalReportingYear, reportingYears)
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onConfirm = async () => {
    if (!isLoading) {
      setIsLoading(true);

      try {
        await duplicate(esrsAssessmentId, newReportingYear, options);
        setIsLoading(false);
        onClose();
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    }
  };

  return (
    <Modal
      title={t('assessment.duplicateAssessmentModal.modal.header')}
      onClose={onClose}
      isOpen={isOpen}
      confirmText={t('common:button.confirm')}
      onConfirm={onConfirm}
      confirmButtonProps={{ isLoading }}
    >
      <VStack width="100%" alignItems="stretch" spacing="24px">
        <VStack>
          <FormField
            id="newReportingYear"
            label={t('assessment.duplicateAssessmentModal.modal.typography.reportingYearTitle')}
          >
            <DateInput
              value={new Date(newReportingYear, 0, 1 ?? new Date())}
              setValue={(date) =>
                setNewReportingYear(date?.getFullYear() ?? new Date().getFullYear())
              }
              showYearPicker
              dateFormat="yyyy"
            />
          </FormField>
        </VStack>

        <FormField
          id="duplicationType"
          label={t('assessment.duplicateAssessmentModal.modal.typography.optionsTitle')}
        >
          <VStack spacing="8px">
            <VStack
              borderColor={
                duplicationType === 'standard' ? 'border.selected.accent' : 'border.default'
              }
              borderWidth={duplicationType === 'standard' ? '2px' : '1px'}
              padding="16px"
              borderRadius="8px"
              spacing="8px"
              cursor="pointer"
              _hover={{
                bg: 'bg.hover',
              }}
              onClick={() => {
                setDuplicationType('standard');
                setOptions(defaultEsrsDuplicationOptions);
              }}
              alignItems="flex-start"
            >
              <Typography variant="h3">
                {t('assessment.duplicateAssessmentModal.modal.typography.standard.title')}
              </Typography>
              <Typography variant="body">
                {t('assessment.duplicateAssessmentModal.modal.typography.standard.body')}
              </Typography>
            </VStack>
            <VStack
              borderColor={
                duplicationType === 'custom' ? 'border.selected.accent' : 'border.default'
              }
              borderWidth={duplicationType === 'custom' ? '2px' : '1px'}
              padding="16px"
              borderRadius="8px"
              spacing="8px"
              cursor="pointer"
              _hover={{
                bg: 'bg.hover',
              }}
              onClick={() => setDuplicationType('custom')}
              alignItems="flex-start"
            >
              <Typography variant="h3">
                {t('assessment.duplicateAssessmentModal.modal.typography.custom.title')}
              </Typography>
              <Typography variant="body">
                {t('assessment.duplicateAssessmentModal.modal.typography.custom.body')}
              </Typography>
              <Collapse in={duplicationType === 'custom'}>
                <VStack spacing="20px" alignItems="flex-start">
                  <VStack spacing="12px" alignItems="flex-start">
                    <SelectionItem
                      title={t('assessment:duplicateAssessmentModal.selection.selectEverything')}
                      isChecked={options === defaultEsrsDuplicationOptions}
                      onChange={() => setOptions(defaultEsrsDuplicationOptions)}
                    />
                  </VStack>

                  <VStack spacing="12px" alignItems="flex-start">
                    <Typography variant="detailStrong">
                      {t('assessment.duplicateAssessmentModal.headers.materiality')}
                    </Typography>
                    <SelectionItem
                      title={t('assessment.duplicateAssessmentModal.selection.materiality')}
                      isChecked={options.withMateriality}
                      onChange={(val) =>
                        setOptions((prev) => ({
                          ...prev,
                          withMateriality: val,
                          ...(val === false && {
                            withTargetsAndActions: false,
                            withDatapoints: false,
                            withComments: false,
                            withDocumentation: false,
                          }),
                        }))
                      }
                    />
                    <SelectionItem
                      title={t('assessment.duplicateAssessmentModal.selection.targetsAndActions')}
                      isChecked={options.withTargetsAndActions}
                      isDisabled={!options.withMateriality}
                      onChange={(val) =>
                        setOptions((prev) => ({
                          ...prev,
                          withTargetsAndActions: val,
                        }))
                      }
                    />
                  </VStack>

                  <VStack spacing="12px" alignItems="flex-start">
                    <Typography variant="detailStrong">
                      {t('assessment.duplicateAssessmentModal.headers.answers')}
                    </Typography>
                    <SelectionItem
                      title={t('assessment.duplicateAssessmentModal.selection.answers')}
                      isChecked={options.withDatapoints}
                      isDisabled={!options.withMateriality}
                      onChange={(val) =>
                        setOptions((prev) => ({
                          ...prev,
                          withDatapoints: val,
                          ...(val === false && { withComments: false }),
                        }))
                      }
                    />

                    <Box ml="16px">
                      <SelectionItem
                        title={t('assessment.duplicateAssessmentModal.selection.narrativeAnswers')}
                        isChecked={options.onlyNarrativeAnswers}
                        isDisabled={!options.withDatapoints || !options.withMateriality}
                        onChange={(val) =>
                          setOptions((prev) => ({
                            ...prev,
                            onlyNarrativeAnswers: val,
                          }))
                        }
                      />
                    </Box>

                    <SelectionItem
                      title={t('assessment.duplicateAssessmentModal.selection.comments')}
                      isChecked={options.withComments}
                      isDisabled={!options.withDatapoints || !options.withMateriality}
                      onChange={(val) =>
                        setOptions((prev) => ({
                          ...prev,
                          withComments: val,
                        }))
                      }
                    />
                  </VStack>

                  <VStack spacing="12px" alignItems="flex-start">
                    <Typography variant="detailStrong">
                      {t('assessment.duplicateAssessmentModal.headers.documentation')}
                    </Typography>
                    <SelectionItem
                      title={t('assessment.duplicateAssessmentModal.selection.docsAndNotes')}
                      isChecked={options.withDocumentation}
                      isDisabled={!options.withMateriality}
                      onChange={(val) =>
                        setOptions((prev) => ({
                          ...prev,
                          withDocumentation: val,
                        }))
                      }
                    />
                  </VStack>

                  <VStack spacing="12px" alignItems="flex-start">
                    <Typography variant="detailStrong">
                      {t('assessment.duplicateAssessmentModal.headers.reportingStructure')}
                    </Typography>
                    <SelectionItem
                      title={t('assessment.duplicateAssessmentModal.selection.reportingUnits')}
                      isChecked={options.withReportingUnits}
                      onChange={(val) =>
                        setOptions((prev) => ({
                          ...prev,
                          withReportingUnits: val,
                        }))
                      }
                    />
                  </VStack>
                </VStack>
              </Collapse>
            </VStack>
          </VStack>
        </FormField>
      </VStack>
    </Modal>
  );
};

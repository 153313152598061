import { HStack, VStack } from '@chakra-ui/react';
import { AutoResizeTextarea, Button, FormField } from 'Atoms';
import axios from 'axios';
import { useGetQualitativeMetricsQuery } from 'models';
import { ContentLayout, Loader, Select } from 'Molecules';
import { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Typography } from 'Tokens';
import { useToast } from 'utils/hooks';
import { nhost } from 'utils/nhost';

type SelectedMetric = {
  value: string;
  label: string;
};

type AnswerForm = {
  answer1: string;
  answer2: string;
  answer3: string;
  prompt?: string;
};

const DEFAULT_PROMPT =
  'Summarize the different answers provided to the metric as truthfully as possible using the following given distinct answers combined together, where each answer belongs to a company and answers are separated by dashes:';

const cleanUpHTMLText = (text: string) => {
  return (
    text
      .replace(/(<([^>]+)>)/gi, '')
      .replace(/\n/g, '')
      .trim() ?? ''
  );
};

export const TestAnswersAI = () => {
  const [selectedOption, setSelectedOption] = useState<SelectedMetric>();
  const [generatedSummary, setGeneratedSummary] = useState<string>();
  const [isSendingRequest, setIsSendingRequest] = useState<boolean>(false);

  const { data: qualitativeMetricData, loading } = useGetQualitativeMetricsQuery();

  const metrics = useMemo(() => {
    return (
      qualitativeMetricData?.EsrsMetric.map((metric) => ({
        ...metric,
        description: cleanUpHTMLText(metric.description ?? ''),
      })) ?? []
    );
  }, [qualitativeMetricData]);

  const toast = useToast();

  const selectedMetric = useMemo(() => {
    return metrics.find((metric) => metric.reference === selectedOption?.value);
  }, [selectedOption, metrics]);

  const { control, reset, handleSubmit } = useForm<AnswerForm>({});

  const summarizeAnswers = async (combinedAnswers: string, customPrompt?: string) => {
    try {
      setIsSendingRequest(true);
      toast({
        text: 'AI is summarizing answers',
      });
      const summaryResult = await axios.post(
        `${import.meta.env.REACT_APP_AI_BACKEND_URL}/metrics/summarizeMetricAnswers`,
        {
          metricTitle: selectedMetric?.title,
          metricDescription: selectedMetric?.description,
          answers: combinedAnswers,
          prompt: customPrompt || DEFAULT_PROMPT,
        },
        {
          headers: {
            'secret-key': 'secretKey',
            authorization: `Bearer ${nhost.auth.getAccessToken()}`,
          },
        }
      );
      setIsSendingRequest(false);
      const text = summaryResult.data.summary;
      setGeneratedSummary(text);
      return summaryResult.data.summary;
    } catch (error) {
      setIsSendingRequest(false);
      toast({
        variant: 'danger',
        text: 'Could not summarize answers',
      });
    }
  };

  const onSubmit = (data: AnswerForm) => {
    const combinedAnswers = `${data.answer1} - ${data.answer2} - ${data.answer3}`;
    summarizeAnswers(combinedAnswers, data.prompt);
  };

  const onClear = () => {
    reset({
      answer1: '',
      answer2: '',
      answer3: '',
    });
  };

  if (loading) return <Loader />;

  return (
    <ContentLayout header="Test metric answers AI">
      <VStack alignItems="stretch" spacing="14px" w="100%">
        <VStack spacing="8px" alignItems="start">
          <Typography variant="h3">Select a qualitative metric</Typography>
          <Select<SelectedMetric>
            placeholder="Select metric..."
            value={{
              label: selectedOption?.label ?? '',
              value: selectedOption?.value ?? '',
            }}
            onChange={(val) => {
              setSelectedOption({
                label: val?.label ?? '',
                value: val?.value ?? '',
              });
            }}
            options={
              [
                ...metrics.map((m) => ({
                  label: m.title,
                  value: m.reference,
                })),
              ] ?? []
            }
            size="md"
          />
        </VStack>

        {selectedMetric && (
          <>
            <VStack alignItems="start" spacing="8px">
              <Typography variant="bodyStrong">Metric full title</Typography>
              <Typography variant="body">{selectedMetric?.title}</Typography>
            </VStack>
            <VStack alignItems="start" spacing="8px">
              <Typography variant="bodyStrong">Metric description</Typography>
              <Typography variant="body">
                {selectedMetric?.description || 'No description provided'}
              </Typography>
            </VStack>
            <form onSubmit={handleSubmit(onSubmit)}>
              <VStack spacing="16px" alignItems="start">
                <Controller
                  name="answer1"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field }) => (
                    <FormField id="answer-1" label="Answer 1" isRequired>
                      <AutoResizeTextarea onChange={field.onChange} value={field.value} />
                    </FormField>
                  )}
                />
                <Controller
                  name="answer2"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field }) => (
                    <FormField id="answer-2" label="Answer 2" isRequired>
                      <AutoResizeTextarea onChange={field.onChange} value={field.value} />
                    </FormField>
                  )}
                />
                <Controller
                  name="answer3"
                  control={control}
                  defaultValue=""
                  rules={{ required: true }}
                  render={({ field }) => (
                    <FormField id="answer-3" label="Answer 3" isRequired>
                      <AutoResizeTextarea onChange={field.onChange} value={field.value} />
                    </FormField>
                  )}
                />

                <Typography variant="bodyStrong">Default prompt</Typography>
                <Typography variant="body">
                  Given the following metric of title: <b>TITLE</b>, and description:{' '}
                  <b>DESCRIPTION</b>
                </Typography>
                <Typography variant="body" color={'blue'}>
                  Prompt starts here ↓
                </Typography>
                <Typography variant="body">"{DEFAULT_PROMPT}"</Typography>

                <Controller
                  name="prompt"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <FormField id="prompt" label="Custom prompt (Replaces default prompt)">
                      <AutoResizeTextarea onChange={field.onChange} value={field.value} />
                    </FormField>
                  )}
                />
                <HStack>
                  <Button variant="secondary" onClick={onClear}>
                    Clear answers
                  </Button>
                  <Button
                    isLoading={isSendingRequest}
                    isDisabled={!selectedMetric || isSendingRequest}
                    type="submit"
                    variant="primary"
                  >
                    Summarize answers
                  </Button>
                </HStack>
              </VStack>
            </form>
          </>
        )}

        {isSendingRequest ? (
          <Typography color="text.info" variant="body">
            AI is generating summary...
          </Typography>
        ) : (
          generatedSummary && (
            <VStack mt="10px" alignSelf="start" alignItems="start">
              <Typography variant="h3">Generated summary</Typography>
              <Typography variant="body">{generatedSummary}</Typography>
              <Button variant="secondary" onClick={() => setGeneratedSummary('')}>
                Reset
              </Button>
            </VStack>
          )
        )}
      </VStack>
    </ContentLayout>
  );
};

import { Box, BoxProps, Collapse, HStack, VStack } from '@chakra-ui/react';
import { IconButton, Tag, Tooltip, TruncatableText } from 'Atoms';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { Typography, TypographyProps } from 'Tokens';
import { BusinessUnitsIcon, CompanyIcon, ParentCompanyIcon } from 'Tokens/Icons/Data';
import { ChevronDownIcon, ChevronRightIcon } from 'Tokens/Icons/Direction';
import { DREnums } from '../DisclosureRequirements/Requirement';
import { ShortUser, User } from 'models';
import { UserAvatar } from 'Organisms';
import { WarningIcon } from 'Tokens/Icons/Status';

export type StructureNode = {
  key: string;
  title: string;
  counter?: number;
  reference?: string;
  projectLeader?: Partial<User>;
  flaggedCount?: number;
};
const NODES_WITH_COUNTER = [DREnums.target, DREnums.action, DREnums.policy];

const paddingsMap = {
  childPadding: '14px 16px 14px 32px',
  smallSize: '8px 16px',
  default: '8px 16px 8px 32px',
};

const getPadding = (isChild?: boolean, size?: 'lg' | 'sm', topNode?: boolean) => {
  if (isChild) {
    return paddingsMap.childPadding;
  } else if (size === 'sm' || topNode) {
    return paddingsMap.smallSize;
  } else {
    return paddingsMap.default;
  }
};

const getIcon = (isGroup?: boolean, topNode?: boolean) => {
  if (!topNode) {
    return <></>;
  } else if (isGroup) {
    return <CompanyIcon />;
  } else {
    return <BusinessUnitsIcon />;
  }
};

export const ParentNode = ({
  node,
  isOpen,
  setIsOpen,
  ...rest
}: {
  node: StructureNode;
  isOpen: boolean;
  setIsOpen: (param: boolean) => void;
} & BoxProps) => {
  return (
    <HStack
      onClick={() => setIsOpen(!isOpen)}
      // bg={isOpen ? 'bg.selected.muted' : 'bg.default'}
      _hover={{ bg: 'bg.hover' }}
      cursor="pointer"
      borderTop="1px solid"
      borderTopColor="border.decorative"
      width="100%"
      padding="16px"
      justifyContent="space-between"
      {...rest}
    >
      <Typography variant="bodyStrong">{node.title}</Typography>
      <IconButton
        variant="ghost"
        aria-label="OpenButton"
        size="sm"
        icon={isOpen ? <ChevronDownIcon /> : <ChevronRightIcon />}
      />
    </HStack>
  );
};

export const StructureItem = ({
  node,
  icon,
  textProps,
  onClick,
  isSelected = false,
  size = 'lg',
  isVisible = true,
  isChild,
  topNode,
  rightChild,
  ...rest
}: {
  node: StructureNode;
  isSelected?: boolean;
  icon?: ReactNode;
  textProps?: TypographyProps;
  size?: 'lg' | 'sm';
  isVisible?: boolean;
  isChild?: boolean;
  topNode?: boolean;
  rightChild?: ReactNode;
} & BoxProps) => {
  const hasCounter = useMemo(
    () => NODES_WITH_COUNTER.includes(node.key.split('_')[0] as DREnums),
    [node]
  );
  const isFlagged = useMemo(() => (node.flaggedCount ?? 0) > 0, [node]);
  return (
    <Box width="100%" bg={isFlagged ? 'bg.warning' : ''}>
      <Collapse in={isVisible} animateOpacity>
        <HStack
          onClick={onClick}
          bg={isSelected ? 'bg.selected' : ''}
          _hover={{ bg: 'bg.hover' }}
          cursor="pointer"
          borderTop="1px solid"
          borderTopColor="border.decorative"
          width="100%"
          padding={getPadding(isChild, size, topNode)}
          minHeight={size === 'sm' ? '48px' : '60px'}
          {...rest}
          justifyContent={hasCounter ? 'space-between' : ''}
        >
          <HStack flex={1} w="100%">
            {icon}
            <HStack w="100%">
              {node.reference && (
                <Typography variant="body" minW="fit-content" maxW="500px" noOfLines={1}>
                  {node.reference}
                </Typography>
              )}
              <TruncatableText
                variant={size === 'sm' ? 'bodyStrong' : 'body'}
                {...textProps}
                text={node.title}
                noOfLines={1}
              />
            </HStack>
            {hasCounter && (
              <HStack spacing="6px" alignItems="start">
                <Tag
                  variant={node.counter && node.counter > 0 ? 'info' : 'undefined'}
                  size="xs"
                  minW="16px"
                >
                  {node.counter ?? 0}
                </Tag>
                {isFlagged && (
                  <Box>
                    <Tooltip label="Please review and update targets baseline and milestones values to make sure a correct reporting.">
                      <Tag variant="warning" size="xs">
                        <WarningIcon color="text.warning" />
                      </Tag>
                    </Tooltip>
                  </Box>
                )}
              </HStack>
            )}
          </HStack>
          {rightChild}
        </HStack>
      </Collapse>
    </Box>
  );
};

const SingleNode = ({
  node,
  selectedNodeKey,
  onSelectNode,
  size,
  topNode,
  isGroup,
}: {
  node: StructureNode | StructureNode[];
  size?: 'lg' | 'sm';
  selectedNodeKey: string;
  onSelectNode: (newSelectedNode: string) => void;
  topNode?: StructureNode;
  isGroup?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (Array.isArray(node) && node.some((n) => n.key === selectedNodeKey)) {
      setIsOpen(true);
    }
  }, [node, selectedNodeKey]);

  return Array.isArray(node) ? (
    <>
      {node.map((n, index) =>
        index === 0 ? (
          <ParentNode node={n} isOpen={isOpen} setIsOpen={setIsOpen} />
        ) : (
          <StructureItem
            maxW="288px"
            w="100%"
            key={n.key}
            node={n}
            onClick={() => onSelectNode(n.key)}
            isSelected={n.key === selectedNodeKey}
            size={size}
            isVisible={isOpen}
            isChild={true}
          />
        )
      )}
    </>
  ) : (
    <StructureItem
      maxW="288px"
      w="100%"
      key={node.key}
      node={node}
      onClick={() => onSelectNode(node.key)}
      isSelected={node.key === selectedNodeKey}
      size={size}
      icon={getIcon(isGroup, !!topNode)}
      rightChild={
        node.projectLeader && <UserAvatar size="sm" user={node.projectLeader as ShortUser} />
      }
    />
  );
};
export const Structure = ({
  header,
  topNode,
  nodes,
  selectedNodeKey,
  onSelectNode,
  size = 'lg',
  isGroup,
  ...rest
}: BoxProps & {
  header: ReactNode;
  topNode?: StructureNode;
  nodes: (StructureNode | StructureNode[])[];
  selectedNodeKey: string;
  onSelectNode: (newSelectedNode: string) => void;
  size?: 'lg' | 'sm';
  isGroup?: boolean;
}) => {
  return (
    <VStack
      display="flex"
      alignItems="stretch"
      pb="20px"
      spacing="0px"
      {...rest}
      className="walktour-company-structure"
    >
      {header}
      {topNode && (
        <StructureItem
          w="max-content"
          node={topNode}
          icon={<ParentCompanyIcon />}
          textProps={{ variant: 'bodyStrong' }}
          onClick={() => onSelectNode(topNode.key)}
          isSelected={topNode.key === selectedNodeKey}
          topNode={true}
        />
      )}
      {nodes?.map((node) => (
        <SingleNode
          node={node}
          size={size}
          selectedNodeKey={selectedNodeKey}
          onSelectNode={onSelectNode}
          topNode={topNode}
          isGroup={isGroup}
        />
      ))}
    </VStack>
  );
};

import { Box, HStack, MenuButton, VStack, useDisclosure } from '@chakra-ui/react';
import { TargetGraph } from './AddTarget/TargetGraph';
import { Avatar, IconButton, Tag, Tooltip, TruncatableText } from 'Atoms';
import { Typography } from 'Tokens';
import { ArrowUpRightIcon } from 'Tokens/Icons/Direction';
import { MaximizeIcon } from 'Tokens/Icons/Function';
import { Menu } from 'Molecules/Menu';
import { Modal } from 'Molecules';
import { useNavigate } from 'react-router-dom';
import { CombinedTargetsData } from './Targets.hooks';

export const TargetsGraphCard = ({
  target,
  setAction,
  openAction,
}: {
  target: CombinedTargetsData;
  setAction: (val: number) => void;
  openAction: () => void;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const sections = target.actions.map((action, key) => ({
    actions: [
      {
        id: String(key),
        title: action.title,
        isDisabled: target.isSubOrParent,
        onClick: () => {
          setAction(action.id);
          openAction();
        },
      },
    ],
  }));
  return (
    <VStack
      alignItems="start"
      border="1px solid"
      borderColor="border.decorative"
      borderRadius="8px"
      spacing="2px"
      w="100%"
    >
      <HStack
        paddingBlock="12px"
        paddingInline="16px"
        borderBottom="1px solid"
        borderColor="border.decorative"
        w="100%"
        justifyContent="space-between"
      >
        <VStack alignItems="start" gap="2px" width="100%">
          <TruncatableText variant="bodyStrong" noOfLines={1} text={target.title} />

          <TruncatableText variant="detail" text={target.companyName + ' target' ?? ''} />
        </VStack>
        <HStack alignItems="end" gap="4px">
          <Avatar name={target?.owner?.displayName} size="xs" boxSize="28px" />
          <Tooltip label="Open location">
            <IconButton
              aria-label="open"
              icon={<ArrowUpRightIcon />}
              variant="ghost"
              onClick={() => navigate(`target-form/${target.targetId}/edit`)}
              size="sm"
            />
          </Tooltip>
          <Tooltip label="Full screen">
            <IconButton
              aria-label="maximize"
              icon={<MaximizeIcon />}
              variant="ghost"
              onClick={onOpen}
              size="sm"
            />
          </Tooltip>
        </HStack>
      </HStack>
      <Box w="99%">
        <TargetGraph
          localAnswers={target}
          targetId={String(target.targetId)}
          baseline={target.baseline}
          showControls={false}
          chartHeight={200}
          marginBottom={0}
        />
      </Box>

      <HStack
        paddingBlock="12px"
        paddingInline="16px"
        borderTop="1px solid"
        borderColor="border.decorative"
        width="100%"
        spacing="8px"
      >
        <Menu
          customMenuButton={
            <MenuButton
              onClick={(e) => e.stopPropagation()}
              _hover={{ cursor: target.numberOfActions ? 'pointer' : 'default' }}
            >
              <Tag
                variant={target.numberOfActions ? 'info' : 'default'}
                borderRadius="6px"
                minH="20px"
                padding="4px 8px"
                minW="0px"
                clickable={target.numberOfActions ? true : false}
                title={String(target.numberOfActions)}
              />
            </MenuButton>
          }
          sections={sections}
          size="sm"
        />
        <Typography variant="body">Related actions</Typography>
      </HStack>
      {isOpen && (
        <Modal isOpen={isOpen} onClose={onClose} title={target.title} size="md" hasFooter={false}>
          <VStack
            alignItems="start"
            border="1px solid"
            borderColor="border.decorative"
            borderRadius="8px"
            w="100%"
            h="100%"
            pt="8px"
          >
            <TargetGraph
              localAnswers={target}
              targetId={String(target.targetId)}
              baseline={target.baseline}
              showControls={false}
            />
          </VStack>
        </Modal>
      )}
    </VStack>
  );
};

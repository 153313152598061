import {
  CompanyLevelMetricsPerDisclosureQuery_,
  GetBuDisclosureRequirementMetricsQuery_,
  GetEsrsAssessmentMetadataDocument_,
  QuestionType_Enum_,
  useEsrsAssessmentQuery,
  useGetEsrsAssessmentMetadataQuery,
  useGetReportingUnitQuery,
  useReportingUnitsMetricsPerDisclosureQuery,
  useUpdateEsrsAssessmentMetaDataMutation,
} from 'models';
import { VStack, useDisclosure, HStack } from '@chakra-ui/react';
import { Typography } from 'Tokens';
import { IconButton } from 'Atoms';
import { useEffect, useMemo, useState } from 'react';
import { LearnMoreDrawer, Loader, MetricLearnMoreHeader } from 'Molecules';
import { useParams, useSearchParams } from 'react-router-dom';
import { HelpIcon } from 'Tokens/Icons/Status';
import { OptOutModal } from './OptOutModal';
import {
  MetricsTableData,
  useMaterialStandardId,
  SelectedMetric,
  MetricViewEnums,
  AggregatedMetricsTableData,
  filterMetricLevels,
} from 'containers/Esrs';
import { DREnums, FrequencyEnums, TimePeriods, TimePeriodsEnums } from '../Requirement';
import { getNestedRows } from './MetricAnswers.hooks';
import { MetricsTableFilters } from './MetricsTableFilters';
import { useCurrentCompany } from 'utils/hooks';
import { MetricSidebar } from './MetricSidebar';
import { separateQualitativeMetricsFromQuantitativeParents } from './Metrics.utils';
import { isMetricStateMaterial } from 'containers/Esrs/utils';
import { MetricTableWithTitle } from './MetricTableWithTitle';
import { MetricsAISection } from './MetricAI';
import { MetricsTable } from './MetricsTable/MetricsTable';

export type AssessableMetrics = CompanyLevelMetricsPerDisclosureQuery_['assessableMetrics'];

export const Metrics = ({
  disclosureRequirementRef,
  setMetricSidebarOpen,
  disclosureRequirement,
}: {
  disclosureRequirementRef: string;
  setMetricSidebarOpen: (val: boolean) => void;
  disclosureRequirement:
    | GetBuDisclosureRequirementMetricsQuery_['requirements'][number]
    | undefined;
}) => {
  const { reportingUnitId = '', standardRef = '', esrsAssessmentId = '', view } = useParams();
  const { isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedQuarter, setSelectedQuarter] = useState<TimePeriods>(TimePeriodsEnums.q1);
  const [isOverview, setIsOverview] = useState<boolean>(false);
  const [selectedMetric, setSelectedMetric] = useState<SelectedMetric>();
  const [isChartsView] = useState(false);
  const [isComments, setIsComments] = useState<boolean>(false);
  const [rowData, setRowData] = useState<MetricsTableData>();
  const [updateMetadata] = useUpdateEsrsAssessmentMetaDataMutation();
  const { company } = useCurrentCompany();

  const isMetricDr = useMemo(() => {
    return disclosureRequirement?.type === DREnums.metric;
  }, [disclosureRequirement]);

  const { companyAssessmentId, parentAssessmentId } = useMaterialStandardId(
    standardRef,
    esrsAssessmentId
  );

  useEffect(() => {
    if (rowData) setMetricSidebarOpen(true);
    else setMetricSidebarOpen(false);
  }, [rowData]);

  const { data } = useEsrsAssessmentQuery({
    variables: { esrsAssessmentId },
    skip: !esrsAssessmentId,
  });
  const esrsAssessment = useMemo(() => data?.esrsAssessment, [data]);
  const [searchParams] = useSearchParams();
  const urlMetricRef = useMemo(() => searchParams.get('metricRef'), [searchParams]);

  const { data: buData, loading } = useReportingUnitsMetricsPerDisclosureQuery({
    variables: {
      disclosureRequirementRef,
      companyAssessmentId,
      parentAssessmentId: parentAssessmentId || companyAssessmentId,
    },
    skip: !disclosureRequirementRef || !companyAssessmentId,
  });

  const { data: ruData, loading: ruLoading } = useGetReportingUnitQuery({
    variables: { reportingUnitId },
    skip: !reportingUnitId,
  });
  const metrics = useMemo(() => buData?.assessableMetrics ?? ([] as AssessableMetrics), [buData]);

  //Chart view
  const { data: metaData, loading: metaDataLoading } = useGetEsrsAssessmentMetadataQuery({
    variables: {
      id: esrsAssessmentId,
    },
    skip: !esrsAssessmentId,
  });
  const metadata = useMemo(() => metaData?.EsrsAssessment_by_pk?.metadata, [metaData]);
  // useEffect(() => {
  //   if (metadata?.isChartView) setIsChartsView(metadata?.isChartView);
  // }, [metaData]);
  useEffect(() => {
    if (isChartsView !== metadata?.isChartView && !metaDataLoading) {
      updateMetadata({
        variables: {
          id: esrsAssessmentId,
          metadata: { ...(metadata ?? {}), isChartView: isChartsView },
        },
        refetchQueries: [GetEsrsAssessmentMetadataDocument_],
      });
    }
  }, [isChartsView]);

  useEffect(() => {
    setTimeout(() => {
      const element = document.getElementById(urlMetricRef ?? '');
      if (element && urlMetricRef === element.id) {
        setTimeout(
          () => {
            element.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
              inline: 'center',
            });
          },
          isOverview ? 0 : 300
        );
      }
    }, 500);
  }, [metrics, urlMetricRef]);

  useEffect(() => {
    const isView = view === MetricViewEnums.overview ? true : false;
    const isRouteNotMatchingState = isView !== isOverview;
    if (isRouteNotMatchingState) {
      if (view === MetricViewEnums.overview) setIsOverview(true);
      else setIsOverview(false);
    }
  }, [view]);

  const filteredMetrics = useMemo(
    () =>
      separateQualitativeMetricsFromQuantitativeParents(
        filterMetricLevels(metrics, companyAssessmentId)
      ),
    [metrics, companyAssessmentId]
  );

  const areMetricsYearly = useMemo(
    () =>
      filteredMetrics.every(
        (metric) =>
          metric.materialMetrics.find((mm) => mm.materialStandardId === companyAssessmentId)
            ?.frequency === FrequencyEnums.yearly
      ),
    [filteredMetrics]
  );

  const areMetricsQuarterly = useMemo(
    () =>
      filteredMetrics.every(
        (metric) =>
          metric.materialMetrics.find((mm) => mm.materialStandardId === companyAssessmentId)
            ?.frequency === FrequencyEnums.quarterly
      ),
    [filteredMetrics]
  );

  useEffect(() => {
    if (areMetricsYearly) setSelectedQuarter('Year');
    else setSelectedQuarter(TimePeriodsEnums.q1);
  }, [areMetricsYearly, buData]);

  const nestedMetrics = useMemo(
    () =>
      filteredMetrics.map((metric) => {
        const materialStandardId = isMetricStateMaterial(
          metric.materialMetrics.find((mm) => mm.materialStandardId === companyAssessmentId)
            ?.isMaterial
        )
          ? companyAssessmentId
          : parentAssessmentId;
        return getNestedRows(metric, materialStandardId);
      }),
    [filteredMetrics]
  );

  const qualitativeMetrics = useMemo(
    () =>
      filteredMetrics.filter(
        (metric) =>
          metric.metricType === QuestionType_Enum_.LongText_ ||
          metric.metricType === QuestionType_Enum_.YesNo_ ||
          metric.metricType === QuestionType_Enum_.SingleChoice_ ||
          metric.metricType === QuestionType_Enum_.MultipleChoice_
      ),
    [filteredMetrics]
  );

  const allFilteredMetrics = useMemo(() => {
    const metricArray: string[] = [];
    const filterMetrics = (metric: MetricsTableData, hidden?: boolean): MetricsTableData => {
      if (metric.subRows) {
        if (metricArray.includes(metric.metric.reference) && metric.parentMetric) {
          return {
            ...metric,
            hidden: hidden,
            subRows: [
              {
                metric: metric.metric,
                referenceToSource: metric.metric?.reference,
                hidden: hidden,
                subRows: metric.subRows.map((subrow) => ({
                  ...subrow,
                  hidden: true,
                  subRows: subrow?.subRows?.map((row) => filterMetrics(row, true)),
                })),
              },
            ],
          };
        }
        metricArray.push(metric.metric.reference);
        const subRows = metric?.subRows?.map((row) => filterMetrics(row, hidden));
        if (subRows) {
          return { ...metric, subRows: subRows, hidden: hidden };
        }
      }
      return { ...metric, hidden: hidden };
    };
    return [...nestedMetrics.map((metric) => filterMetrics(metric))];
  }, [nestedMetrics]);

  if (loading || metaDataLoading || ruLoading) return <Loader />;

  return (
    <HStack w="100%" alignItems="start" spacing="24px">
      <VStack spacing="24px" alignItems="start" w="100%">
        <HStack justifyContent="space-between" w="100%">
          <HStack>
            <Typography variant="bodyLarge" as="span" color="text.hint">
              {disclosureRequirement?.reference}{' '}
              <Typography variant="h3" as="span">
                {disclosureRequirement?.title}
              </Typography>
            </Typography>
          </HStack>
          <HStack>
            <IconButton
              aria-label="learn more"
              variant="ghost"
              icon={<HelpIcon />}
              size="sm"
              onClick={() => {
                setSelectedMetric({
                  title: disclosureRequirement?.title,
                  description: disclosureRequirement?.description ?? '',
                  isDisclosure: true,
                });
                onDrawerOpen();
              }}
            />
          </HStack>
        </HStack>
        <>
          {!areMetricsYearly && (
            <MetricsTableFilters
              selectedQuarter={selectedQuarter}
              setSelectedQuarter={setSelectedQuarter}
              areMetricsQuarterly={areMetricsQuarterly}
              areMetricsYearly={areMetricsYearly}
            />
          )}

          {isMetricDr && (
            <MetricsAISection
              disclosureRequirementRef={disclosureRequirementRef}
              materialStandardId={companyAssessmentId}
              aggregatedMetricsAnswers={[]}
              reportingUnitId={reportingUnitId}
              esrsAssessmentProjectLeader={esrsAssessment?.projectLeader ?? undefined}
              allFilteredMetrics={allFilteredMetrics}
              areMetricsYearly={areMetricsYearly}
              isCompanyLevel={false}
              isGroup={false}
              onDrawerOpen={onDrawerOpen}
              rowData={rowData}
              setRowData={setRowData}
              onOpen={onOpen}
              qualitativeMetrics={qualitativeMetrics.map((m) => ({ metric: m }))}
              selectedQuarter={selectedQuarter}
              summaryQualitativeMetrics={[]}
              setSelectedMetric={setSelectedMetric}
            />
          )}

          <VStack spacing="40px" alignItems="stretch" w="100%" minH="fit-content" mb="40px">
            {allFilteredMetrics
              .filter((m) => m.metric.metricType === QuestionType_Enum_.Decimal_)
              .map((metric) => (
                <MetricTableWithTitle
                  metric={metric}
                  selectedQuarter={selectedQuarter}
                  onOpen={onOpen}
                  setSelectedMetric={setSelectedMetric}
                  onDrawerOpen={onDrawerOpen}
                  companyStandardId={companyAssessmentId}
                  areMetricsYearly={areMetricsYearly}
                  currency={company?.currency ?? ''}
                  esrsAssessmentProjectLeader={esrsAssessment?.projectLeader ?? undefined}
                  rowData={rowData}
                  isGroup={company?.isGroupOwner}
                  setRowData={setRowData}
                  filteredMetrics={allFilteredMetrics}
                  isMetricDr={isMetricDr}
                  disclosureRequirementTitle={disclosureRequirement?.title ?? ''}
                  businessUnitName={ruData?.bu?.name ?? ''}
                  setIsComments={setIsComments}
                />
              ))}
            {!isOverview && !!qualitativeMetrics.length && (
              <>
                <MetricsTable
                  metrics={allFilteredMetrics.filter(
                    (m) => m.metric.metricType !== QuestionType_Enum_.Decimal_
                  )}
                  selectedQuarter={selectedQuarter}
                  onOpen={onOpen}
                  setSelectedMetric={setSelectedMetric}
                  onDrawerOpen={onDrawerOpen}
                  materialStandardId={companyAssessmentId}
                  areMetricsYearly={areMetricsYearly}
                  currency={company?.currency ?? ''}
                  esrsAssessmentProjectLeader={esrsAssessment?.projectLeader ?? undefined}
                  rowData={rowData}
                  setRowData={setRowData}
                  allMetrics={allFilteredMetrics}
                  isMetricDr={isMetricDr}
                  setIsComments={setIsComments}
                  isNarrative
                  isBusinessUnit
                  isGroup={company?.isGroupOwner ?? false}
                />
              </>
            )}
          </VStack>
        </>
        <LearnMoreDrawer
          header={selectedMetric?.isDisclosure ? selectedMetric.title : undefined}
          isOpen={isDrawerOpen}
          onClose={onDrawerClose}
          description={selectedMetric?.description}
          customHeader={
            !selectedMetric?.isDisclosure ? (
              <MetricLearnMoreHeader
                metricRef={selectedMetric?.reference ?? ''}
                tags={selectedMetric?.tags ?? []}
              />
            ) : undefined
          }
        />
        <OptOutModal
          isOpen={isOpen}
          onClose={onClose}
          assessmentId={esrsAssessmentId}
          reportingUnitId={reportingUnitId}
          metricRef={selectedMetric?.reference ?? ''}
          isCompany={false}
        />
      </VStack>
      {!!rowData && (
        <MetricSidebar
          row={rowData}
          isComments={isComments}
          metric={rowData?.metric ?? ({} as MetricsTableData['metric'])}
          materialStandardId={companyAssessmentId}
          companyReportingUnit={reportingUnitId}
          esrsAssessmentProjectLeader={esrsAssessment?.projectLeader ?? undefined}
          selectedMetric={selectedMetric}
          setSelectedMetric={setSelectedMetric}
          onMenuOpen={onOpen}
          selectedQuarter={selectedQuarter}
          setRowData={setRowData}
          isGroup={false}
          isMetricDr={isMetricDr}
          companyCurrency={company?.currency ?? ''}
        />
      )}
    </HStack>
  );
};

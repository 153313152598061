import usePortfolios, {
  PortfolioCompanyScore,
  useDeletePortfolioCompany,
} from 'containers/Portfolios/Portfolios.hooks';
import { ActionMenu, SearchInput, Table } from 'Molecules';
import { useNavigate, useParams } from 'react-router-dom';
import { useToast, useCurrentCompany } from 'utils/hooks';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'utils/translation';
import { PortfolioSummary } from 'containers/Portfolios/pieces/PortfoliosList';
import {
  Box,
  HStack,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import {
  Button,
  Checkbox,
  ProgressBar,
  Tag,
  EmptyState,
  VisibleIf,
  Alert,
  StickyToolbar,
} from 'Atoms';
import { Typography } from 'Tokens';
import { PortfolioCompany, useUserCompaniesQuery } from 'models';
import { formatNum } from 'utils/numbers';
import { TaxonomyScoreChart } from 'Organisms/TaxonomyScoreChart/TaxonomyScoreChart';
import { EligibilityStatus, PortfolioCompanyEditorModal } from './PortfolioCompanyEditor';
import { NudgePortfolioCompanyModal } from 'containers/Portfolios/pieces/NudgePortfolioCompanyModal';
import { useUserData } from '@nhost/react';
import { CommentIcon, DeleteIcon, EditIcon, RefreshIcon } from 'Tokens/Icons/Function';
import { ReportPeriods } from './PortfolioView';
import { formatDate, formatDateToDDMMYYYY, formatQuarterYear } from 'utils/date';
import { protfolioCompaniesSorter } from './PortfolioCompanies';

enum FilterType {
  all = 'All',
  eligible = 'Eligible',
  notEligible = 'Not eligible',
  notStarted = 'Not started',
  notFinished = 'Not finished',
}

const FILTER_TYPES = [
  FilterType.all,
  FilterType.eligible,
  FilterType.notEligible,
  FilterType.notStarted,
  FilterType.notFinished,
];

const isEligible = (pCompany: PortfolioCompany) => {
  if (pCompany.progress?.financials === 0 || pCompany.progress?.screening === 0) return false;
  const scores = pCompany.scores;

  return [
    scores?.revenue?.eligible ?? 0,
    scores?.capex?.eligible ?? 0,
    scores?.opex?.eligible ?? 0,
  ].some((n) => n > 0);
};

const isNotEligible = (pCompany: PortfolioCompany) => {
  if (
    (pCompany.progress?.financials === 0 || pCompany.progress?.screening === 0) &&
    !pCompany.progress.isLocked
  )
    return false;
  const scores = pCompany.scores;

  return [
    100 - scores?.revenue?.eligible ?? 0,
    100 - scores?.capex?.eligible ?? 0,
    100 - scores?.opex?.eligible ?? 0,
  ].every((n) => n === 100);
};
const hasStarted = (pCompany: PortfolioCompany) => {
  return (pCompany?.progress?.financials ?? 0) > 0 || (pCompany?.progress?.screening ?? 0) > 0;
};

const hasFinished = (pCompany: PortfolioCompany) => {
  return (
    (pCompany.progress?.financials === 100 && pCompany.progress?.screening === 100) ||
    pCompany.progress?.isLocked
  );
};
const filterCompanies = (companies: PortfolioCompany[], filter: FilterType) => {
  if (filter === FilterType.eligible) return companies.filter((c) => isEligible(c));
  if (filter === FilterType.notEligible) {
    return companies.filter((c) => isNotEligible(c));
  }
  if (filter === FilterType.notStarted) {
    return companies.filter((c) => !hasStarted(c));
  }
  if (filter === FilterType.notFinished) {
    return companies.filter((c) => !hasFinished(c));
  }
  return companies;
};

const searchName = (c: PortfolioCompany, query: string) => {
  if (query === '' || !query) return true;
  return c?.company?.name?.toLowerCase().includes(query.toLowerCase());
};

const ProgressMarker = ({
  progressPercentage,
  isCompleted,
}: {
  progressPercentage: number;
  isCompleted: boolean;
}) => {
  const { t } = useTranslation(['portfolio', 'common']);
  if (isCompleted) return <Tag variant="success">{t('common:assessment.isLocked.completed')}</Tag>;
  if (progressPercentage === 0)
    return <Tag variant="undefined">{t('common:assessment.isLocked.notStarted')}</Tag>;

  return (
    <VStack width="128px" spacing="8px" alignItems="flex-start">
      <Typography variant="detailStrong">{formatNum(progressPercentage, 0)}%</Typography>
      <ProgressBar completed={progressPercentage} noTooltip />
    </VStack>
  );
};

export const NotEligibleTag = () => {
  const { t } = useTranslation(['portfolio', 'common']);

  return (
    <Tag variant="undefined" helpLabel={t('portfolio:notRequested.tooltip')} rightIconMargin="12px">
      {t('common:assessment.isLocked.notRequested')}
    </Tag>
  );
};

export const PortfolioCompanyProgress = ({
  progress,
}: {
  progress: PortfolioCompanyScore['progress'];
}) => {
  const { t } = useTranslation(['portfolio', 'common']);
  const total = (progress.financials + progress.screening) / 2;
  const isCompleted = progress.isLocked;

  const outOfTen = (num: number) => {
    if (num === 0) return 0;
    return formatNum(num, 0);
  };

  return (
    <Popover placement="auto" trigger="hover">
      <PopoverTrigger>
        <VStack alignItems="start">
          <ProgressMarker progressPercentage={total} isCompleted={isCompleted} />
        </VStack>
      </PopoverTrigger>
      <PopoverContent padding="16px">
        <VStack width="100%" spacing="16px" alignItems="stretch">
          <VStack width="100%" alignItems="flex-start" spacing="8px">
            <HStack width="100%" justifyContent="space-between">
              <Typography variant="bodyStrong">{t('common:words.progress')}</Typography>
              <Typography variant="h4">{formatNum(total, 0)}%</Typography>
            </HStack>
            <ProgressBar completed={total} noTooltip />
          </VStack>
          <VStack width="100%" spacing="8px" alignItems="stretch">
            <VStack width="100%" bg="bg.muted" borderRadius="6px" spacing="8px" padding="8px 12px">
              <HStack width="100%" justifyContent="space-between">
                <Typography variant="detailStrong">{t('common:assessment.screening')}</Typography>
                <Typography variant="detailStrong">{outOfTen(progress.screening)}%</Typography>
              </HStack>
              <ProgressBar completed={progress.screening} noTooltip />
            </VStack>

            <VStack width="100%" bg="bg.muted" borderRadius="6px" spacing="8px" padding="8px 12px">
              <HStack width="100%" justifyContent="space-between">
                <Typography variant="detailStrong">{t('common:assessment.financials')}</Typography>
                <Typography variant="detailStrong">{outOfTen(progress.financials)}%</Typography>
              </HStack>
              <ProgressBar completed={progress.financials} noTooltip />
            </VStack>
            <VStack width="100%" bg="bg.muted" borderRadius="6px" spacing="8px" padding="8px 12px">
              <HStack width="100%" justifyContent="space-between">
                <Typography variant="detailStrong">
                  {t('common:assessment.isLocked.label')}
                </Typography>
                <Tag variant={progress.isLocked ? 'success' : 'warning'}>
                  {t(`common:assessment.isLocked.${progress.isLocked}`)}
                </Tag>
              </HStack>
            </VStack>
          </VStack>
        </VStack>
      </PopoverContent>
    </Popover>
  );
};

export const PortfolioTaxonomy = ({
  reportPeriod,
  year,
  isAuditor = false,
}: {
  reportPeriod: ReportPeriods;
  year: string;
  isAuditor?: boolean;
}) => {
  const user = useUserData();
  const { data: userCompaniesData } = useUserCompaniesQuery({
    variables: {
      id: user?.id,
    },
    skip: !user,
  });
  const navigate = useNavigate();
  const { company } = useCurrentCompany();
  const { getPortfolio, loading } = usePortfolios();
  const { t } = useTranslation(['portfolio', 'common']);
  const deletePortfolioCompany = useDeletePortfolioCompany();
  const [showNudger, setShowNudger] = useState(false);
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [currentEdit, setCurrentEdit] = useState<PortfolioCompany | undefined>(undefined);
  const [selectedRows, setSelectedRows] = useState<PortfolioCompany[]>([]);
  const [currentFilter, setCurrentFilter] = useState<FilterType>(FilterType.all);
  const { portfolioId } = useParams();
  const portfolio = getPortfolio(portfolioId ?? '', year);
  const toast = useToast();
  const [search, setSearch] = useState('');

  const visibleCompanies = useMemo(() => {
    return (
      filterCompanies((portfolio?.portfolioCompanies ?? []) as PortfolioCompany[], currentFilter)
        .filter((c) => searchName(c, search))
        .sort((a, b) => protfolioCompaniesSorter(a?.company?.name, b?.company?.name)) ?? []
    );
  }, [portfolio?.portfolioCompanies, currentFilter, search, year]);

  const taxonomyReport = useMemo(
    () =>
      portfolio?.taxonomyReports?.find(
        (report) => formatQuarterYear(report.quarter, report.year) === year
      ),
    [year]
  );
  useEffect(() => {
    if (!portfolio && !loading) {
      toast({
        text: t('portfolio:toast.notFound'),
        variant: 'danger',
      });
    }
  }, [portfolio, loading]);

  return (
    <VStack spacing="md" width="100%" alignItems="center" paddingTop="16px">
      <VStack cursor="pointer" alignItems="stretch" width="100%" spacing="32px">
        <VStack spacing="16px">
          {taxonomyReport?.dueDate && (
            <Alert
              status="neutral"
              closable={false}
              title={`Note that data for EU Taxonomy is collected yearly. Deadline is ${
                taxonomyReport?.dueDate
                  ? formatDateToDDMMYYYY(formatDate(taxonomyReport?.dueDate))
                  : 'not set yet'
              }`}
            />
          )}
          <PortfolioSummary
            portfolioCompanies={(portfolio?.portfolioCompanies ?? []) as PortfolioCompany[]}
            reportPeriod={reportPeriod}
          />
        </VStack>

        <HStack justifyContent="space-between" width="100%">
          <HStack spacing="16px">
            <SearchInput
              placeholder={t('portfolio:filterCompanies')}
              search={search}
              setSearch={setSearch}
              width="100%"
            />
            <HStack spacing="8px">
              {FILTER_TYPES.map((f) => (
                <Button
                  variant="secondary"
                  selected={currentFilter === f}
                  onClick={() => setCurrentFilter(f)}
                  borderRadius="32px"
                  key={f}
                  color="text.default"
                  _hover={{
                    color: 'text.default',
                  }}
                  size="sm"
                >
                  {f} {filterCompanies(portfolio?.portfolioCompanies ?? [], f).length}
                </Button>
              ))}
            </HStack>
          </HStack>

          <HStack>
            <Button
              variant="ghost"
              leftIcon={<CommentIcon color="inherit" />}
              onClick={() => {
                setSelectedRows(visibleCompanies ?? []);
                setShowNudger(true);
              }}
            >
              {t('portfolio:reminderReport')}
            </Button>
          </HStack>
        </HStack>
        {!visibleCompanies.length && search ? (
          <Box w="100%" h="100%">
            <EmptyState
              title={t('common:search.filter.emptyTitle')}
              description={t('common:search.filter.emptyDescription')}
              callToAction={{
                text: t('common:search.filter.emptyBtn'),
                variant: 'secondary',
                onClick: () => {
                  setSearch('');
                },
                leftIcon: <RefreshIcon color="inherit" />,
              }}
              component={true}
            />
          </Box>
        ) : (
          <Table<PortfolioCompany>
            rowProps={{
              cursor: 'pointer',
              _hover: {
                bg: 'bg.hover',
              },
            }}
            columns={[
              {
                header: () => (
                  <Checkbox
                    isDisabled={!visibleCompanies.length}
                    isChecked={selectedRows.length === visibleCompanies?.length}
                    onChange={(e) =>
                      setSelectedRows(e.currentTarget.checked ? visibleCompanies ?? [] : [])
                    }
                  />
                ),
                id: 'checkbox',
                cell: ({ row }) => (
                  <Checkbox
                    isChecked={
                      !!selectedRows.find((x) => (row.original as PortfolioCompany).id === x.id)
                    }
                    onChange={(e) => {
                      if (e.currentTarget.checked) {
                        setSelectedRows((cur) => [...cur, row.original as PortfolioCompany]);
                      } else
                        setSelectedRows((cur) => [
                          ...cur.filter(
                            (item) => item.id !== (row.original as PortfolioCompany).id
                          ),
                        ]);
                    }}
                  />
                ),
              },

              {
                header: t('common:company'),
                id: 'name',
                cell: ({ row }) => {
                  const hasAccessToCompany = userCompaniesData?.data?.companies?.find(
                    (c) => c.company?.id === row.original?.company?.id
                  );

                  return (
                    <HStack
                      width="100%"
                      onClick={() =>
                        hasAccessToCompany && navigate(`/${row.original?.company?.id}`)
                      }
                    >
                      <Tooltip label={hasAccessToCompany ? t('common:goToCompany') : ''}>
                        <Typography variant="bodyStrong">
                          {(row.original as PortfolioCompany).company?.name ??
                            (row.original as PortfolioCompany).estimateCompany?.name ??
                            t('portfolio:fields.noName')}
                        </Typography>
                      </Tooltip>
                    </HStack>
                  );
                },
              },
              {
                header: t('portfolio:assessmentStatus'),
                id: 'status',
                cell: ({ row }) => {
                  const isTaxonomyEligible =
                    row?.original?.eligibilityStatus !== EligibilityStatus.notEligible;
                  return (
                    <>
                      <VisibleIf condition={!isTaxonomyEligible}>
                        <NotEligibleTag />
                      </VisibleIf>

                      <VisibleIf condition={isTaxonomyEligible}>
                        <PortfolioCompanyProgress
                          progress={
                            row?.original?.progress ?? {
                              financials: 0,
                              screening: 0,
                              isLocked: false,
                            }
                          }
                        />
                      </VisibleIf>
                    </>
                  );
                },
              },
              {
                header: t('portfolio:fields.amount.label'),
                id: 'amount',
                cell: ({ row }) => {
                  const valueOfInvestments = row.original.valueOfInvestments;
                  const quarterValue: number = valueOfInvestments?.[reportPeriod];
                  const yearValue: number =
                    (valueOfInvestments.q1 +
                      valueOfInvestments.q2 +
                      valueOfInvestments.q3 +
                      valueOfInvestments.q4) /
                    4;
                  return (
                    <>
                      {reportPeriod !== 'year' ? (
                        <Typography variant="body">
                          {formatNum(quarterValue)} {company?.currency}
                        </Typography>
                      ) : (
                        <Typography variant="body">
                          {formatNum(yearValue)} {company?.currency}
                        </Typography>
                      )}
                    </>
                  );
                },
              },
              {
                header: t('common:financials.revenue'),
                id: 'revenue',
                cell: ({ row }) => (
                  <HStack>
                    <TaxonomyScoreChart
                      score={row.original.scores?.revenue ?? {}}
                      progress={
                        row.original.estimateCompany
                          ? { financials: 100, screening: 100 }
                          : row?.original?.progress ?? { financials: 0, screening: 0 }
                      }
                      title={t('common:financials.revenue')}
                      size="sm"
                      withDetails={true}
                      scData={row.original.substantialContributionProportion}
                      companyName={row.original.company?.name}
                    />
                  </HStack>
                ),
              },
              {
                header: t('common:financials.capex'),
                id: 'capex',
                cell: ({ row }) => (
                  <HStack>
                    <TaxonomyScoreChart
                      score={(row.original as PortfolioCompany).scores?.capex ?? {}}
                      progress={
                        row.original.estimateCompany
                          ? { financials: 100, screening: 100 }
                          : row?.original?.progress ?? { financials: 0, screening: 0 }
                      }
                      title={t('common:financials.capex')}
                      size="sm"
                      withDetails={true}
                      scData={row.original.substantialContributionProportion}
                      companyName={row.original.company?.name}
                    />
                  </HStack>
                ),
              },
              {
                header: t('common:financials.opex'),
                id: 'opex',
                cell: ({ row }) => (
                  <HStack>
                    <TaxonomyScoreChart
                      score={(row.original as PortfolioCompany).scores?.opex ?? {}}
                      progress={
                        row.original.estimateCompany
                          ? { financials: 100, screening: 100 }
                          : row?.original?.progress ?? { financials: 0, screening: 0 }
                      }
                      title={t('common:financials.opex')}
                      size="sm"
                      withDetails={true}
                      scData={row.original.substantialContributionProportion}
                      companyName={row.original.company?.name}
                    />
                  </HStack>
                ),
              },
              {
                header: '',
                id: 'actions',
                cell: ({ row }) => (
                  <ActionMenu
                    actions={[
                      {
                        name: t('common:actions.edit'),
                        onClick: () => setCurrentEdit(row.original as PortfolioCompany),
                        icon: <EditIcon color="inherit" />,
                        isDisabled: isAuditor,
                      },
                      {
                        name: t('common:actions.delete'),
                        onClick: () => deletePortfolioCompany(row.original as PortfolioCompany),
                        icon: <DeleteIcon color="inherit" />,
                        isDisabled: isAuditor,
                      },
                      {
                        name: t('portfolio:reminderReport'),
                        onClick: () => {
                          setSelectedRows([row.original as PortfolioCompany]);
                          setShowNudger(true);
                        },
                        icon: <CommentIcon color="inherit" />,
                      },
                    ]}
                  />
                ),
              },
            ]}
            data={visibleCompanies}
          />
        )}
        <PortfolioCompanyEditorModal
          portfolioCompany={currentEdit}
          isOpen={isCreating || !!currentEdit}
          onClose={() => {
            setCurrentEdit(undefined);
            setIsCreating(false);
          }}
          currentPortfolioCompanyIds={portfolio?.portfolioCompanies.map((pc) => pc.id)}
          reportYear={year}
        />
        <NudgePortfolioCompanyModal
          portfolioCompanies={selectedRows}
          isOpen={showNudger}
          onClose={() => {
            setShowNudger(false);
            setSelectedRows([]);
          }}
        />
        {selectedRows.length && (
          <StickyToolbar
            onClick={() => setShowNudger(true)}
            count={selectedRows.length}
            onClose={() => setSelectedRows([])}
            actionText={t('common:words.nudge')}
            actionIcon={<CommentIcon color="inherit" />}
          />
        )}
      </VStack>
    </VStack>
  );
};

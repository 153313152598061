import { sortBy } from 'lodash';
import { AssessableMetrics } from './Metrics';
import { QuestionType_Enum_ } from 'models';
import { AggregatedMetricsTableData } from './AggregatedMetrics';
import { TableData } from 'Molecules/NestedTable';

export const orderMetrics = (metrics: AssessableMetrics) => {
  const sortedMetrics = sortBy(metrics, ['globalOrder', 'order', 'firstLevel', 'secondLevel']);

  return sortedMetrics;
};

export const filterChildrenInFirstLevelMetrics = (
  metrics: AssessableMetrics,
  materialStandardId: string
) => {
  return (
    metrics.filter((metric) => {
      const materialMetric = metric.materialMetrics.find(
        (mm) => mm.materialStandardId === materialStandardId
      );

      const hasParentInFirstLevel = metric.parentMetrics.some((p) =>
        metrics.some((m) => m.reference === p.parentMetricRef)
      );

      return !(materialMetric?.isMaterial && hasParentInFirstLevel);
    }) ?? []
  );
};

type Metric = AssessableMetrics[number];

export const separateQualitativeMetricsFromQuantitativeParents = (
  metrics: AssessableMetrics
): AssessableMetrics => {
  const qualMetrics: AssessableMetrics = [];

  const processMetric = (metric: Metric): Metric => {
    const isQuantitative = metric.metricType === QuestionType_Enum_.Decimal_;

    if (isQuantitative && metric.childrenMetrics?.length > 0) {
      const filteredChildrenMetrics = metric.childrenMetrics.filter((child) => {
        if (child.childMetric?.metricType === QuestionType_Enum_.LongText_) {
          qualMetrics.push({
            ...child.childMetric,
            childrenMetrics: child.childMetric.childrenMetrics?.map((grandChild) => ({
              ...grandChild,
              childMetric: grandChild.childMetric
                ? processMetric(grandChild.childMetric as Metric)
                : grandChild.childMetric,
            })),
          });
          return false;
        }
        return true;
      });
      return {
        ...metric,
        childrenMetrics: filteredChildrenMetrics.map((child) => ({
          ...child,
          childMetric: child.childMetric
            ? processMetric(child.childMetric as Metric)
            : child.childMetric,
        })),
      };
    }
    return metric;
  };

  const filteredMetrics = metrics.map(processMetric);

  return [...filteredMetrics, ...qualMetrics];
};

const getUrlParameter = (row: TableData<AggregatedMetricsTableData>) => {
  if (row?.tagType && row?.tagName) {
    return `?tagGroup=${row.tagName}`;
  } else if (!row?.tags && row?.tagName) {
    return `?tagChild=${row.tagName}`;
  } else if (row?.tags) {
    return `?tag=${row.tagName}`;
  }
  return '';
};

export const getMetricChartUrl = (data: {
  row: AggregatedMetricsTableData;
  metricRef: string;
  standardId: string;
  companyId: string | null;
  esrsAssessmentId: string;
  standardRef: string;
  disclosureRequirementRef: string;
}) => {
  const {
    row,
    metricRef,
    standardId,
    companyId,
    esrsAssessmentId,
    standardRef,
    disclosureRequirementRef,
  } = data;

  const baseMetricChartUrl = `/${companyId}/esrs/${esrsAssessmentId}/standard/${standardRef}/disclosure-requirement/metric/${disclosureRequirementRef}/chart/${metricRef}/${standardId}`;

  const urlParameter = getUrlParameter(row);

  return `${baseMetricChartUrl}${urlParameter}`;
};
export const flattenNarrativeMetrics = (narrativeMetrics: AssessableMetrics): AssessableMetrics => {
  const flattenedMetrics: AssessableMetrics = [];

  const flattenMetric = (metric: AssessableMetrics[number]) => {
    if (!metric.childrenMetrics || metric.childrenMetrics.length === 0) {
      flattenedMetrics.push(metric);
    } else {
      metric.childrenMetrics.forEach((child) => {
        flattenMetric(child?.childMetric as AssessableMetrics[number]);
      });
    }
  };

  narrativeMetrics.forEach((metric) => {
    flattenMetric(metric);
  });

  return flattenedMetrics;
};

import { VStack, HStack } from '@chakra-ui/react';
import { Tag } from 'Atoms';
import { Typography } from 'Tokens';
import { TimePeriods, TimePeriodsEnums } from '../Requirement';

export const MetricsTableFilters = ({
  selectedQuarter,
  setSelectedQuarter,
  areMetricsYearly,
  areMetricsQuarterly,
}: {
  selectedQuarter: TimePeriods;
  setSelectedQuarter: (p: TimePeriods) => void;
  areMetricsYearly: boolean;
  areMetricsQuarterly: boolean;
}) => {
  return (
    <VStack gap="8px" width="100%">
      <HStack
        padding="8px"
        borderRadius="8px"
        bgColor="bg.muted"
        width="100%"
        justifyContent="space-between"
      >
        <HStack gap="6px"></HStack>
        {!areMetricsYearly && (
          <HStack gap="6px">
            <Typography variant="bodyStrong">Select period:</Typography>
            {Object.values(TimePeriodsEnums)
              .filter((p) => (areMetricsQuarterly ? p !== 'Year' : true))
              .map((quarter) => (
                <Tag
                  title={quarter}
                  isSelected={selectedQuarter === quarter ? true : false}
                  clickable={selectedQuarter === quarter ? false : true}
                  onClick={() => setSelectedQuarter(quarter)}
                />
              ))}
          </HStack>
        )}
      </HStack>
    </VStack>
  );
};

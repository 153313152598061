import { Box, Checkbox, HStack } from '@chakra-ui/react';
import { Row } from '@tanstack/react-table';
import { IconButton } from 'Atoms';
import { QuestionType_Enum_ } from 'models';
import React, { Dispatch, MutableRefObject, SetStateAction, useMemo, useRef } from 'react';
import { Typography } from 'Tokens';
import { ArrowCornerDownRight, ChevronDownIcon, ChevronRightIcon } from 'Tokens/Icons/Direction';
import { SettingsIcon } from 'Tokens/Icons/Function';
import { HelpIcon } from 'Tokens/Icons/Status';
import { MetricTypeIcon, MetricTypes } from 'Molecules/MetricTypeIcon';
import { SelectedMetric } from 'containers/Esrs/EsrsAssessment.hooks';
import { uniq } from 'lodash';
import {
  DataCollectionLabelOptions,
  DataCollectionRowEnum,
  DataCollectionType,
  TableMetricData,
} from '../DataCollection.d';
import { MetricNameWithTag } from '../DataCollectionUtils';
import { useEsrsMetricData } from '../../DisclosureRequirements/Metrics/Metrics.hooks';
import { getDataCollectionText } from 'containers/Esrs/utils';

const isDescendantOfSelectedMetric = (row: TableMetricData, selectedRows: TableMetricData[]) => {
  for (const selectedRow of selectedRows) {
    const stack = [selectedRow];
    while (stack.length > 0) {
      const currentRow = stack.pop();
      if (currentRow?.reference === row.reference) {
        return true;
      }
      stack.push(...(currentRow?.subRows || []));
    }
  }
  return false;
};

export const MetricRefOrderNumber = ({ row }: { row: Row<TableMetricData> }) => {
  const metric = useMemo(() => row.original, [row]);
  const { metricRefNumber } = useEsrsMetricData(metric.reference, metric);

  return <Typography variant="body">{metricRefNumber}</Typography>;
};

export const MetricShortTitleView = ({
  row,
  companyStandardId,
}: {
  row: Row<TableMetricData>;
  companyStandardId: string;
}) => {
  const [rowData, materialMetric] = useMemo(() => {
    return [
      row.original,
      row.original.materialMetrics.find((mm) => mm.materialStandardId === companyStandardId),
    ];
  }, [row, companyStandardId]);

  const adminPanelTags = useMemo(() => rowData.adminPanelTags?.map((tag) => tag.type), [rowData]);

  const materialMetricTags = useMemo(
    () => materialMetric?.materialMetricTags?.map((tag) => tag.tagType) ?? [],
    [materialMetric]
  );

  const combinedTags = useMemo(
    () => uniq([...adminPanelTags, ...materialMetricTags]),
    [materialMetricTags, adminPanelTags]
  );

  const metricNameRef = useRef<HTMLDivElement>(null);

  const getMetricIcon = () => {
    if (rowData.metricType === QuestionType_Enum_.LongText_) {
      return <MetricTypeIcon type={MetricTypes.text} />;
    }
    if (rowData.metricType === QuestionType_Enum_.YesNo_) {
      return <MetricTypeIcon type={MetricTypes.boolean} />;
    }
    return <MetricTypeIcon type={MetricTypes.number} />;
  };

  return (
    <HStack pl={`${row.depth * 24}px`} spacing="8px" width="100%">
      {row.getCanExpand() ? (
        <IconButton
          variant={'ghost'}
          size="xs"
          onClick={row.getToggleExpandedHandler()}
          aria-label="expand"
          icon={row.getIsExpanded() ? <ChevronDownIcon /> : <ChevronRightIcon />}
        />
      ) : (
        getMetricIcon()
      )}

      <Box ref={metricNameRef} width="100%">
        <MetricNameWithTag
          name={rowData.shortTitle ?? rowData.title}
          tags={rowData.type === DataCollectionRowEnum.metric ? combinedTags : []}
          rowRef={metricNameRef as MutableRefObject<HTMLDivElement>}
        />
      </Box>
    </HStack>
  );
};

export const DataCollectionCheckboxHeader = React.memo(
  ({
    tableRows,
    selectedRows,
    disclosureRequirementRef,
    setSelectedRows,
  }: {
    tableRows: TableMetricData[];
    selectedRows: TableMetricData[];
    disclosureRequirementRef: string;
    setSelectedRows: Dispatch<SetStateAction<TableMetricData[]>>;
  }) => {
    return (
      <Box display="flex" alignItems="center">
        <Checkbox
          isDisabled={!tableRows.length}
          isChecked={
            selectedRows.length === tableRows?.length &&
            selectedRows[0].disclosureRequirementRef === disclosureRequirementRef
          }
          isIndeterminate={
            selectedRows.length !== 0 &&
            selectedRows.length < tableRows?.length &&
            selectedRows[0].disclosureRequirementRef === disclosureRequirementRef
          }
          onChange={(e) => {
            if (e.currentTarget.checked) {
              setSelectedRows(
                tableRows.filter((row) => row.disclosureRequirementRef === disclosureRequirementRef)
              );
            } else {
              setSelectedRows([]);
            }
          }}
        />
      </Box>
    );
  }
);

export const DataCollectionCheckboxCell = React.memo(
  ({
    row,
    tableRows,
    selectedRows,
    setSelectedRows,
    disclosureRequirementRef,
  }: {
    row: Row<TableMetricData>;
    tableRows: TableMetricData[];
    selectedRows: TableMetricData[];
    disclosureRequirementRef: string;
    setSelectedRows: Dispatch<SetStateAction<TableMetricData[]>>;
  }) => {
    const rowData = useMemo(() => row.original, [row]);
    return (
      <Box display="flex" alignItems="center">
        <Checkbox
          // Disable if the row it is a child and either it is not included in the top level rows or it is a descendant of a selected row but not selected itself
          isDisabled={
            rowData.isChild &&
            (!tableRows.includes(rowData) ||
              (isDescendantOfSelectedMetric(rowData, selectedRows) &&
                !selectedRows.includes(rowData)))
          }
          isChecked={isDescendantOfSelectedMetric(row.original, selectedRows)}
          onChange={(e) => {
            if (e.currentTarget.checked) {
              if (
                selectedRows.length > 0 &&
                selectedRows[0].disclosureRequirementRef !== disclosureRequirementRef
              ) {
                setSelectedRows([row.original]);
              } else {
                setSelectedRows((cur) => [...cur, rowData]);
              }
            } else
              setSelectedRows((cur) => [
                ...cur.filter((item) => item.reference !== rowData.reference),
              ]);
          }}
        />
      </Box>
    );
  }
);

export const DataCollectionDataGatheringLevelCell = ({
  row,
  companyStandardId,
}: {
  row: Row<TableMetricData>;
  companyStandardId: string;
}) => {
  const [materialMetric, parentMaterialMetric, isChild] = useMemo(() => {
    const materialMetrics = row.original.materialMetrics;
    const parentMaterialMetrics = row.original.parentMetric?.materialMetrics;
    return [
      materialMetrics.find((mm) => mm.materialStandardId === companyStandardId),
      parentMaterialMetrics?.find((mm) => mm.materialStandardId === companyStandardId),
      row.original.isChild,
    ];
  }, [row]);

  const isParentMetricMaterial = useMemo(
    () => parentMaterialMetric?.isMaterial,
    [parentMaterialMetric]
  );
  const dataCollection = useMemo(() => materialMetric?.dataCollection, [materialMetric]);

  if (isChild && isParentMetricMaterial)
    return (
      <HStack color="text.hint">
        <ArrowCornerDownRight color="inherit" />
        <Typography variant="body" color="inherit">
          {getDataCollectionText(parentMaterialMetric?.dataCollection ?? '')}
        </Typography>
      </HStack>
    );

  return (
    <HStack>
      {!!dataCollection && DataCollectionLabelOptions[dataCollection as DataCollectionType]?.icon}
      <Typography variant="body">
        {getDataCollectionText(materialMetric?.dataCollection ?? '')}
      </Typography>
    </HStack>
  );
};

export const DataCollectionFrequencyCell = ({
  row,
  companyStandardId,
}: {
  row: Row<TableMetricData>;
  companyStandardId: string;
}) => {
  const [materialMetric, parentMaterialMetric, isChild] = useMemo(() => {
    const materialMetrics = row.original.materialMetrics;
    const parentMaterialMetrics = row.original.parentMetric?.materialMetrics;
    return [
      materialMetrics.find((mm) => mm.materialStandardId === companyStandardId),
      parentMaterialMetrics?.find((mm) => mm.materialStandardId === companyStandardId),
      row.original.isChild,
    ];
  }, [row]);

  const isParentMetricMaterial = useMemo(
    () => parentMaterialMetric?.isMaterial,
    [parentMaterialMetric]
  );

  if (isChild && isParentMetricMaterial)
    return (
      <HStack color="text.hint">
        <ArrowCornerDownRight color="inherit" />
        <Typography variant="body" color="inherit">
          {parentMaterialMetric?.frequency}
        </Typography>
      </HStack>
    );

  return <Typography variant="body">{materialMetric?.frequency}</Typography>;
};

export const DataCollectionSettingsAndLearnMoreCell = React.memo(
  ({
    row,
    companyStandardId,
    parentStandardId,
    isSubsidiaryAndNonMetricDR,
    setSelectedMetricData,
    setSelectedMetric,
    onDrawerOpen,
    onConfigModalOpen,
  }: {
    row: Row<TableMetricData>;
    companyStandardId: string;
    parentStandardId: string;
    isSubsidiaryAndNonMetricDR: boolean;
    setSelectedMetricData: (selectedMetric: TableMetricData) => void;
    setSelectedMetric: (metric: SelectedMetric) => void;
    onDrawerOpen: () => void;
    onConfigModalOpen: () => void;
  }) => {
    const rowData = useMemo(() => row.original, [row]);

    const hasTags = useMemo(() => {
      const parentHasRequestedTags = !!rowData.parentMetric?.materialMetrics.find(
        (mm) => mm.materialStandardId === parentStandardId
      )?.materialMetricTags.length;

      return row.original.adminPanelTags?.length || parentHasRequestedTags;
    }, [rowData, row]);

    const isDisabled = useMemo(() => {
      const isChild = rowData.isChild;
      const parentMetric = rowData.parentMetric;

      const parentHasAdminPanelTags = !!parentMetric?.adminPanelTags.length;
      const parentHasRequestedTags = !!parentMetric?.materialMetrics.find(
        (mm) => mm.materialStandardId === parentStandardId
      )?.materialMetricTags.length;
      const parentHasAddedTags = !!parentMetric?.materialMetrics.find(
        (mm) => mm.materialStandardId === companyStandardId
      )?.materialMetricTags.length;

      const parentHasTags = parentHasAdminPanelTags || parentHasRequestedTags || parentHasAddedTags;

      return (isChild && parentHasTags) || (isSubsidiaryAndNonMetricDR && !hasTags);
    }, [rowData, companyStandardId, parentStandardId, isSubsidiaryAndNonMetricDR]);

    const tooltipLabel = useMemo(() => {
      if (isSubsidiaryAndNonMetricDR && !hasTags) {
        return 'Configured on a parent company level';
      }
      if (isDisabled) {
        return 'Settings are available on the parent metric';
      }
      return '';
    }, [isDisabled, isSubsidiaryAndNonMetricDR]);

    return (
      <HStack spacing="2px" justifyContent="end">
        {rowData.type === DataCollectionRowEnum.metric && (
          <IconButton
            tooltipLabel={tooltipLabel}
            isDisabled={isDisabled}
            variant="ghost"
            size="md"
            aria-label="settings"
            icon={<SettingsIcon color={isDisabled ? 'text.disabled' : ''} />}
            onClick={() => {
              setSelectedMetricData(rowData);
              onConfigModalOpen();
            }}
          />
        )}

        <IconButton
          aria-label="learn more"
          variant="ghost"
          icon={<HelpIcon />}
          size="md"
          onClick={() => {
            setSelectedMetric({
              reference: rowData.reference,
              description:
                rowData.type === DataCollectionRowEnum.tag
                  ? 'This metric was generated by a breakdown of a parent metric'
                  : rowData.description ?? '',
              tags: rowData.adminPanelTags.map((tag) => ({ type: tag.type })),
            });
            onDrawerOpen();
          }}
        />
      </HStack>
    );
  }
);

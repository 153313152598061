import { Button, ButtonProps } from 'Atoms';
import { PaiPortfolioReport } from 'models';
import { DownloadIcon } from 'Tokens/Icons/Function';
import { PaiResults, mapUnitToCompanyCurrencyPAI } from './PortfolioPai.hooks';
import ExcelJS from 'exceljs';
import { calculateMetricResults, getMetricProgress } from 'utils/scores/pai';
import { useMemo } from 'react';
import { saveAs } from 'file-saver';
import { useParams } from 'react-router-dom';
import usePortfolios from 'containers/Portfolios/Portfolios.hooks';
import { useCurrentCompany } from 'utils/hooks';

export const PaiExport = ({
  selectedIndicators,
  results,
  reportingYear,
  onClick,
}: {
  selectedIndicators: PaiPortfolioReport['selectedIndicators'];
  results: PaiResults | undefined;
  reportingYear: string;
  onClick?: ButtonProps['onClick'];
}) => {
  const { portfolioId } = useParams();
  const {
    getPortfolio,
    // loading
  } = usePortfolios();

  const { company } = useCurrentCompany();

  const portfolio = getPortfolio(portfolioId ?? '', reportingYear);
  const investorMetricsList = useMemo(() => {
    return selectedIndicators
      .map((indicator) =>
        indicator.indicator.investorMetrics.map((investorMetric) => ({
          ...investorMetric.investorMetric,
          indicator: indicator.indicator,
          companyCount:
            (indicator.isForAllCompanies
              ? results?.length
              : indicator.paiReportIndicatorPortfolioCompanies.length) ?? 1,
        }))
      )
      .flat();
  }, [selectedIndicators]);

  const investeeMetricsList = useMemo(() => {
    return selectedIndicators
      .map((indicator) =>
        indicator.indicator.investorMetrics.map((investorMetric) => ({
          ...investorMetric.investorMetric,
          indicator: indicator.indicator,
        }))
      )
      .flat()
      .map((investorMetric) =>
        investorMetric.investeeMetrics.map((investeeMetric) => ({
          ...investeeMetric.metric,
          investorMetric: investorMetric,
        }))
      )
      .flat();
  }, [selectedIndicators]);

  const onExport = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet('Pai Results');
    sheet.columns = [
      {
        key: 'indicator',
        header: 'Indicator',
        width: 50,
      },
      {
        key: 'metric',
        header: 'Metric',
        width: 100,
      },
      {
        key: 'impact',
        header: 'Impact this year',
        width: 30,
      },
      {
        key: 'answers',
        header: 'Answers collected',
        width: 20,
      },
    ];
    const rows = investorMetricsList.map((investorMetric) => ({
      indicator: investorMetric.indicator?.title ?? 'N/A',
      metric: investorMetric.title ?? 'N/A',
      impact: `${calculateMetricResults(investorMetric, results ?? []).result ?? 'N/A'} ${
        mapUnitToCompanyCurrencyPAI(investorMetric.unit, company?.currency) ?? ''
      }`,
      answers:
        `${getMetricProgress(investorMetric, results ?? [], investorMetric.companyCount)}%` ?? '0%',
    }));

    const removeIllegalCharacters = (name: string): string => {
      return name.replace(/[\*\?\:\[\]\/\\]/g, '_');
    };

    sheet.addRows(rows);

    results?.forEach((res) => {
      const formatedCompanyName = removeIllegalCharacters(res.company.name);
      const companySheet = workbook.addWorksheet(`${formatedCompanyName}-raw-results`);
      companySheet.columns = [
        {
          key: 'indicator',
          header: 'Indicator',
          width: 100,
        },
        {
          key: 'metric',
          header: 'Metric',
          width: 100,
        },
        {
          key: 'question',
          header: 'Question',
          width: 100,
        },
        {
          key: 'answer',
          header: 'Answer',
          width: 20,
        },
        {
          key: 'marketValue',
          header: 'Enterprise value',
          width: 20,
        },
        {
          key: 'allocation',
          header: 'Allocation',
          width: 20,
        },
      ];
      const companyRows = investeeMetricsList.map((investeeMetric) => ({
        indicator: investeeMetric.investorMetric.indicator?.title ?? 'N/A',
        metric: investeeMetric.investorMetric.title ?? 'N/A',
        question: investeeMetric.title ?? 'N/A',
        answer: `${res.answers[investeeMetric?.reference]} ${investeeMetric?.unit ?? ''}` ?? 'N/A',
        marketValue: res.marketValue ?? '0',
        allocation: res.allocation ?? '0',
      }));
      companySheet.addRows(companyRows);
    });

    workbook.xlsx.writeBuffer().then(function (buffer) {
      // done
      console.log(buffer);

      const blob = new Blob([buffer], { type: 'applicationi/xlsx' });
      saveAs(blob, `${portfolio?.name}-pai-results.xlsx`);
    });
  };

  return (
    <Button
      onClick={(e) => {
        onExport();
        if (onClick) {
          onClick(e);
        }
      }}
      // isLoading={loading}
      leftIcon={<DownloadIcon color="inherit" />}
      variant="primary"
      alignSelf="start"
    >
      Export results
    </Button>
  );
};

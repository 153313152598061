import { Box, HStack, Input, Thead, useNumberInput } from '@chakra-ui/react';
import { IconButton, Tag } from 'Atoms';
import { Typography, colors } from 'Tokens';
import { ArrowDownRightIcon, ArrowUpRightIcon } from 'Tokens/Icons/Direction';
import { AddIcon, MinusIcon } from 'Tokens/Icons/Function';
import { useEffect, useMemo, useRef, useState } from 'react';
import { MilestoneFields } from '../../../Requirement';
import { ViewSwitcher } from 'Molecules/ViewSwitcher';
import { getMilestoneTableTitle } from '../TextUtils';
import { TableTH, NotAdded, TableTD } from '../AddTargetUtils';
import { WarningIcon } from 'Tokens/Icons/Status';
import { numberToPercentage, percentageToNumber } from 'containers/Esrs/utils';

enum ValueView {
  'relative' = 'Relative value',
  'absolute' = 'Absolute value',
}

export const TableTitleSection = ({
  companyName,
  milestone,
  handleChangeValue,
  isBottomUp,
  isGroup,
  isInputDisabled = false,
}: {
  companyName: string;
  milestone: MilestoneFields | undefined;
  handleChangeValue: (fields: Partial<MilestoneFields>) => void;
  isBottomUp: boolean;
  isGroup: boolean;
  isInputDisabled?: boolean;
}) => {
  const hideSwitcher = isInputDisabled && isGroup;
  const showDescription = isGroup && isInputDisabled && !isBottomUp;
  return (
    <Box width="100%">
      <HStack width="100%" justifyContent="space-between">
        <Typography variant="h3">
          {!isInputDisabled
            ? `${companyName} goal for ${milestone?.year}`
            : getMilestoneTableTitle({ companyName, isBottomUp, isGroup, year: milestone?.year })}
        </Typography>
        {!hideSwitcher && (
          <HStack>
            <Typography variant="bodyStrong">Set a goal using:</Typography>
            <ViewSwitcher
              views={[ValueView.relative, ValueView.absolute]}
              selected={milestone?.isRelative ? ValueView.relative : ValueView.absolute}
              setSelected={(view) => handleChangeValue({ isRelative: view === ValueView.relative })}
              size="sm"
            />
          </HStack>
        )}
      </HStack>
      {showDescription && (
        <Typography variant="body">
          Here you can see milestone goals aggregated from subsidiaries, and if their total match
          your goal. These values would not show up in your report — you can just use them as a
          reference point.
        </Typography>
      )}
    </Box>
  );
};

export const TableHeader = ({ isGroup, unit }: { isGroup?: boolean; unit: string }) => (
  <Thead>
    <TableTH>Department</TableTH>
    <TableTH unit={unit}>Baseline</TableTH>
    <TableTH unit="%">Relative change from the baseline</TableTH>
    <TableTH unit={unit}> Milestone goal</TableTH>
    {isGroup && <TableTH width="40px"></TableTH>}
  </Thead>
);
export const MilestoneDirectionIcon = ({ value }: { value: number }) => {
  if (value > 0) return <ArrowUpRightIcon color="text.hint" />;
  return <ArrowDownRightIcon color="text.hint" />;
};

const MilestoneNumberInput = ({
  defaultValue,
  onChange,
  isDisabled,
  calculatedValue,
  isCompanyLevel = false,
  percentage,
  hasMax,
}: {
  defaultValue: number | undefined;
  onChange: (param: number) => void;
  isDisabled?: boolean;
  calculatedValue?: number;
  isCompanyLevel?: boolean;
  percentage?: number;
  hasMax?: boolean;
}) => {
  const hStackRef = useRef<HTMLDivElement>(null);
  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } = useNumberInput({
    step: 5,
    defaultValue: defaultValue ?? 0,
    min: -100,
    max: hasMax ? 100 : undefined,
    precision: 1,
    value: isDisabled ? calculatedValue ?? undefined : undefined,
    onChange: (val) => {
      onChange(Number(val));
    },
  });

  const inc = getIncrementButtonProps();
  const dec = getDecrementButtonProps();
  const input = getInputProps();
  const { value } = getInputProps();

  useEffect(() => {
    if (isDisabled && isCompanyLevel) {
      onChange(Number(value));
    }
  }, [value]);

  const handleFocus = () => {
    if (hStackRef.current) {
      hStackRef.current.style.border = '2px solid';
      hStackRef.current.style.borderColor = colors['border.selected.accent'].default;
      hStackRef.current.style.borderRadius = '6px';
    }
  };

  const handleBlur = () => {
    if (hStackRef.current) {
      hStackRef.current.style.border = 'none';
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const target = e.target as HTMLElement;
    if (hStackRef.current && target.tagName !== 'INPUT') {
      hStackRef.current.focus();
    }
  };

  return (
    <HStack
      w="100%"
      h="100%"
      tabIndex={0}
      ref={hStackRef}
      onClick={handleClick}
      _focus={{ border: '1px solid', borderColor: 'border.selected.accent' }}
      paddingRight="16px"
    >
      <MilestoneDirectionIcon value={percentage ?? value} />
      <Input
        {...input}
        p="0px"
        border="none"
        bg="white"
        color={isDisabled ? 'text.hint' : 'text.default'}
        fontSize="14px"
        readOnly={isDisabled}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      {!isDisabled && (
        <HStack spacing="4px">
          <IconButton
            aria-label="minus"
            size="xs"
            icon={<MinusIcon />}
            {...dec}
            variant="outline"
          />
          <IconButton aria-label="add" size="xs" icon={<AddIcon />} {...inc} variant="outline" />
        </HStack>
      )}
    </HStack>
  );
};

export const MilestoneTextValue = ({
  milestoneValue,
  value,
}: {
  milestoneValue: number;
  value: number;
}) => {
  if (value === 0 || !value) return <NotAdded />;
  return (
    <HStack>
      <MilestoneDirectionIcon value={milestoneValue ?? 0} />
      <Typography variant="body" as="span">
        {value}
      </Typography>
    </HStack>
  );
};

const saveRelativeValue = (
  val: number,
  handleChangeValue: (param: Partial<MilestoneFields>) => void,
  reportingUnitId?: string,
  reportingUnits?: MilestoneFields['reportingUnits']
) => {
  if (reportingUnitId) {
    handleChangeValue({
      reportingUnits: {
        ...reportingUnits,
        [reportingUnitId]: val,
      },
    });
  } else handleChangeValue({ value: val });
};

const saveAbsoluteValue = (
  val: number,
  baseline: number,
  handleChangeValue: (param: Partial<MilestoneFields>) => void,
  setInputValue: (param: number) => void,
  reportingUnitId?: string,
  reportingUnits?: MilestoneFields['reportingUnits']
) => {
  setInputValue(val);
  if (reportingUnitId) {
    handleChangeValue({
      reportingUnits: {
        ...reportingUnits,
        [reportingUnitId]: numberToPercentage(baseline, val),
      },
    });
  } else handleChangeValue({ value: numberToPercentage(baseline, val) });
};

export const RelativeValueCells = ({
  milestoneValue,
  baseline,
  handleChangeValue,
  reportingUnitId,
  reportingUnits = {},
  hasBorder = false,
}: {
  milestoneValue: number;
  baseline: number;
  handleChangeValue: (param: Partial<MilestoneFields>) => void;
  reportingUnitId?: string;
  reportingUnits?: MilestoneFields['reportingUnits'];
  hasBorder?: boolean;
}) => {
  const goalValue = useMemo(() => {
    const percentage = milestoneValue;
    return percentageToNumber(percentage, baseline);
  }, [baseline, milestoneValue]);

  return (
    <>
      <TableTD hasBorder={hasBorder} height="48px">
        <MilestoneNumberInput
          defaultValue={milestoneValue}
          onChange={(val) => {
            saveRelativeValue(val, handleChangeValue, reportingUnitId, reportingUnits);
          }}
          hasMax
        />
      </TableTD>
      <TableTD hasBorder={hasBorder}>
        <MilestoneTextValue milestoneValue={milestoneValue} value={goalValue ?? 0} />
      </TableTD>
    </>
  );
};

export const AbsoluteValueCells = ({
  milestoneValue,
  baseline,
  handleChangeValue,
  reportingUnitId,
  reportingUnits = {},
  hasBorder = false,
}: {
  milestoneValue: number;
  baseline: number;
  handleChangeValue: (param: Partial<MilestoneFields>) => void;
  reportingUnitId?: string;
  reportingUnits?: MilestoneFields['reportingUnits'];
  hasBorder?: boolean;
}) => {
  const goalValue = useMemo(() => {
    const percentage = milestoneValue;
    return percentageToNumber(percentage, baseline);
  }, [milestoneValue, baseline]);

  const [inputValue, setInputValue] = useState(goalValue);

  return (
    <>
      <TableTD hasBorder={hasBorder}>
        <MilestoneTextValue
          milestoneValue={milestoneValue}
          value={Number(numberToPercentage(baseline, inputValue ?? 0).toFixed(2)) ?? 0}
        />
      </TableTD>
      <TableTD hasBorder={hasBorder} height="48px">
        <MilestoneNumberInput
          defaultValue={inputValue}
          onChange={(val) => {
            saveAbsoluteValue(
              val,
              baseline,
              handleChangeValue,
              setInputValue,
              reportingUnitId,
              reportingUnits
            );
          }}
          percentage={milestoneValue}
        />
      </TableTD>
    </>
  );
};

export const BaselineCell = ({
  baselineValue,
  baselineYear,
  year,
}: {
  baselineValue: string | null | undefined;
  baselineYear: number;
  year: number;
}) => {
  if (!baselineValue || baselineValue === '') return <NotAdded />;

  if (baselineYear !== year)
    return (
      <HStack w="100%" spacing="4px">
        <Typography variant="body" as="span">
          {baselineValue}
        </Typography>
        <Tag
          variant="warning"
          size="xs"
          leftIcon={<WarningIcon color="text.warning" />}
          title={String(baselineYear)}
        />
      </HStack>
    );
  return (
    <Typography variant="body" as="span">
      {baselineValue}
    </Typography>
  );
};

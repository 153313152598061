import React from 'react';
import ReactDOM from 'react-dom/client';
import App, { ErrorElement } from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from '@sentry/react';
import { ColorModeScript } from '@chakra-ui/react';
import { SentryIntegrations, ignoreErrorsList } from 'utils/sentry';
import { nhost } from 'utils/nhost';

Sentry.init({
  dsn: import.meta.env.REACT_APP_SENTRY_DSN,
  environment: import.meta.env.REACT_APP_VERCEL_ENV,
  integrations: SentryIntegrations,
  tracesSampleRate: 1.0,
  ignoreErrors: ignoreErrorsList,
  beforeSend: (event) => {
    const { isAuthenticated, isLoading: isAuthLoading } = nhost.auth.getAuthenticationStatus();
    if (!isAuthenticated || isAuthLoading) {
      return null;
    }
    return event;
  },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<ErrorElement />}>
      <ColorModeScript initialColorMode="system" />
      <App />
    </Sentry.ErrorBoundary>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { PortfolioFieldsFragment_ } from './__generated__/graphql';

enum ScoreSectionsEnum {
  revenue = 'revenue',
  capex = 'capex',
  opex = 'opex',
}

type Proportion = {
  revenue: number;
  capex: number;
  opex: number;
};

type SubstantialContributionProportion = Record<EnvironmentalObjectiveKeyEnum, Proportion>;

enum EligibilityStatus {
  notSure = 'NOT_SURE',
  notEligible = 'NOT_ELIGIBLE',
  eligible = 'ELIGIBLE',
}

const zeroScores: SharedTaxonomyScore = {
  aligned: 0,
  eligible: 0,
  inProgress: 0,
  enabling: 0,
  transitional: 0,
  total: 100,
};

export type PortfolioCompany = PortfolioFieldsFragment_['portfolioCompanies'][number] & {
  scores?: PortfolioCompanyScore['scores'];
  progress?: PortfolioCompanyScore['progress'];
  substantialContributionProportion?: SubstantialContributionProportion;
};

export type Portfolio = Omit<PortfolioFieldsFragment_, 'portfolioCompanies'> & {
  portfolioCompanies: PortfolioCompany[];
};

type TaxonomyScore = {
  total: number;
  eligible: number;
  aligned: number;
  inProgress?: number;
  enabling?: number;
  transitional?: number;
};

export type SharedTaxonomyScore = {
  total?: number | null;
  aligned?: number | null;
  inProgress?: number | null;
  eligible?: number | null;
  enabling?: number | null;
  transitional?: number | null;
};

export type PortfolioCompanyScore = {
  scores: {
    capex: SharedTaxonomyScore;
    opex: SharedTaxonomyScore;
    revenue: SharedTaxonomyScore;
  };
  progress: {
    financials: number;
    screening: number;
    isLocked: boolean;
  };
};

export enum EnvironmentalObjectiveKeyEnum {
  mitigation = 'mitigation',
  adaptation = 'adaptation',
  biodiversity = 'biodiversity',
  circular = 'circular',
  pollution = 'pollution',
  water = 'water',
}

export const scoreSections = Object.values(ScoreSectionsEnum);

const transformEstimateScoreToNumerics = (scores: {
  [key: string]: { aligned: string; eligible: string; inProgress: string };
}) => {
  return Object.keys(scores).reduce((acc, key) => {
    return {
      ...acc,
      [key]: {
        aligned: Number(scores[key].aligned),
        eligible: Number(scores[key].eligible),
        inProgress: Number(scores[key].inProgress),
        total: 100,
      },
    };
  }, {});
};

const emptySCProportions: SubstantialContributionProportion = {
  adaptation: { revenue: 0, capex: 0, opex: 0 },
  mitigation: { revenue: 0, capex: 0, opex: 0 },
  biodiversity: { revenue: 0, capex: 0, opex: 0 },
  pollution: { revenue: 0, capex: 0, opex: 0 },
  circular: { revenue: 0, capex: 0, opex: 0 },
  water: { revenue: 0, capex: 0, opex: 0 },
};
const transformPortfolioCompany = (portfolioCompany: Portfolio['portfolioCompanies'][0]) => {
  const isEstimate = !portfolioCompany?.company;
  const eligibilityStatus = portfolioCompany?.eligibilityStatus;
  const progress: PortfolioCompanyScore['progress'] =
    isEstimate || eligibilityStatus === EligibilityStatus.notEligible
      ? { screening: 100, financials: 100, isLocked: false }
      : {
          screening: portfolioCompany.sharedAssessment?.cachedResult?.progress?.screening ?? 0,
          financials: portfolioCompany.sharedAssessment?.cachedResult?.progress?.financials ?? 0,
          isLocked: portfolioCompany.sharedAssessment?.isLocked || false,
        };

  const score: PortfolioCompanyScore['scores'] = isEstimate
    ? transformEstimateScoreToNumerics(portfolioCompany?.estimateCompany?.scores) ?? {}
    : eligibilityStatus === EligibilityStatus.notEligible
      ? { capex: zeroScores, opex: zeroScores, revenue: zeroScores }
      : portfolioCompany.sharedAssessment?.cachedResult?.score ?? {};

  const substantialContributionProportion: SubstantialContributionProportion = isEstimate
    ? emptySCProportions
    : portfolioCompany.sharedAssessment?.cachedResult?.objectivesState
        ?.substantialContributionProportion ?? emptySCProportions;

  return {
    ...portfolioCompany,
    name: portfolioCompany?.company?.name,
    score,
    scores: score, // Need consistent naming between scores/score, one of them to be deprecated
    progress,
    substantialContributionProportion,
    sharedPaiCompanyReportId: portfolioCompany?.sharedPaiCompanyReportId,
  };
};

export const transformPortfolios = (portfolios: Portfolio[]): Portfolio[] => {
  return portfolios.map((portfolio) => ({
    ...portfolio,
    portfolioCompanies: portfolio.portfolioCompanies.map(transformPortfolioCompany),
  }));
};

export const getPortfolioTotal = (portfolioCompanies: PortfolioCompany[]) => {
  const total = portfolioCompanies.reduce(
    (sum, { valueOfInvestments }) =>
      sum +
      valueOfInvestments.q1 +
      valueOfInvestments.q2 +
      valueOfInvestments.q3 +
      valueOfInvestments.q4,
    0
  );
  return total;
};

const calculateAmountAverage = (valueOfInvestments: PortfolioCompany['valueOfInvestments']) => {
  const { q1 = 0, q2 = 0, q3 = 0, q4 = 0 } = valueOfInvestments;
  return (q1 + q2 + q3 + q4) / 4;
};

export const getAggregatePortfolioScores = (portfolioCompanies: PortfolioCompany[]) => {
  const total = getPortfolioTotal(portfolioCompanies);

  const isAllInProgress = portfolioCompanies.every(
    (c) => (c.progress?.financials === 0 || c.progress?.screening === 0) && !c.progress?.isLocked
  );

  if (isAllInProgress) {
    return scoreSections.reduce(
      (acc, section) => {
        acc[section] = {
          inProgress: 100,
          total: 100,
          aligned: 0,
          eligible: 0,
          enabling: 0,
          transitional: 0,
        };
        return acc;
      },
      {} as Record<ScoreSectionsEnum, TaxonomyScore>
    );
  }

  const sectionScores = scoreSections.reduce(
    (scores, section) => {
      const aligned = portfolioCompanies.reduce((sum, pCompany) => {
        const percentage = calculateAmountAverage(pCompany?.valueOfInvestments) / total;

        if (!pCompany?.company) {
          return sum + percentage * (pCompany?.estimateCompany?.scores?.[section]?.aligned ?? 0);
        }
        return sum + percentage * (pCompany?.scores?.[section]?.aligned ?? 0);
      }, 0);

      const eligible = portfolioCompanies.reduce((sum, pCompany) => {
        const percentage = calculateAmountAverage(pCompany?.valueOfInvestments) / total;

        const isFullyInProgress =
          (pCompany?.progress?.financials === 0 || pCompany?.progress?.screening === 0) &&
          !pCompany?.progress?.isLocked;
        const isDoneAndNull =
          pCompany?.progress?.financials === 100 &&
          pCompany?.progress?.screening === 100 &&
          pCompany?.scores?.[section]?.inProgress === null;

        if (!pCompany?.company) {
          return sum + percentage * pCompany?.estimateCompany?.scores?.[section]?.eligible ?? 0;
        }

        return (
          sum +
          percentage *
            (isFullyInProgress
              ? 100
              : isDoneAndNull
                ? 0
                : pCompany?.scores?.[section]?.eligible ?? 100)
        );
      }, 0);

      const inProgress = portfolioCompanies.reduce((sum, pCompany) => {
        const percentage = calculateAmountAverage(pCompany?.valueOfInvestments) / total;
        if (!pCompany?.company) {
          return sum;
        }
        const isFullyInProgress =
          (pCompany?.progress?.financials === 0 || pCompany?.progress?.screening === 0) &&
          !pCompany?.progress?.isLocked;
        const isDoneAndNull =
          pCompany?.progress?.financials === 100 &&
          pCompany?.progress?.screening === 100 &&
          pCompany?.scores?.[section]?.inProgress === null;

        return (
          sum +
          percentage *
            (isFullyInProgress
              ? 100
              : isDoneAndNull
                ? 0
                : pCompany?.scores?.[section]?.inProgress ?? 100)
        );
      }, 0);

      const enabling = portfolioCompanies.reduce((sum, pCompany) => {
        const percentage = calculateAmountAverage(pCompany?.valueOfInvestments) / total;

        return sum + percentage * (pCompany?.scores?.[section]?.enabling ?? 0);
      }, 0);

      const transitional = portfolioCompanies.reduce((sum, pCompany) => {
        const percentage = calculateAmountAverage(pCompany?.valueOfInvestments) / total;

        return sum + percentage * (pCompany?.scores?.[section]?.transitional ?? 0);
      }, 0);

      scores[section] = {
        total: 100,
        aligned,
        eligible,
        inProgress,
        enabling,
        transitional,
      };
      return scores;
    },
    {} as Record<ScoreSectionsEnum, TaxonomyScore>
  );

  return sectionScores;
};

export const aggregatePortfolioProgress = (portfolioCompanies: PortfolioCompany[]) => {
  const total = getPortfolioTotal(portfolioCompanies);

  const portfolioProgress = portfolioCompanies.reduce(
    (agg, pCompany) => {
      const percentage = calculateAmountAverage(pCompany?.valueOfInvestments) / total ?? 0;
      const currentProgress = pCompany.progress ?? {
        screening: 0,
        financials: 0,
      };
      return {
        financials: agg.financials + percentage * currentProgress.financials,
        screening: agg.screening + percentage * currentProgress.screening,
        isLocked: (agg.isLocked && pCompany.sharedAssessment?.isLocked) || false,
      };
    },
    { financials: 0, screening: 0, isLocked: true }
  );
  return portfolioProgress;
};

export const getAggregatePortfolioSubstantialContribution = (
  portfolioCompanies: PortfolioCompany[]
) => {
  const total = getPortfolioTotal(portfolioCompanies);

  const isAllInProgress = portfolioCompanies.every(
    (c) => c.progress?.financials === 0 || c.progress?.screening === 0
  );

  if (isAllInProgress) {
    return Object.values(EnvironmentalObjectiveKeyEnum).reduce(
      (acc, currentObjective) => {
        acc[currentObjective] = {
          revenue: 0,
          capex: 0,
          opex: 0,
        };
        return acc;
      },
      {} as Record<EnvironmentalObjectiveKeyEnum, { revenue: number; opex: number; capex: number }>
    );
  }

  const proportionsPerObjective = Object.values(EnvironmentalObjectiveKeyEnum).reduce(
    (acc, currentObjective) => {
      const sectionScores = scoreSections.reduce(
        (scores, section) => {
          const weighedSubstantialContributionPercentage = portfolioCompanies.reduce(
            (sum, pCompany) => {
              const percentage = calculateAmountAverage(pCompany?.valueOfInvestments) / total;

              return (
                sum +
                percentage *
                  (pCompany?.substantialContributionProportion?.[currentObjective]?.[section] ?? 0)
              );
            },
            0
          );

          scores[section] = weighedSubstantialContributionPercentage;
          return scores;
        },
        {} as Record<ScoreSectionsEnum, number>
      );

      acc[currentObjective] = sectionScores;
      return acc;
    },
    {} as Record<EnvironmentalObjectiveKeyEnum, { revenue: number; opex: number; capex: number }>
  );

  return proportionsPerObjective;
};

import {
  useGetCompanyLevelStandardProgressQuery,
  useGetReporingUnitStandardProgressQuery,
  useGetGroupLevelStandardProgressQuery,
  GetCompanyLevelStandardProgressQuery_,
  GetGroupLevelStandardProgressQuery_,
} from 'models';
import { useMemo } from 'react';
import { useCompanyType } from 'utils/hooks';

export const cleanUpProgressResult = (percentage: number) => {
  if (Number.isNaN(percentage)) return '0';
  if (percentage > 100) return '100';
  if (Number.isInteger(percentage)) {
    return percentage.toString(); // No decimals needed, convert directly to string
  } else {
    return percentage.toFixed(0);
  }
};

export const getRegularCompanyProgress = (aggregates?: GetCompanyLevelStandardProgressQuery_) => {
  const metricsTotal =
    (aggregates?.companyLevelMaterialMetrics.aggregate?.count ?? 0) +
    (aggregates?.reportingUnitMaterialMetrics.aggregate?.count ?? 0) *
      (aggregates?.reportingUnitsCount.aggregate?.count ?? 0);

  const answersTotal =
    (aggregates?.companyLevelMaterialityAnswers.aggregate?.count ?? 0) +
    (aggregates?.reportingUnitMaterialityAnswers?.aggregate?.count ?? 0);

  const percentage = (answersTotal / metricsTotal) * 100;
  return cleanUpProgressResult(percentage);
};

export const getGroupCompanyProgress = (aggregates?: GetGroupLevelStandardProgressQuery_) => {
  const metricsTotal =
    (aggregates?.groupLevelMaterialMetrics.aggregate?.count ?? 0) +
    (aggregates?.subsidiariesLevelMaterialMetrics?.aggregate?.count ?? 0) +
    (aggregates?.reportingUnitMaterialMetrics.aggregate?.count ?? 0) *
      (aggregates?.reportingUnitsCount.aggregate?.count ?? 0);

  const answersTotal =
    (aggregates?.subsidiariesLevelMaterialityAnswers?.aggregate?.count ?? 0) +
    (aggregates?.groupLevelMaterialityAnswers.aggregate?.count ?? 0) +
    (aggregates?.reportingUnitMaterialityAnswers?.aggregate?.count ?? 0);

  const percentage = (answersTotal / metricsTotal) * 100;
  return cleanUpProgressResult(percentage);
};

export const useRegularCompanyStandardProgress = ({
  standardRef,
  assessmentId,
}: {
  standardRef: string;
  assessmentId: string;
}) => {
  const { data: standardProgressData, loading } = useGetCompanyLevelStandardProgressQuery({
    variables: {
      standardRef: standardRef,
      assessmentId: assessmentId,
    },
  });

  const progress = useMemo(() => {
    return getRegularCompanyProgress(standardProgressData);
  }, [standardProgressData, standardRef]);

  return {
    progress: progress,
    loading: loading,
  };
};

export const useGroupStandardProgress = ({
  standardRef,
  assessmentId,
}: {
  standardRef: string;
  assessmentId: string;
}) => {
  const { data: standardProgressData, loading } = useGetGroupLevelStandardProgressQuery({
    variables: {
      standardRef: standardRef,
      assessmentId: assessmentId,
    },
  });

  const progress = useMemo(() => {
    return getGroupCompanyProgress(standardProgressData);
  }, [standardProgressData, standardRef]);
  return {
    progress: progress,
    loading: loading,
  };
};

export const useStandardProgress = ({
  standardRef,
  assessmentId,
}: {
  standardRef: string;
  assessmentId: string;
}) => {
  const { isGroup } = useCompanyType();
  const { data: companyProgress, loading: isLoadingCompany } =
    useGetCompanyLevelStandardProgressQuery({
      variables: {
        standardRef: standardRef,
        assessmentId: assessmentId,
      },
      skip: isGroup,
    });
  const { data: groupProgress, loading: isLoadingGroup } = useGetGroupLevelStandardProgressQuery({
    variables: {
      standardRef: standardRef,
      assessmentId: assessmentId,
    },
    skip: !isGroup,
  });

  const progress = useMemo(() => {
    if (!isGroup) {
      return getRegularCompanyProgress(companyProgress);
    } else {
      return getGroupCompanyProgress(groupProgress);
    }
  }, [groupProgress, companyProgress, isGroup, standardRef]);

  return {
    progress: progress,
    loading: isGroup ? isLoadingGroup : isLoadingCompany,
  };
};

export const useReportingUnitStandardProgress = ({
  standardRef,
  assessmentId,
  reportingUnitId,
}: {
  standardRef: string;
  assessmentId: string;
  reportingUnitId: string;
}) => {
  const { data: standardProgressData, loading } = useGetReporingUnitStandardProgressQuery({
    variables: {
      reportingUnitId: reportingUnitId,
      standardRef: standardRef,
      assessmentId: assessmentId,
    },
  });

  const progress = useMemo(() => {
    const metricsTotal = standardProgressData?.reportingUnitMaterialMetrics?.aggregate?.count ?? 0;

    const answersTotal =
      standardProgressData?.reportingUnitMaterialityAnswers?.aggregate?.count ?? 0;

    const percentage = (answersTotal / metricsTotal) * 100;
    return cleanUpProgressResult(percentage);
  }, [standardProgressData, standardRef]);

  return {
    progress: progress,
    loading: loading,
  };
};

import { ChakraTheme, Text as ChakraText, TextProps } from '@chakra-ui/react';
import React from 'react';

export const fonts: { [key: string]: string } = {
  celsiaFont: 'Inter',
};

export const fontSizes: { [key: string]: string } = {
  xs: '10px',
  sm: '12px',
  md: '14px',
  lg: '16px',
  d1: '44px',
  d3: '28px',
  h1: '22px',
  h2: '18px',
  h3: '16px',
  h4: '14px',
};

export const fontWeights: { [key: string]: number } = {
  normal: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
};

export const lineHeights: { [key: string]: string } = {
  xxs: '13.62px',
  xs: '16px',
  s: '18px',
  sm: '20px',
  md: '24px',
  lg: '28px',
  xl: '34px',
  '2xl': '56px',
};

export const TypographyTheme: ChakraTheme['components']['Text'] = {
  baseStyle: {
    fontFamily: 'celsiaFont',
    fontWeight: 'normal',
    color: 'text.default',
    lineHeight: 'sm',
  },
  variants: {
    d1: {
      fontSize: 'd1',
      lineHeight: '2xl',
      fontWeight: 'bold',
    },
    d3: {
      fontSize: 'd3',
      lineHeight: 'xl',
      fontWeight: 'semibold',
    },
    h1: {
      fontSize: 'h1',
      lineHeight: 'xl',
      fontWeight: 'semibold',
    },
    h2: {
      fontSize: 'h2',
      lineHeight: 'lg',
      fontWeight: 'semibold',
    },
    h3: {
      fontSize: 'h3',
      lineHeight: 'md',
      fontWeight: 'semibold',
    },
    h4: {
      fontSize: 'h4',
      lineHeight: 'sm',
      fontWeight: 'semibold',
    },
    bodyLarge: {
      fontSize: 'lg',
      lineHeight: 'md',
    },
    body: {
      fontSize: 'md',
      fontWeight: 'normal',
    },
    bodyStrong: {
      fontSize: 'md',
      fontWeight: 'medium',
    },
    detail: {
      fontSize: 'sm',
      lineHeight: 'xs',
      fontWeight: 'normal',
    },
    detailStrong: {
      fontSize: 'sm',
      lineHeight: 'xs',
      fontWeight: 'semibold',
    },
    micro: {
      fontSize: 'xs',
      lineHeight: 'xxs',
      fontWeight: 'normal',
    },
    microStrong: {
      fontSize: 'xs',
      lineHeight: 'xxs',
      fontWeight: 'semibold',
    },
    overline: {
      fontSize: 'sm',
      lineHeight: 's',
      fontWeight: 'semibold',
    },
  },
};

export type TypographyProps = Omit<TextProps, 'variant'> & {
  variant?: TypographyVariant;
};

export const TYPOGRAPHY_VARIANTS = [
  'd1',
  'd3',
  'h1',
  'h2',
  'h3',
  'h4',
  'bodyLarge',
  'body',
  'bodyStrong',
  'detail',
  'detailStrong',
  'micro',
  'microStrong',
  'overline',
] as const;

export type TypographyVariant = (typeof TYPOGRAPHY_VARIANTS)[number];

export const Typography = React.forwardRef(
  (props: TypographyProps, ref: React.LegacyRef<HTMLParagraphElement>) => {
    return <ChakraText ref={ref} {...props} />;
  }
);

import {
  Input as ChakraInput,
  ChakraTheme,
  InputProps as ChakraInputProps,
  InputLeftElement,
  InputRightElement,
  InputGroup,
  InputElementProps,
  NumberInput as ChakraNumberInput,
  NumberInputProps as ChakraNumberInputProps,
  NumberInputField,
  Box,
} from '@chakra-ui/react';
import { captureException } from '@sentry/browser';
import { IconButton } from 'Atoms/Buttons';
import { RemoveIcon } from 'Tokens/Icons/Function';
import React, { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';

export const INPUT_STATES = ['default', 'isInvalid', 'isDisabled'];
export type InputState = (typeof INPUT_STATES)[number];

const size = {
  md: {
    fontSize: 'md',
    py: '8px',
    px: '8px',
    h: '36px',
    borderRadius: '8px',
  },
  sm: {
    fontSize: 'sm',
    px: '8px',
    py: '4px',
    h: '28px',
    borderRadius: '6px',
  },
};

export const InputTheme: ChakraTheme['components']['Input'] = {
  baseStyle: {
    field: {
      fontFamily: 'Inter',
      width: '200px',
      _placeholder: {
        color: 'text.hint',
      },
      boxShadow: 'none',
    },
  },
  sizes: {
    md: {
      field: size.md,
      element: {
        h: size.md.h,
        fontSize: '24px',
      },
    },
    sm: {
      field: size.sm,
      element: {
        h: size.sm.h,
        fontSize: '16px',
      },
    },
  },
  variants: {
    outline: {
      field: {
        pl: '8px',
        pr: '8px',
        bg: 'bg.default',
        borderWidth: '1px',
        borderColor: 'border.decorative',
        _hover: {
          borderColor: 'border.hover',
        },
        _focusVisible: {
          borderColor: 'border.selected.accent',
          boxShadow: 'none',
        },
        _disabled: {
          cursor: 'not-allowed',
          bg: 'bg.disabled',
          border: 'none',
          boxShadow: 'none',
          opacity: 1,
        },
        _invalid: {
          borderColor: 'border.critical.accent',
          boxShadow: 'none',
        },
      },
      element: {
        color: 'text.muted',
      },
    },
    ghost: {
      field: {
        pl: '4px',
        pr: '4px',
        bg: 'bg.default',
        border: 'none',
        boxShadow: 'none',
        _hover: {
          bg: 'bg.hover',
        },
        _focusVisible: {
          bg: 'bg.pressed',
        },
        _disabled: {
          cursor: 'not-allowed',
          bg: 'bg.disabled',
          border: 'none',
          boxShadow: 'none',
          opacity: 1,
        },
        _invalid: {
          borderColor: 'border.critical.accent',
          boxShadow: 'none',
        },
      },
      element: {
        color: 'text.muted',
      },
    },
  },
};

export const INPUT_SIZES = Object.keys(InputTheme.sizes ?? {});
export type InputSize = (typeof INPUT_SIZES)[number];

export const INPUT_VARIANTS = Object.keys(InputTheme.variants ?? {});
export type InputVariant = (typeof INPUT_VARIANTS)[number];

export type InputProps = Omit<ChakraInputProps, 'variant' | 'size'> & {
  variant?: InputVariant;
  size?: InputSize;
  leftIcon?: InputElementProps['children'];
  rightIcon?: InputElementProps['children'];
  clearable?: boolean;
};

export const Input = React.forwardRef(
  (
    { leftIcon, rightIcon, clearable, ...props }: InputProps,
    ref: React.LegacyRef<HTMLInputElement>
  ) => {
    const showClearButton = useMemo(
      () => typeof props.value === 'string' && props.value.length > 0 && clearable,
      [props.value, clearable]
    );
    const handleClearInput = () => {
      if (props?.onChange) {
        props.onChange({ target: { value: '' } } as ChangeEvent<HTMLInputElement>);
      } else {
        captureException(new Error('Input component is missing onChange prop'));
      }
    };

    if (rightIcon || leftIcon) {
      return (
        <InputGroup>
          <Box position="relative" w={props.width || props.w}>
            {leftIcon && (
              <InputLeftElement
                height="100%"
                pointerEvents="none"
                children={leftIcon}
                color={props.isDisabled ? 'text.disabled' : 'text.muted'}
              />
            )}
            <ChakraInput
              ref={ref}
              {...(leftIcon && {
                paddingLeft: props.size === 'md' ? '40px' : '28px',
              })}
              {...((rightIcon || clearable) && {
                paddingRight: props.size === 'md' ? '40px' : '28px',
              })}
              {...props}
            />
            {rightIcon && (
              <InputRightElement
                height="100%"
                pointerEvents="none"
                children={rightIcon}
                color={props.isDisabled ? 'text.disabled' : 'text.muted'}
              />
            )}
            {showClearButton && (
              <IconButton
                size="sm"
                variant="ghost"
                aria-label="clear input"
                position="absolute"
                right="5px"
                top="50%"
                transform="translateY(-50%)"
                zIndex={100}
                onClick={handleClearInput}
                icon={<RemoveIcon />}
              />
            )}
          </Box>
        </InputGroup>
      );
    }
    return (
      <InputGroup>
        <Box position="relative" w={props.width || props.w}>
          <ChakraInput paddingRight={clearable ? '40px' : '0px'} ref={ref} {...props} />
          {showClearButton && (
            <IconButton
              size="sm"
              variant="ghost"
              aria-label="clear input"
              position="absolute"
              right="5px"
              top="50%"
              transform="translateY(-50%)"
              zIndex={100}
              onClick={handleClearInput}
              icon={<RemoveIcon />}
            />
          )}
        </Box>
      </InputGroup>
    );
  }
);

export const NumberInputTheme: ChakraTheme['components']['NumberInput'] = {
  ...InputTheme,
  sizes: {
    lg: {
      field: {
        ...size.md,
        minW: '100%',
      },
    },
    md: {
      field: {
        ...size.md,
        maxWidth: '156px',
      },
    },
    sm: {
      field: {
        ...size.sm,
        maxWidth: '156px',
      },
    },
  },
};

export const NUMBER_INPUT_SIZES = ['sm', 'md'];
export type NumberInputSize = 'lg' | 'md' | 'sm';

export type NumberInputProps = Omit<ChakraNumberInputProps, 'size'> & {
  unit?: string;
  size?: NumberInputSize;
  isLocked?: boolean;
};

export const NumberInput = React.forwardRef(
  (
    {
      unit,
      inputMode = 'numeric',
      replaceNanWithZero = false,
      isBorderless = false,
      width = '',
      textAlign = 'right',
      justifyContent,
      removeBorder = false,
      ...props
    }: Omit<NumberInputProps, 'onChange'> & {
      onChange?: (value: number) => void;
      replaceNanWithZero?: boolean;
      isBorderless?: boolean;
      removeBorder?: boolean;
      width?: NumberInputProps['width'];
      textAlign?: NumberInputProps['textAlign'];
    },
    ref: React.LegacyRef<HTMLInputElement>
  ) => {
    const leftRef = useRef(null);
    const [leftElementWidth, setLeftElementWidth] = useState<string>();
    const [leftElementHeight, setLeftElementHeight] = useState<string>();

    useEffect(() => {
      const leftElement = leftRef.current;
      setLeftElementWidth(leftElement ? window.getComputedStyle(leftElement).width : '');
      setLeftElementHeight(leftElement ? window.getComputedStyle(leftElement).height : '');
    }, [unit]);

    const parseNanValue = (value: NumberInputProps['value']) => {
      return Number.isNaN(value) ? 0 : value;
    };

    return (
      <InputGroup size={props.size} zIndex="0" justifyContent={justifyContent}>
        <InputLeftElement
          ref={leftRef}
          zIndex="1"
          pointerEvents="none"
          color={props.isDisabled ? 'text.disabled' : 'text.hint'}
          height="fit-content"
          minHeight={props.size === 'sm' ? '28px' : '36px'}
          width="auto"
          maxWidth="150px"
          paddingLeft="8px"
          paddingRight="2px"
          fontSize="14px"
        >
          {unit}
        </InputLeftElement>
        <ChakraNumberInput
          {...props}
          value={replaceNanWithZero ? parseNanValue(props.value) : props.value}
          onChange={(_: string, valueAsNumber: number) => props.onChange?.(valueAsNumber)}
        >
          <NumberInputField
            paddingLeft={
              leftElementWidth !== null && leftElementWidth !== '' ? leftElementWidth : '1px'
            }
            height={
              leftElementHeight !== null && leftElementHeight !== '' ? leftElementHeight : '1px'
            }
            ref={ref}
            inputMode={inputMode}
            textAlign={textAlign}
            placeholder="0"
            border={isBorderless ? 'none' : ''}
            width={width}
            fontSize={isBorderless ? '14px' : 'sm'}
            fontWeight={400}
            _focus={
              isBorderless && !removeBorder
                ? {
                    border: '1px solid',
                    borderColor: 'border.selected.accent',
                    borderRadius: '4px',
                  }
                : {}
            }
            _hover={
              isBorderless
                ? {
                    cursor: 'pointer',
                  }
                : {}
            }
          />
        </ChakraNumberInput>
      </InputGroup>
    );
  }
);

export const ESRSNumberInput = React.forwardRef(
  ({
    value,
    onChange,
    unit,
    replaceNullWithZero = false,
    width = '200px',
    height,
    paddingLeft = '8px',
    textAlign = 'left',
    isClearable = false,
    variant = 'outline',
    isBorderless,
    colorProps,
    ...props
  }: Omit<NumberInputProps, 'onChange'> & {
    value: number | undefined;
    onChange: (value: number | null) => void;
    replaceNullWithZero?: boolean;
    width?: NumberInputProps['width'];
    height?: NumberInputProps['height'];
    paddingLeft?: NumberInputProps['paddingLeft'];
    textAlign?: NumberInputProps['textAlign'];
    isClearable?: boolean;
    variant?: ChakraNumberInputProps['variant'];
    isBorderless?: boolean;
    colorProps?: { [key: string]: any };
  }) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const leftRef = useRef(null);
    const [leftElementWidth, setLeftElementWidth] = useState<string>();
    const [leftElementHeight, setLeftElementHeight] = useState<string>();
    const [showClearButton, setClearButton] = useState(false);
    const [isFocused, setIsFocused] = useState(false);

    useEffect(() => {
      const leftElement = leftRef.current;
      setLeftElementWidth(leftElement ? window.getComputedStyle(leftElement).width : '');
      setLeftElementHeight(leftElement ? window.getComputedStyle(leftElement).height : '');
    }, [unit]);

    const parseValue = (val: number | string) => {
      const parsed = parseFloat(val as string);
      if (isNaN(parsed)) return replaceNullWithZero ? 0 : null;
      return parsed;
    };

    return (
      <InputGroup
        onFocus={() => {
          setIsFocused(true);
          setClearButton(true);
        }}
        onBlur={() => {
          setIsFocused(false);
          setClearButton(false);
        }}
        onMouseEnter={() => setClearButton(true)}
        onMouseLeave={() => {
          if (!isFocused) setClearButton(false);
        }}
        width={width}
        maxHeight={height}
        minHeight={height}
        {...colorProps}
      >
        <InputLeftElement
          ref={leftRef}
          zIndex="1"
          pointerEvents="none"
          color={props.isDisabled ? 'text.disabled' : 'text.hint'}
          height="fit-content"
          minHeight={props.size === 'sm' ? '28px' : '36px'}
          width="auto"
          maxWidth="150px"
          paddingLeft="8px"
          paddingRight="2px"
          fontSize="14px"
        >
          {unit}
        </InputLeftElement>
        <ChakraNumberInput
          {...props}
          variant={variant}
          clampValueOnBlur
          defaultValue={value}
          width={width}
          onChange={(valueAsString: string) => {
            const parsedValue = parseValue(valueAsString);
            onChange(parsedValue);
          }}
          {...colorProps}
        >
          <NumberInputField
            paddingLeft={
              unit && leftElementWidth !== null && leftElementWidth !== ''
                ? leftElementWidth
                : paddingLeft
            }
            height={
              leftElementHeight !== null && leftElementHeight !== '' ? leftElementHeight : '1px'
            }
            maxHeight={height}
            minHeight={height}
            ref={inputRef}
            textAlign={textAlign}
            placeholder="0"
            minW={width}
            paddingRight={isClearable ? '40px' : undefined}
            border={isBorderless ? 'none' : ''}
            {...colorProps}
          />
        </ChakraNumberInput>
        {isClearable && showClearButton && value !== undefined && (
          <InputRightElement width="auto">
            <IconButton
              size={props.size == 'sm' ? 'xs' : 'sm'}
              variant="ghost"
              icon={<RemoveIcon />}
              aria-label="remove"
              onClick={() => {
                if (inputRef.current) {
                  const event = new Event('change', { bubbles: true });
                  (inputRef.current.value as any) = null;
                  inputRef.current.dispatchEvent(event);
                }
                onChange(null);
              }}
            />
          </InputRightElement>
        )}
      </InputGroup>
    );
  }
);

import { Box, useDisclosure, VStack } from '@chakra-ui/react';
import { ActionFieldsFragment_, GetActionsQuery_, GetReportingUnitsQuery_ } from 'models';
import { CreatableSelect } from 'Molecules';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Typography } from 'Tokens';
import { ActionFilters } from '../../Actions/ActionFilters';
import { ActionsList } from '../../Actions/ActionsList';
import { useMaterialStandardId } from 'containers/Esrs/EsrsAssessment.hooks';
import { AddActionDrawer } from '../../Actions';

type Actions = { actionId: string; estimate: null | number }[];

export const TargetActionsSection = ({
  actions,
  parentActions,
  value,
  onChange,
  watchedActions,
  actionRef,
  reportingUnits,
  targetId,
  isGroup,
  allSubsidiariesIds,
  unit,
  targetName,
}: {
  actions: GetActionsQuery_['actions'];
  parentActions: GetActionsQuery_['actions'];
  value: Actions;
  onChange: (param: Actions) => void;
  watchedActions: Actions;
  actionRef: string;
  reportingUnits: GetReportingUnitsQuery_['reportingUnits'];
  targetId?: string;
  isGroup: boolean;
  allSubsidiariesIds: string[];
  unit: string;
  targetName?: string;
}) => {
  const [actionName, setActionName] = useState('');
  const { esrsAssessmentId = '', standardRef = '' } = useParams();
  const { companyAssessmentId } = useMaterialStandardId(standardRef, esrsAssessmentId);
  const [newAction, setNewAction] = useState<{
    value: string;
    label: string;
    estimate: number | null;
  }>();
  const [selectedAction, setSelectedAction] = useState<ActionFieldsFragment_ | undefined>();
  const [filteredActions, setFilteredActions] = useState<GetActionsQuery_['actions']>([]);
  const [filteredParentActions, setFilteredParentActions] = useState<GetActionsQuery_['actions']>(
    []
  );
  const { isOpen, onClose, onOpen } = useDisclosure();
  const selectRef = useRef<any>(null);

  const actionsOptions = useMemo(
    () => actions.map((action) => ({ value: action.id, label: action.title })),
    [actions]
  );

  const relatedParentActions = useMemo(
    () =>
      parentActions?.filter((action) =>
        action.actionSubsidiaries?.some(
          (subsidiary) => subsidiary.subsidiaryAssessment.id === esrsAssessmentId
        )
      ) ?? [],
    [parentActions, esrsAssessmentId]
  );

  const allRUIds = useMemo(
    () => (reportingUnits.map((ru) => ru.id).filter((ru) => ru !== undefined) as string[]) ?? [],
    [reportingUnits]
  );

  const hideActionsList = useMemo(
    () =>
      !watchedActions?.length ||
      (watchedActions?.length === 1 && watchedActions?.[0] === undefined),
    [watchedActions]
  );

  const watchedActionsData = useMemo(
    () =>
      actions.filter((a) =>
        watchedActions
          ?.filter((a1) => a1?.actionId !== undefined)
          .some((a2) => a2.actionId === a.id)
      ),
    [actions, watchedActions]
  );

  useEffect(() => {
    const existingId = watchedActions?.some((a) => a.actionId === newAction?.value ?? '');

    if (
      !!existingId &&
      watchedActions?.find((a) => a.actionId === newAction?.value ?? '')?.estimate !==
        newAction?.estimate
    ) {
      selectRef?.current?.setValue([
        ...selectRef?.current
          ?.getValue()
          .filter((a: { value: string; label: string }) => a.value !== newAction?.value),
        { value: newAction?.value, label: newAction?.label },
      ]);
    }
    if (!existingId && !!newAction?.value) {
      //function add option?
      selectRef?.current?.setValue([
        ...selectRef?.current?.getValue(),
        { value: newAction?.value, label: newAction?.label },
      ]);
      setSelectedAction(undefined);
    }
  }, [newAction]);

  const targetEstimate = useMemo(
    () => watchedActions?.find((a) => a.actionId === selectedAction?.id)?.estimate,
    [watchedActions, selectedAction]
  );

  return (
    <VStack alignItems="start" w="100%" spacing={'16px'}>
      <VStack alignItems="start" w="100%" spacing="16px">
        <Typography variant="h4" pb="-8px">
          Company actions
        </Typography>
        <Box w="450px">
          <CreatableSelect
            value={actionsOptions.filter((action) =>
              watchedActions?.some((a) => a.actionId === action.value)
            )}
            onChange={(action) => {
              const lastIndex = action[action.length - 1];
              const changedActions = action?.map((v) => ({
                actionId: v?.value as string,
                estimate:
                  newAction?.label === v.label
                    ? newAction?.estimate ?? null
                    : watchedActions?.find((a) => a.actionId === v.value)?.estimate ?? null,
              }));
              if (action.length !== 0 && !!lastIndex) {
                const isNew = lastIndex.value === undefined;
                if (isNew) {
                  setSelectedAction(undefined);
                  setActionName(lastIndex.label);
                  onOpen();
                } else {
                  onChange(changedActions);
                }
              } else onChange(changedActions);
            }}
            options={actionsOptions}
            ref={selectRef}
            createOptionPosition="first"
            getOptionValue={(action) => action.value ?? ''}
            getOptionLabel={(action) => action.label}
            getNewOptionData={(_input, label) => ({
              value: undefined,
              label: label as string,
            })}
            formatCreateLabel={(input) => `Create new action: ${input}`}
            isMulti
            isClearable={true}
            placeholder="Select existing or create a new action"
          />
        </Box>
        <VStack w="100%" alignItems="start" spacing="12px">
          {(!hideActionsList || !!relatedParentActions?.length) && (
            <ActionFilters
              actions={watchedActionsData}
              setFilteredActions={setFilteredActions}
              parentActions={relatedParentActions}
              setFilteredParentActions={setFilteredParentActions}
              isGroup={isGroup}
            />
          )}
          {!hideActionsList && (
            <ActionsList
              localActions={value}
              actions={filteredActions}
              isGroup={isGroup}
              onOpen={onOpen}
              setSelectedAction={setSelectedAction}
              selectRef={selectRef}
              allSubsidiariesIds={allSubsidiariesIds}
              allRUIds={allRUIds}
              unit={unit}
            />
          )}
        </VStack>
      </VStack>
      {!!relatedParentActions?.length && (
        <VStack alignItems="start" w="100%">
          <Typography variant="h4">Parent actions</Typography>
          <ActionsList
            localActions={value}
            actions={filteredParentActions}
            isGroup={true}
            onOpen={onOpen}
            setSelectedAction={setSelectedAction}
            selectRef={selectRef}
            isReadOnly={true}
            allSubsidiariesIds={allSubsidiariesIds}
            allRUIds={allRUIds}
            unit={unit}
          />
        </VStack>
      )}
      <AddActionDrawer
        onClose={onClose}
        isOpen={isOpen}
        actionName={actionName}
        actionToEdit={selectedAction}
        disclosureRequirementRef={actionRef}
        setNewAction={setNewAction}
        reportingUnits={reportingUnits}
        targetId={targetId}
        isGroup={isGroup}
        materialityAssessmentId={companyAssessmentId}
        isReadOnly={parentActions?.some((a) => a.id === selectedAction?.id)}
        targetName={targetName}
        targetEstimate={targetEstimate}
        unit={unit}
      />
    </VStack>
  );
};

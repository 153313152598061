import { Tree, MultiBackend, getBackendOptions, getDescendants } from '@minoru/react-dnd-treeview';
import { DndProvider } from 'react-dnd';
import {
  ReportingGroup,
  BAssessment,
  CompanyDetails,
  CompanyAssessment,
  useUpdateBAssessmentsCompanyStructureMutation,
  CompanyAssessmentDocument_,
  useUpsertReportingGroupsMutation,
  ReportingGroupsDocument_,
  useUpdateHasGeneralAssessmentOnBusinessUnitMutation,
  GetFinancialsDocument_,
  useFlagCompanyAssessmentAsDirtyMutation,
  useDeleteReportingGroupMutation,
  useToggleBusinessUnitInCAssessmentMutation,
  GetBusinessUnitReportingGroupDocument_,
  BusinessUnitAssessmentFieldsFragment_,
  ReportingGroupFieldsFragment_,
  BusinessUnitAssessmentFieldsFragmentDoc_,
  BusinessUnitsDocument_,
} from 'models';
import React, { useCallback, useMemo, useState } from 'react';
import { MenuAction, MenuSection, Menu } from 'Molecules/Menu';
import { AlertDialog, SearchInput } from 'Molecules';
import { Box, HStack, useDisclosure, VStack, Switch, MenuButton, Tooltip } from '@chakra-ui/react';
import { BodyText, Typography } from 'Tokens';
import { Checkbox, IconButton, VisibleIf } from 'Atoms';
import './companyStructure.css';
import { useTranslation } from 'utils/translation';
import { NodeItem, NodeType, CompanyRootNode, NodeItemBox } from './TreeNode';
import { ReportingGroupFormModal } from './CompanyStructureForms';
import { useBuildTree } from './useBuildTree';
import { BusinessUnitFormModal } from '../../containers/BusinessUnits/pieces/BusinessUnitForms';
import { COMPANY_LEVEL_ASSESSMENT_ID } from 'containers/Assessments/Assessments.hooks';
import { useAlignmentStatuses, useCompanyAssessmentResults } from 'Features/Results/Results.hooks';
import { useToast, useCurrentCompanyId } from 'utils/hooks';
import { DuplicateBusinessUnitModal } from './DuplicateBusinessUnitModal';
import {
  AddIcon,
  CopyIcon,
  DeleteIcon,
  EditIcon,
  SettingsIcon,
  SortIcon,
} from 'Tokens/Icons/Function';
import { BusinessUnitsIcon, FolderIcon } from 'Tokens/Icons/Data';
import { HelpIcon } from 'Tokens/Icons/Status';
import { captureException } from '@sentry/react';
import { BusinessUnitsSortingType } from 'containers/Assessments/pieces/Assessment.hooks';

type CompanyStructureProps = {
  reportingGroups: ReportingGroup[];
  bAssessments: BAssessment[];
  company: CompanyDetails;
  cAssessment: CompanyAssessment;
  selectedNode?: string;
  selectableNodes?: NodeType[];
  onSelect: (selected: { nodeType: NodeType; nodeId: string }) => void;
  manualOnDrop?: (
    reportingGroups: Partial<ReportingGroup>[],
    bAssessments: Partial<BAssessment>[]
  ) => void;
  withProgressBar?: boolean;
  businessUnitsSorting: BusinessUnitsSortingType;
};

type ActionType = Omit<MenuAction, 'onClick'> & {
  forNodeTypes: NodeType[];
  customRender?: (node: NodeItem) => React.ReactNode;
  onClick?: (node: NodeItem) => void;
};

export const CompanyStructure = ({
  reportingGroups,
  bAssessments,
  company,
  cAssessment,
  selectedNode,
  selectableNodes = [],
  onSelect,
  manualOnDrop,
  withProgressBar = false,
  businessUnitsSorting,
}: CompanyStructureProps) => {
  const { t } = useTranslation(['bUnits', 'assessment', 'common']);
  const { data, loading } = useCompanyAssessmentResults(cAssessment?.id ?? '', !withProgressBar);
  const { alignmentResults } = useAlignmentStatuses(cAssessment?.id ?? '', !withProgressBar);
  const toast = useToast();
  const { companyId } = useCurrentCompanyId();
  const [selectedParents, setSelectedParents] = useState<string[]>([]);

  const [query, setQuery] = useState<string>('');
  const [currentEditGroup, setCurrentEditGroup] = useState<ReportingGroup | undefined>();
  const [currentEditBU, setCurrentEditBU] = useState<BAssessment | undefined>();
  const { isOpen: isCreating, onClose: onCreationClose, onOpen: onCreateGroup } = useDisclosure();
  const { isOpen: isCreatingBU, onClose: onBuCreationClose, onOpen: onCreateBU } = useDisclosure();
  const [flagAssessmentAsDirty] = useFlagCompanyAssessmentAsDirtyMutation();
  const [unitToDuplicate, setUnitToDuplicate] = useState<string>();
  const [unitToUnassign, setUnitToUnassign] = useState<string>();
  const [groupToDelete, setGroupToDelete] = useState<string>();
  const [selectedParentGroup, setSelectedParentGroup] = useState<string | undefined>(undefined);
  const [deleteReportingGroup] = useDeleteReportingGroupMutation();
  const [toggleBUInAssessment] = useToggleBusinessUnitInCAssessmentMutation();
  const [mutateHasGeneralAssessment, { loading: isLoadingUpdateHasGeneralAssessment }] =
    useUpdateHasGeneralAssessmentOnBusinessUnitMutation({
      refetchQueries: [CompanyAssessmentDocument_],
    });
  const [upsertReportingGroup] = useUpsertReportingGroupsMutation({
    refetchQueries: [ReportingGroupsDocument_, GetFinancialsDocument_],
    awaitRefetchQueries: true,
  });
  const [updateBAssessmentsStructure] = useUpdateBAssessmentsCompanyStructureMutation({
    refetchQueries: [
      CompanyAssessmentDocument_,
      GetFinancialsDocument_,
      GetBusinessUnitReportingGroupDocument_,
    ],
    awaitRefetchQueries: true,
  });

  const { sortAlphabetically, groupsFirst, toggleSortingAlphabetically, toggleSortingGroupsFirst } =
    businessUnitsSorting;
  const companyBusinessUnitId = COMPANY_LEVEL_ASSESSMENT_ID;

  const treeData: NodeItem[] = useBuildTree(
    reportingGroups,
    bAssessments,
    companyBusinessUnitId,
    query,
    sortAlphabetically,
    groupsFirst
  );

  const onDrop = (newTree: NodeItem[]) => {
    const updatedNodes = newTree.map((node, index) => ({
      ...node?.data?.original,
      parentId: node.parent === companyBusinessUnitId ? undefined : node.parent,
      orderIndex: index,
      type: node?.data?.type,
    }));

    const updatedGroups = updatedNodes
      .filter((node) => node.type === NodeType.reportingGroup)
      .map((unTypedNode) => {
        const node = unTypedNode as ReportingGroupFieldsFragment_;
        return {
          id: node.id,
          ownerId: node.owner?.id,
          orderIndex: node.orderIndex,
          name: node.name,
          parentId: node.parentId,
          companyAssessmentId: cAssessment?.id,
        };
      });

    const updatedBAssessments: {
      id: any;
      orderIndex: any;
      reportingGroupId: string;
      cAssessmentId: any;
    }[] = updatedNodes
      .filter(
        (node) =>
          node.type === NodeType.businessUnit &&
          updatedNodes.find((n) => n.id === node.parentId)?.type === NodeType.reportingGroup
      )
      .map((unTypedNode) => {
        const node = unTypedNode as BAssessment & { parentId: string };
        return {
          id: node.id,
          orderIndex: node.orderIndex,
          reportingGroupId: node.parentId,
          cAssessmentId: cAssessment?.id,
        };
      });

    if (manualOnDrop) {
      manualOnDrop(updatedGroups, updatedBAssessments);
    } else {
      if (updatedGroups.length > 0) {
        upsertReportingGroup({ variables: { input: updatedGroups } });
      }
      if (updatedBAssessments.length > 0) {
        try {
          updateBAssessmentsStructure({ variables: { input: updatedBAssessments } });
        } catch (e) {
          captureException(e, {
            extra: { errorMessage: 'Drag and drop reporting units error' },
          });
        }
      }
    }
  };

  const updateHasGeneralAssessment = async (node: any) => {
    try {
      await mutateHasGeneralAssessment({
        variables: {
          bAssessmentId: node.data.original.id,
          hasGeneralAssessment: !node.data.original.hasGeneralAssessment,
          cAssessmentId: cAssessment?.id,
        },
      });
      await flagAssessmentAsDirty({
        variables: {
          cachedResultId: cAssessment?.cachedResultId ?? '',
        },
      });
      toast({
        text: t('assessment:generalAssessment.updated'),
      });
    } catch (e) {
      captureException(e, {
        extra: { errorMessage: t('assessment:generalAssessment.errorUpdating') },
      });
      toast({
        text: t('assessment:generalAssessment.errorUpdating'),
        variant: 'danger',
      });
    }
  };
  const nodeActions: ActionType[] = [
    {
      forNodeTypes: [NodeType.businessUnit],
      title: t('assessment:structure.department.form.edit'),
      leftElement: <EditIcon color="inherit" />,
      id: 'edit',
      onClick: (node) => {
        if (node.data?.type === NodeType.reportingGroup)
          setCurrentEditGroup(node.data?.original as ReportingGroupFieldsFragment_);
        else setCurrentEditBU(node.data?.original as BusinessUnitAssessmentFieldsFragment_);
      },
    },
    {
      id: 'duplicate',
      title: t('common:actions.duplicate'),
      leftElement: <CopyIcon color="inherit" />,
      onClick: (node) => setUnitToDuplicate((node.id ?? '') as string),
      forNodeTypes: [NodeType.businessUnit],
    },
    {
      id: 'unassign',
      title: t('common:actions.delete'),
      variant: 'destructive',
      leftElement: <DeleteIcon color="inherit" />,
      onClick: (node) => setUnitToUnassign((node.id ?? '') as string),
      forNodeTypes: [NodeType.businessUnit],
    },
    {
      forNodeTypes: [NodeType.reportingGroup],
      id: 'create-reporting-unit',
      leftElement: <BusinessUnitsIcon />,
      onClick: (node) => {
        setSelectedParentGroup(String(node?.id));
        onCreateBU();
      },
      rightElement: (
        <Tooltip placement="right-start" label={t('assessment:reportsTooltip.buTooltip')}>
          <Box>
            <HelpIcon marginTop="6px" color="inherit" />
          </Box>
        </Tooltip>
      ),
      title: t('assessment:structure.businessUnit.create'),
    },
    {
      forNodeTypes: [NodeType.reportingGroup],
      id: 'create',
      title: t('assessment:structure.department.create'),
      leftElement: <FolderIcon />,
      onClick: (node) => {
        setSelectedParentGroup(String(node?.id));
        onCreateGroup();
      },
      rightElement: (
        <Tooltip label={t('assessment:reportsTooltip.rgTooltip')} placement="right-start">
          <Box>
            <HelpIcon marginTop="4px" color="inherit" />
          </Box>
        </Tooltip>
      ),
    },
  ];

  const getGeneralSection = (node: NodeItem): MenuSection => ({
    title: t('assessment:generalAssessment.actionMenuGroupTitle'),
    actions: [
      {
        id: 'general',
        title: t('assessment:generalAssessment.actionMenuTitle'),
        render: () => (
          <HStack
            key={'generalAssessment'}
            justifyContent="space-between"
            cursor="default"
            onClick={(e) => e.stopPropagation()}
          >
            <VStack align="start">
              <Typography variant="bodyStrong">
                {t('assessment:generalAssessment.actionMenuTitle')}
              </Typography>
              <Typography variant="micro">
                {t('assessment:generalAssessment.actionMenuText')}
              </Typography>
            </VStack>
            <Switch
              isDisabled={isLoadingUpdateHasGeneralAssessment}
              isChecked={node.data?.original?.hasGeneralAssessment}
              onClick={(e) => e.stopPropagation()}
              onChange={() => updateHasGeneralAssessment(node)}
            />
          </HStack>
        ),
      },
    ],
  });
  const getNodeActions = useCallback(
    (node: NodeItem): MenuAction[] =>
      nodeActions
        .filter((a) => node.data && a.forNodeTypes.includes(node.data?.type))
        .map(({ onClick, customRender, ...rest }) => ({
          ...rest,
          onClick: onClick ? () => onClick(node) : undefined,
          render: customRender ? () => customRender(node) : undefined,
        })),
    [treeData]
  );
  const getGroupActions = (node: NodeItem): MenuSection => ({
    actions: [
      {
        id: 'edit',
        title: t('assessment:structure.department.form.edit'),
        leftElement: <EditIcon color="inherit" />,
        onClick: () => {
          if (node.data?.type === NodeType.reportingGroup)
            setCurrentEditGroup(node.data?.original as ReportingGroupFieldsFragment_);
          else setCurrentEditBU(node.data?.original as BusinessUnitAssessmentFieldsFragment_);
        },
      },
      {
        id: 'delete',
        title: t('common:actions.delete'),
        leftElement: <DeleteIcon color="inherit" />,
        variant: 'destructive',
        onClick: () => setGroupToDelete((node.id ?? '') as string),
      },
    ],
  });

  const getBusinessUnitProgress = useCallback(
    (id: string) => {
      if (!data) return undefined;
      return (
        data?.businessUnitResults.find((b) => b?.businessUnit?.id === id)?.cachedResult?.progress
          ?.screening ?? 0
      );
    },
    [data]
  );

  const getReportingGroupChildren = (id: string): ReportingGroup['businessUnits'] => {
    const directUnits = reportingGroups.find((g) => g.id === id)?.businessUnits ?? [];
    const grandChildrenUnits =
      reportingGroups
        .filter((g) => g.parentId === id && g.parentId !== g.id)
        .map((g) => getReportingGroupChildren(g.id))
        .flat() ?? [];

    return [...directUnits, ...grandChildrenUnits];
  };

  const getNodeProgress = useCallback(
    (node: NodeItem) => {
      if (!withProgressBar) {
        return undefined;
      }
      if (node?.data?.type === NodeType.businessUnit) {
        return getBusinessUnitProgress(String(node.id) ?? '');
      }

      const reportingGroupChildren = getReportingGroupChildren(String(node.id) ?? '');
      const totalProgress = reportingGroupChildren
        .map((bu) => getBusinessUnitProgress(bu.businessUnit?.id))
        .reduce((a, b) => a + b, 0);

      return reportingGroupChildren.length ? totalProgress / reportingGroupChildren.length ?? 0 : 0;
    },
    [data, reportingGroups, cAssessment]
  );

  const companyProgress = useMemo(() => {
    if (!data) return undefined;

    return data?.cachedResult?.progress?.screening ?? 0;
  }, [data]);

  return (
    <DndProvider backend={MultiBackend} options={getBackendOptions()}>
      <Box display="flex">
        <VStack
          spacing="16px"
          width="320px"
          borderRightColor="border.decorative"
          borderRightWidth="1px"
          borderRightStyle="solid"
          flexGrow="1"
        >
          <HStack justifyContent="space-between" width="100%" paddingTop="12px" paddingX="16px">
            <BodyText fontSize="14px" fontWeight="600" lineHeight="20px">
              {t('bUnits:title')}
            </BodyText>
            <VisibleIf condition={!cAssessment?.isLocked}>
              <Menu
                size="lg"
                menuButton={
                  <MenuButton
                    as={IconButton}
                    aria-label="add"
                    size="sm"
                    variant="secondary"
                    icon={<AddIcon color="inherit" />}
                  />
                }
                sections={[
                  {
                    actions: [
                      {
                        id: 'add-reporting-unit',
                        leftElement: <BusinessUnitsIcon color="inherit" />,
                        onClick: () => {
                          setSelectedParentGroup(undefined);
                          onCreateBU();
                        },
                        title: t('assessment:structure.businessUnit.create'),
                        rightElement: (
                          <Tooltip
                            placement="right-start"
                            label={t('assessment:reportsTooltip.buTooltip')}
                          >
                            <Box>
                              <HelpIcon marginTop="6px" color="inherit" />
                            </Box>
                          </Tooltip>
                        ),
                      },
                      {
                        id: 'add-reporting-group',
                        leftElement: <FolderIcon color="inherit" />,
                        onClick: () => {
                          setSelectedParentGroup(undefined);
                          onCreateGroup();
                        },
                        title: t('assessment:structure.department.create'),
                        rightElement: (
                          <Tooltip
                            label={t('assessment:reportsTooltip.rgTooltip')}
                            placement="right-start"
                          >
                            <Box>
                              <HelpIcon marginTop="6px" color="inherit" />
                            </Box>
                          </Tooltip>
                        ),
                      },
                    ],
                  },
                ]}
              />
            </VisibleIf>
          </HStack>
          <HStack paddingX="16px" width="100%">
            <SearchInput
              size="sm"
              search={query}
              setSearch={setQuery}
              width="100%"
              placeholder={t('bUnits:search.placeholder')}
            />
            <Menu
              size="md"
              menuButton={
                <MenuButton
                  as={IconButton}
                  aria-label="Sort reporting units"
                  size="sm"
                  variant="secondary"
                  icon={<SortIcon color="inherit" />}
                />
              }
              sections={[
                {
                  actions: [
                    {
                      id: 'Reporting unit sorting alphabetically',
                      title: 'Sort alphabetically',
                      onClick: (e) => {
                        setTimeout(() => {
                          toggleSortingAlphabetically();
                        }, 100);
                        e?.stopPropagation();
                      },
                      variant: 'ghost',
                      rightElement: (
                        <Checkbox
                          as="div" // rely on MenuItem's onClick to handle everything
                          isChecked={sortAlphabetically}
                        />
                      ),
                    },
                    {
                      id: 'Reporting unit sorting groups on top',
                      title: 'Groups on top',
                      onClick: (e) => {
                        setTimeout(() => {
                          toggleSortingGroupsFirst();
                        }, 100);
                        e?.stopPropagation();
                      },
                      variant: 'ghost',
                      rightElement: (
                        <Checkbox
                          as="div" // rely on MenuItem's onClick to handle everything
                          isChecked={groupsFirst}
                        />
                      ),
                    },
                  ],
                },
              ]}
            />
          </HStack>
          <VStack spacing="0px" width="100%" justifyContent="stretch" padding="0px">
            <CompanyRootNode
              company={company}
              isSelected={selectedNode === companyBusinessUnitId}
              childrenCount={getDescendants(treeData, companyBusinessUnitId).length}
              cAssessment={cAssessment}
              sections={[
                {
                  actions: [
                    {
                      id: 'create-reporting-unit',
                      leftElement: <BusinessUnitsIcon color="inherit" />,
                      onClick: () => {
                        setSelectedParentGroup(undefined);
                        onCreateBU();
                      },
                      title: t('assessment:structure.businessUnit.create'),
                      rightElement: (
                        <Tooltip
                          placement="right-start"
                          label={t('assessment:reportsTooltip.buTooltip')}
                        >
                          <Box>
                            <HelpIcon marginTop="8px" color="inherit" />
                          </Box>
                        </Tooltip>
                      ),
                    },
                    {
                      id: 'create',
                      leftElement: <FolderIcon color="inherit" />,
                      onClick: () => {
                        setSelectedParentGroup(undefined);
                        onCreateGroup();
                      },
                      title: t('assessment:structure.department.create'),
                      rightElement: (
                        <Tooltip
                          label={t('assessment:reportsTooltip.rgTooltip')}
                          placement="right-start"
                        >
                          <Box>
                            <HelpIcon marginTop="4px" color="inherit" />
                          </Box>
                        </Tooltip>
                      ),
                    },
                  ],
                },
                {
                  actions: [
                    {
                      id: 'settings',
                      leftElement: <SettingsIcon color="inherit" />,
                      onClick: () => {
                        window.open(`/${companyId}/settings/company-details`);
                      },
                      title: t('assessment:structure.department.form.settings'),
                    },
                  ],
                },
              ]}
              onClick={() =>
                onSelect({ nodeType: NodeType.company, nodeId: companyBusinessUnitId ?? 'company' })
              }
              progress={companyProgress}
              withProgress={withProgressBar}
              alignmentStatuses={!withProgressBar ? [] : alignmentResults?.company ?? []}
              isDataLoading={loading}
            />
            <Tree<NodeItem['data']>
              tree={treeData}
              classes={{
                listItem: 'listItem',
                placeholder: 'placeholderContainer',
                dropTarget: 'dropTarget',
                draggingSource: 'draggingSource',
              }}
              rootId={companyBusinessUnitId}
              onDrop={cAssessment?.isLocked ? () => {} : onDrop}
              sort={false}
              dragPreviewRender={() => (
                <Box cursor="grabbing" width="300px" height="40px" background="transparent" />
              )}
              placeholderRender={() => (
                <Box width="300px" height="8px" bg="rgba(177, 208, 255, 0.6)" />
              )}
              render={(node, state) => (
                <NodeItemBox
                  key={node.id}
                  node={node}
                  state={state}
                  company={company}
                  isLocked={cAssessment?.isLocked ?? false}
                  sections={[
                    { actions: getNodeActions(node) },
                    ...(node.data?.type === NodeType.businessUnit
                      ? [{ ...getGeneralSection(node) }]
                      : [{ ...getGroupActions(node) }]),
                  ]}
                  isSelected={selectedNode === node.id}
                  childrenCount={getDescendants(treeData, node.id).length || 0}
                  onClick={() => {
                    if (!!node.data && selectableNodes?.includes(node.data.type)) {
                      onSelect({ nodeType: node.data.type, nodeId: String(node.id) });
                    }
                  }}
                  progress={getNodeProgress(node)}
                  withProgress={withProgressBar}
                  selectedParents={selectedParents}
                  setSelectedParents={setSelectedParents}
                  alignmentStatuses={!withProgressBar ? [] : alignmentResults?.[node.id] ?? []}
                  isDataLoading={loading}
                />
              )}
            />
          </VStack>
        </VStack>
        <ReportingGroupFormModal
          isOpen={isCreating || !!currentEditGroup}
          onClose={() => {
            setCurrentEditGroup(undefined);
            onCreationClose();
          }}
          reportingGroup={currentEditGroup}
          selectedParentGroup={currentEditGroup ? undefined : selectedParentGroup}
        />
        <BusinessUnitFormModal
          isOpen={isCreatingBU || !!currentEditBU}
          currentAssessmentId={cAssessment?.id}
          businessUnitId={currentEditBU?.businessUnit?.id}
          withAssessments={false}
          onClose={() => {
            setCurrentEditBU(undefined);
            onBuCreationClose();
          }}
          selectedParentGroup={currentEditBU ? undefined : selectedParentGroup}
        />
        {unitToDuplicate && (
          <DuplicateBusinessUnitModal
            isOpen={!!unitToDuplicate}
            id={unitToDuplicate}
            onClose={() => {
              setUnitToDuplicate(undefined);
            }}
          />
        )}
        <AlertDialog
          isOpen={!!unitToUnassign}
          onClose={() => setUnitToUnassign(undefined)}
          onConfirm={async () => {
            try {
              if (unitToUnassign)
                await toggleBUInAssessment({
                  variables: {
                    cAssessmentId: cAssessment?.id,
                    businessUnitId: unitToUnassign,
                    deletedAt: 'now()',
                  },
                });
              await flagAssessmentAsDirty({
                variables: {
                  cachedResultId: cAssessment?.cachedResultId,
                },
                refetchQueries: [CompanyAssessmentDocument_, GetFinancialsDocument_],
                awaitRefetchQueries: true,
              });
              onSelect({ nodeType: NodeType.company, nodeId: companyBusinessUnitId ?? 'company' });
              toast({
                text: t('assessment:structure.businessUnit.remove'),
              });
              setUnitToUnassign(undefined);
            } catch (e) {
              captureException(e, {
                extra: { errorMessage: t('common:500.title') },
              });
              toast({
                text: t('common:500.title'),
                variant: 'danger',
              });
            }
          }}
          title={t('assessment:structure.businessUnit.validating')}
          confirmLabel={t('common:actions.confirm')}
        >
          <Typography variant="body">{t('assessment:structure.businessUnit.caution')}</Typography>
        </AlertDialog>

        <AlertDialog
          isOpen={!!groupToDelete}
          onClose={() => setGroupToDelete(undefined)}
          onConfirm={() => {
            if (groupToDelete)
              deleteReportingGroup({
                variables: {
                  id: groupToDelete,
                },
                refetchQueries: [
                  ReportingGroupsDocument_,
                  BusinessUnitAssessmentFieldsFragmentDoc_,
                  BusinessUnitsDocument_,
                  CompanyAssessmentDocument_,
                ],
              }).then(() => {
                toast({
                  text: t('assessment:structure.businessUnit.success'),
                });
                setGroupToDelete(undefined);
              });
          }}
          title={t('assessment:structure.businessUnit.delete')}
          confirmLabel={t('common:actions.confirm')}
        >
          <Typography variant="body">
            {t('assessment:structure.businessUnit.cautionGroup')}
          </Typography>
        </AlertDialog>
      </Box>
    </DndProvider>
  );
};

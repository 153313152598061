import { HStack } from '@chakra-ui/react';
import { Button, IconButton } from 'Atoms';
import { Typography } from 'Tokens';
import { ArrowLeftIcon } from 'Tokens/Icons/Direction';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { PATHS_TO_BREAK_AT, LinkType } from './Breadcrumbs.utils';
import { checkCurrentPath, isValidPath, useGetPathName } from './Breadcrumbs.hooks';

export const BREADCRUMBS_HEIGHT = '40px';

const Breadcrumb = ({
  locations,
  currentPath,
  index,
  path,
  linkType,
  isEditTarget,
}: {
  locations: string[];
  currentPath: string;
  index: number;
  path: string;
  linkType: string;
  isEditTarget: boolean;
}) => {
  const isCurrentPage = useMemo(() => index === locations.length - 1, [locations]);
  const newPath = useMemo(
    () => currentPath.slice(0, currentPath.lastIndexOf(path)) + path,
    [currentPath]
  );
  const pathTitle = useGetPathName(index, path, linkType, isEditTarget);

  return (
    <Link onClick={(e) => (isCurrentPage ? e.preventDefault() : {})} to={newPath}>
      <HStack gap="2px">
        <Button
          size="xs"
          variant="ghost"
          p="1px 2px"
          maxW="200px"
          justifyContent="flex-start"
          _hover={{ bg: !isCurrentPage ? 'bg.hover' : 'unset' }}
        >
          <Typography
            variant="body"
            overflow="hidden"
            textOverflow="ellipsis"
            color={isCurrentPage ? 'text.hint' : 'unset'}
            borderRadius="4px"
            cursor={isCurrentPage ? 'default' : 'unset'}
            _pressed={{ bg: 'bg.pressed' }}
          >
            {pathTitle}
          </Typography>
        </Button>

        <Typography>{!isCurrentPage && ' /'}</Typography>
      </HStack>
    </Link>
  );
};

export const Breadcrumbs = ({
  onBackButtonClick,
  onBackNavigate,
  noPadding,
}: {
  onBackButtonClick?: () => void;
  onBackNavigate?: () => void;
  noPadding?: boolean;
}) => {
  const locations = useLocation();
  const currentPath = useMemo(() => locations.pathname, [locations]);
  const navigate = useNavigate();
  const { materialStandardId = '', reportingUnitId = '' } = useParams();
  const [paths, setPaths] = useState<string[]>([]);

  const pathChecks = useMemo(() => checkCurrentPath(currentPath + locations.hash), [currentPath]);

  const linkType = useMemo(() => {
    if (pathChecks.isTaxonomy) {
      return LinkType.Taxonomy;
    } else if (pathChecks.isEsrs) {
      return LinkType.Esrs;
    } else if (pathChecks.isPai) {
      return LinkType.Pai;
    } else {
      return LinkType.Portfolios;
    }
  }, [pathChecks]);

  const filteredLocations: string[] = useMemo(() => {
    const findLastRelevantPathIndex = (pathToCheck: string, pathsToBreakAt: string[]) => {
      return pathsToBreakAt.reduce((lastIndex, path) => {
        const pathIndex = pathToCheck.toLocaleLowerCase().lastIndexOf(path);
        return pathIndex !== -1 ? pathIndex : lastIndex;
      }, -1);
    };

    const lastIndexToRead = findLastRelevantPathIndex(currentPath, PATHS_TO_BREAK_AT);
    const endIndex = lastIndexToRead !== -1 ? lastIndexToRead : currentPath.length;

    const pathConditions = {
      isEsrsStandard: pathChecks.isEsrsStandard,
      isOnboarding: pathChecks.isOnboarding,
      isChart: pathChecks.isChart,
      isEditTarget: pathChecks.isEditTarget,
      isEditAction: pathChecks.isEditAction,
      isEditTaxonomy: pathChecks.isEditTaxonomy,
    };

    const filteredLocs = currentPath
      .substring(0, endIndex)
      .split('/')
      .slice(2)
      .filter((path) =>
        isValidPath(
          path,
          pathConditions,
          materialStandardId,
          reportingUnitId,
          linkType,
          currentPath
        )
      );

    return filteredLocs;
  }, [pathChecks, currentPath]);

  useEffect(() => {
    const newPaths: string[] = [];

    filteredLocations.forEach((path) => {
      const newPath = currentPath.slice(0, currentPath.lastIndexOf(path)) + path;
      newPaths.push(newPath);
    });

    setPaths(newPaths);
  }, [filteredLocations, currentPath]);

  return (
    <HStack gap="2px" p={noPadding ? '' : '16px 16px 0'} w="fit-content">
      <IconButton
        size="xs"
        variant="ghost"
        icon={<ArrowLeftIcon />}
        onClick={() => {
          if (onBackNavigate) {
            onBackNavigate();
          } else if (onBackButtonClick) {
            onBackButtonClick();
          } else {
            navigate(paths[paths.length - 2]);
          }
        }}
        aria-label={'Back'}
      />
      {filteredLocations.map((path, index) => {
        return (
          <Breadcrumb
            key={path}
            locations={filteredLocations}
            currentPath={currentPath}
            index={index}
            isEditTarget={pathChecks.isEditTarget}
            linkType={linkType}
            path={path}
          />
        );
      })}
    </HStack>
  );
};

import { Box, HStack, VStack, useDisclosure } from '@chakra-ui/react';
import { ActionFieldsFragment_, useEsrsAssessmentActionsQuery } from 'models';
import { useMemo, useState } from 'react';
import { Typography } from 'Tokens/Typography';
import { groupBy, uniqBy } from 'lodash';
import { Loader, SearchInput, Select } from 'Molecules';
import { Button, SearchEmptyState } from 'Atoms';
import { ViewSelector } from './ViewSelector';
import { ActionsTable } from '../DisclosureRequirements/Actions/ActionsTable';
import { useEsrsActions } from './GroupAssessmentOverview.hooks';
import { MaterialTopicFilter } from '../Assessment/EsrsOverview';
import { AddActionDrawer } from '../DisclosureRequirements/Actions';
import {
  useAssessmentReportingUnits,
  useMaterialStandardId,
} from 'containers/Esrs/EsrsAssessment.hooks';
import { useParams } from 'react-router-dom';
import { useCompanyType } from 'utils/hooks';

export const ActionsOverview = ({ esrsAssessmentId }: { esrsAssessmentId: string }) => {
  const [searchValue, setSearchValue] = useState('');
  const [selectedView, setSelectedView] = useState('all');
  const [selectedAction, setSelectedAction] = useState<ActionFieldsFragment_>();
  const { standardRef = '', disclosureRequirementRef = '' } = useParams();
  const { reportingUnitAssessments: reportingUnits } =
    useAssessmentReportingUnits(esrsAssessmentId);
  const { onOpen: onActionOpen, isOpen: isActionOpen, onClose: onActionClose } = useDisclosure();
  const { companyAssessmentId } = useMaterialStandardId(standardRef, esrsAssessmentId);
  const { companyType } = useCompanyType();
  const isGroup = useMemo(() => companyType === 'group-company', [companyType]);

  const [selectedSubsidiary, setSelectedSubsidiary] = useState<string>('All subsidiaries');
  const [showAll, setShowAll] = useState(false);

  const { data: actionsData, loading: actionsLoading } = useEsrsAssessmentActionsQuery({
    variables: {
      esrsAssessmentId,
    },
    skip: !esrsAssessmentId,
  });

  const actions = useEsrsActions(actionsData);

  const filteredActions = useMemo(() => {
    return actions?.filter((action) => {
      const search = action.title.toLowerCase().includes(searchValue.toLowerCase());
      let subsidiary = true;
      if (selectedSubsidiary !== 'All subsidiaries') {
        subsidiary = action.companyName === selectedSubsidiary;
      }

      let materialView = true;
      if (selectedView === MaterialTopicFilter.NOT_MATERIAL) {
        materialView = action.isSubOrParent;
      } else if (selectedView === MaterialTopicFilter.MATERIAL) {
        materialView = !action.isSubOrParent;
      }

      return search && subsidiary && materialView;
    });
  }, [actions, searchValue, selectedSubsidiary, selectedView]);

  const companyOptions = useMemo(() => {
    const companyArray = actions?.map((action) => ({
      label: action.companyName,
      value: action.companyName,
    }));
    return uniqBy(companyArray, (name) => {
      return name.label;
    });
  }, [actions]);

  const groupedActions = useMemo(() => {
    return groupBy(filteredActions, 'category.title');
  }, [filteredActions]);

  if (actionsLoading) {
    return <Loader />;
  }

  return (
    <VStack mt="16px" spacing="24px" w="100%" alignItems="start">
      <VStack spacing="24px" w="100%" alignItems="stretch">
        <VStack spacing="12px" alignItems="start" w="100%">
          <HStack justifyContent="space-between" w="100%">
            <HStack>
              <SearchInput
                search={searchValue}
                setSearch={setSearchValue}
                placeholder="Filter"
                withLeftIcon={false}
                borderColor="border.default"
                minW="200px"
                width="200px"
              />
              <ViewSelector selectedView={selectedView} setSelectedView={setSelectedView} />
              <Select
                width="200px"
                value={{
                  label: selectedSubsidiary,
                  value: selectedSubsidiary,
                }}
                onChange={(val) => setSelectedSubsidiary(val?.value ?? '')}
                options={
                  [
                    {
                      label: 'All subsidiaries',
                      value: 'All subsidiaries',
                    },
                    ...companyOptions,
                  ] ?? []
                }
              />
            </HStack>
          </HStack>
        </VStack>
        {filteredActions?.length === 0 ? (
          <Box w="100%" h="100%">
            <SearchEmptyState setSearch={setSearchValue} />
          </Box>
        ) : (
          <>
            {Object.entries(groupedActions).map((categoryAction, index) => {
              return (
                <VStack key={index} spacing="10px" alignItems="flex-start">
                  <Typography variant="overline" color="text.muted" textTransform="uppercase">
                    {categoryAction[0]}
                  </Typography>
                  <ActionsTable
                    data={!showAll ? categoryAction[1].slice(0, 3) : categoryAction[1]}
                    currentTab={selectedView}
                    isSearch={!!searchValue}
                    onActionOpen={onActionOpen}
                    setSelectedAction={setSelectedAction}
                    isOverview={true}
                  />
                  {categoryAction[1].length > 3 && (
                    <Button variant="ghost" onClick={() => setShowAll(!showAll)}>
                      {showAll ? 'Hide' : 'Show all...'}
                    </Button>
                  )}
                </VStack>
              );
            })}
          </>
        )}
        <AddActionDrawer
          isOpen={isActionOpen}
          onClose={onActionClose}
          actionToEdit={selectedAction}
          disclosureRequirementRef={disclosureRequirementRef}
          reportingUnits={reportingUnits}
          isReadOnly
          isGroup={isGroup}
          materialityAssessmentId={companyAssessmentId}
        />
      </VStack>
    </VStack>
  );
};

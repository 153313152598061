import { captureConsoleIntegration } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { reactRouterV6BrowserTracingIntegration } from '@sentry/react';
import {
  Routes,
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { useEffect } from 'react';

export const ignoreErrorsList = ['Network Error', 'Failed to load resource', 'Failed to fetch'];

export const SentryIntegrations = [
  reactRouterV6BrowserTracingIntegration({
    useEffect,
    useLocation,
    useNavigationType,
    createRoutesFromChildren,
    matchRoutes,
  }),
  captureConsoleIntegration({ levels: ['error'] }),
];
export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

import { VStack, HStack, CircularProgress, Skeleton, Box } from '@chakra-ui/react';
import { Typography } from 'Tokens';
import { CompanyBottomUpIcon, CompanyTopDownIcon } from 'Tokens/Icons/Custom';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useGroupStandardProgress,
  useRegularCompanyStandardProgress,
  useReportingUnitStandardProgress,
} from './StandardProgress.hooks';
import { UNKONWN_ROUTE } from '../../DisclosureRequirements';
import { Tag, Tooltip } from 'Atoms';
import { MaterialStandard } from '../../GroupAssessment/GroupAssessmentOverview.hooks';
import { WarningIcon } from 'Tokens/Icons/Status';

export const StandardProgress = ({
  standard,
  standardIndex,
  categoryIndex,
  standardLength,
  isFlagged,
}: {
  standard: MaterialStandard;
  standardIndex: number;
  categoryIndex: number;
  standardLength: number;
  isFlagged: boolean;
}) => {
  const { esrsAssessmentId } = useParams();

  const { progress, loading } = useRegularCompanyStandardProgress({
    standardRef: standard.standardRef ?? '',
    assessmentId: esrsAssessmentId ?? '',
  });
  const navigate = useNavigate();
  return (
    <VStack
      shadow="interactive"
      bg="bg.elevated.interactive"
      borderRadius="10px"
      p="20px"
      gap="8px"
      alignItems="start"
      minHeight="148px"
      _hover={{
        shadow: 'interactive.hover',
        background: 'bg.elevated.interactive.hover',
      }}
      cursor="pointer"
      key={standard.standardRef}
      onClick={() => {
        navigate(`standard/${standard.standardRef}`);
      }}
      justifyContent="space-between"
      {...(categoryIndex === 0 && (standardLength > 1 ? standardIndex === 1 : standardIndex === 0)
        ? { className: 'walktour-standard-overview' }
        : {})}
    >
      <HStack alignItems="start" justifyContent="space-between" width="100%">
        <VStack spacing="4px" alignItems="start">
          <Typography variant="h4">{standard.standardName}</Typography>
          <Typography variant="detail" color="text.hint">
            {standard.standardRef}
          </Typography>
        </VStack>
        {isFlagged && (
          <Box>
            <Tooltip label="Please review and update targets baseline and milestones values to make sure a correct reporting.">
              <Tag variant="warning" size="xs">
                <WarningIcon color="text.warning" />
              </Tag>
            </Tooltip>
          </Box>
        )}
      </HStack>
      <HStack spacing="8px">
        {standard.isMaterialForGroup && !standard.isMaterialForSubsidiary && (
          <Tag variant="undefined" size="xs">
            <Tooltip label="Assessed as material by a parent company">
              <CompanyTopDownIcon boxSize="16px" />
            </Tooltip>
          </Tag>
        )}
        <Skeleton isLoaded={!loading}>
          <HStack spacing="6px">
            <CircularProgress size="16px" color="text.info" value={Number(progress ?? '0')} />
            <Typography variant="body">{`${progress}%`}</Typography>
          </HStack>
        </Skeleton>
      </HStack>
    </VStack>
  );
};

export const ReportingUnitStandardProgress = ({
  standard,
  standardIndex,
  categoryIndex,
  reportingUnitId,
}: {
  standard: MaterialStandard;
  standardIndex: number;
  categoryIndex: number;
  reportingUnitId: string;
}) => {
  const navigate = useNavigate();
  const { esrsAssessmentId } = useParams();

  const { progress, loading } = useReportingUnitStandardProgress({
    standardRef: standard.standardRef ?? '',
    assessmentId: esrsAssessmentId ?? '',
    reportingUnitId: reportingUnitId,
  });
  return (
    <VStack
      shadow="interactive"
      bg="bg.elevated.interactive"
      borderRadius="10px"
      p="20px"
      gap="8px"
      alignItems="start"
      minHeight="148px"
      _hover={{
        shadow: 'interactive.hover',
        background: 'bg.elevated.interactive.hover',
      }}
      cursor="pointer"
      key={standard.standardRef}
      onClick={() => {
        navigate(
          `bu-standard/${standard.standardRef}/bu/${reportingUnitId}/disclosure-requirement/${UNKONWN_ROUTE}`
        );
      }}
      justifyContent="space-between"
      {...(categoryIndex === 0 && standardIndex === 0
        ? { className: 'walktour-standard-overview' }
        : {})}
    >
      <VStack spacing="4px" alignItems="start">
        <Typography variant="h4">{standard.standardName}</Typography>
        <Typography variant="detail" color="text.hint">
          {standard.standardRef}
        </Typography>
      </VStack>
      <HStack spacing="8px">
        <Tag variant="undefined" size="xs">
          {standard.isMaterialForGroup ? (
            <Tooltip label="Assessed as material by a parent company">
              <CompanyTopDownIcon boxSize="16px" />
            </Tooltip>
          ) : (
            <Tooltip label="Assessed as material by this company">
              <CompanyBottomUpIcon boxSize="16px" />
            </Tooltip>
          )}
        </Tag>
        <Skeleton isLoaded={!loading}>
          <HStack spacing="6px">
            <CircularProgress size="16px" color="text.info" value={Number(progress ?? '0')} />
            <Typography variant="body">{`${progress}%`}</Typography>
          </HStack>
        </Skeleton>
      </HStack>
    </VStack>
  );
};

export const GroupStandardProgress = ({
  standard,
  standardIndex,
  categoryIndex,
}: {
  standard: MaterialStandard;
  standardIndex: number;
  categoryIndex: number;
}) => {
  const navigate = useNavigate();
  const { esrsAssessmentId } = useParams();
  const { progress, loading } = useGroupStandardProgress({
    standardRef: standard?.standardRef,
    assessmentId: esrsAssessmentId ?? '',
  });
  return (
    <VStack
      shadow="interactive"
      bg="bg.elevated.interactive"
      borderRadius="10px"
      p="20px"
      gap="8px"
      alignItems="start"
      minHeight="148px"
      _hover={{
        shadow: 'interactive.hover',
        background: 'bg.elevated.interactive.hover',
      }}
      cursor="pointer"
      key={standard.standardRef}
      onClick={() => {
        navigate(`standard/${standard.standardRef}`);
      }}
      justifyContent="space-between"
      {...(categoryIndex === 0 && standardIndex === 0
        ? { className: 'walktour-standard-overview' }
        : {})}
    >
      <VStack spacing="2px" alignItems="start">
        <Typography variant="h4">{standard.standardName}</Typography>
        <Typography variant="detail" color="text.hint">
          {standard.standardRef}
        </Typography>
      </VStack>
      <HStack spacing="10px">
        {!standard.isMaterialForGroup && standard.isMaterialForSubsidiary && (
          <Tag variant="undefined" size="xs">
            <Tooltip label="Not material for the group, but material for one or several subsidiaries">
              <CompanyBottomUpIcon boxSize="16px" />
            </Tooltip>
          </Tag>
        )}
        <Skeleton isLoaded={!loading}>
          <HStack spacing="6px">
            <CircularProgress size="16px" color="text.info" value={Number(progress ?? '0')} />
            <Typography variant="body">{`${progress}%`}</Typography>
          </HStack>
        </Skeleton>
      </HStack>
    </VStack>
  );
};

import { GetEsrsCategoriesQuery_ } from 'models';
import { DataCollectionLevel } from './pieces/DataCollection';
import { QuartersEnums } from './pieces/DisclosureRequirements/Requirement';

export const getIsWithinYearRange = (startYear: number, endYear: number, selectedYear: number) => {
  const start = new Date(startYear).getFullYear();
  const end = new Date(endYear).getFullYear();

  const isWithinSelectedYearRange =
    selectedYear === 0 ||
    (start >= selectedYear && start <= selectedYear) ||
    (end >= selectedYear && end <= selectedYear) ||
    (start < selectedYear && end > selectedYear);
  return isWithinSelectedYearRange;
};

export const mapUnitToCompanyCurrency = (
  unit: string | undefined | null,
  companyCurrency: string
) => {
  if (!unit) return 'NA';
  if (unit === 'currency') {
    return companyCurrency;
  } else if (unit.includes('Monetary unit')) {
    return unit.replace(/Monetary unit/g, companyCurrency);
  } else if (unit.includes('€')) {
    return unit.replace(/€/g, companyCurrency);
  } else return unit;
};

export const createGraphObject = (
  isYearly: boolean,
  q1: number,
  q2: number,
  q3: number,
  q4: number,
  year: number
) => {
  return isYearly
    ? {
        [QuartersEnums.q1]: 0,
        [QuartersEnums.q2]: 0,
        [QuartersEnums.q3]: 0,
        [QuartersEnums.q4]: 0,
        Year: year,
      }
    : {
        [QuartersEnums.q1]: q1,
        [QuartersEnums.q2]: q2,
        [QuartersEnums.q3]: q3,
        [QuartersEnums.q4]: q4,
      };
};

export const stringToNumber = (value: string | null | undefined) => {
  if (value == null && value == undefined) {
    return value;
  }
  return Number(value);
};

export const getDataCollectionText = (type: string) => {
  if (type === DataCollectionLevel.company) return 'Company level';
  else if (type === DataCollectionLevel.reportingUnits) return 'Business units';
  else if (type === DataCollectionLevel.group) return 'Parent company';
  else if (type === DataCollectionLevel.subsidiaries) return 'Subsidiaries';
  return 'NA';
};

export const isMetricStateMaterial = (isMaterial: null | boolean | undefined) => {
  // We should always consider a metric as material if its true or null, but not if its false
  if (isMaterial === undefined) return false;
  return isMaterial !== false;
};

export const orderCategories = (
  categories: GetEsrsCategoriesQuery_['EsrsCategory']
): GetEsrsCategoriesQuery_['EsrsCategory'] => {
  const orderedRefs = ['general', 'Environment', 'social', 'governance'];
  const unorderedCategories = [...categories];
  const orderedCategories = unorderedCategories.sort((a, b) => {
    return orderedRefs.indexOf(a.reference) - orderedRefs.indexOf(b.reference);
  });
  return orderedCategories;
};

export const percentageToNumber = (percentage: number | undefined, number: number) => {
  const percentageValue = Number(percentage) ?? 0;
  const numberValue = Number(number);
  const result =
    (percentageValue > 0
      ? numberValue + (numberValue * percentageValue) / 100
      : numberValue - (numberValue * Math.abs(percentageValue)) / 100) ?? 0;
  return Number(result.toFixed(2));
};

export const numberToPercentage = (baseline: number, number: number) => {
  if (baseline === 0) return 0;
  const percentage = ((number - baseline) / baseline) * 100;
  return Number(percentage);
};

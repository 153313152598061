import { Modal } from 'Molecules';
import { useMemo, useState } from 'react';
import { VStack, HStack, Collapse } from '@chakra-ui/react';
import { Checkbox, DateInput, FormField } from 'Atoms';
import { Typography } from 'Tokens';
import {
  useDuplicateAssessment,
  DuplicateAssessmentOptions,
  defaultDuplicationOptions,
  getInvestorsByReportingPeriods,
  useCompanyAssessmentsMapByReportingPeriod,
} from '../Assessments.hooks';
import { useTranslation } from 'utils/translation';
import { formatQuarterYearToDate } from 'utils/date';
import { useNavigate } from 'react-router-dom';
import { useCurrentCompanyId, useToast } from 'utils/hooks';
import { captureException } from '@sentry/react';
import { useCompanyAssessmentsQuery, useInvestorsQuery } from 'models';
import { useShareAssessmentWithInvestors } from './Assessment.hooks';

export const SelectionItem = ({
  title,
  isChecked,
  onChange,
  isDisabled,
}: {
  title: string;
  isChecked: boolean;
  onChange: (val: boolean) => void;
  isDisabled?: boolean;
}) => {
  return (
    <HStack spacing="8px">
      <Checkbox
        isDisabled={isDisabled}
        isChecked={isChecked}
        onChange={(e) => onChange(e.target.checked)}
      />
      <Typography variant="body">{title}</Typography>
    </HStack>
  );
};

export const DuplicateAssessmentModal = ({
  id,
  isOpen,
  onClose,
  startDate,
  withYearSelector = true,
}: {
  id: string;
  startDate: string;
  onClose: () => void;
  isOpen: boolean;
  withYearSelector?: boolean;
}) => {
  const duplicateAssessment = useDuplicateAssessment();

  const toast = useToast();
  const [type, setType] = useState<'custom' | 'standard'>('standard');
  const [options, setOptions] = useState<DuplicateAssessmentOptions>(defaultDuplicationOptions);
  const { t } = useTranslation(['common', 'assessment']);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { companyId } = useCurrentCompanyId();

  const [newStartDate, setNewStartDate] = useState(() => {
    const [quarter, year] = startDate.split('-');
    return formatQuarterYearToDate(quarter, year);
  });

  const { data: investorsData } = useInvestorsQuery({
    variables: {
      companyId: companyId,
    },
    skip: !companyId,
  });

  const { data: assessmentsData } = useCompanyAssessmentsQuery({
    variables: {
      companyId,
    },
    skip: !companyId,
  });

  const assessments = useMemo(() => {
    return assessmentsData?.assessments ?? [];
  }, [assessmentsData]);

  const investors = useMemo(() => {
    return investorsData?.investors ?? [];
  }, [investorsData]);

  const investorsByReportingPeriod = useMemo(() => {
    return getInvestorsByReportingPeriods(investors, startDate ?? '');
  }, [investors, startDate, assessmentsData]);

  const { assessments: assessmentsMap } = useCompanyAssessmentsMapByReportingPeriod(
    assessments,
    investorsByReportingPeriod
  );
  const shareAssessmentWithInvestors = useShareAssessmentWithInvestors();

  const onConfirm = async () => {
    if (!isLoading) {
      setIsLoading(true);

      try {
        const res = await duplicateAssessment(id, newStartDate, options);

        await shareAssessmentWithInvestors(
          newStartDate,
          investors,
          undefined,
          assessmentsMap,
          undefined,
          res?.data?.cAssessment?.id
        );

        setIsLoading(false);
        onClose();
        if (res?.data?.cAssessment?.id) {
          navigate(`${res?.data?.cAssessment.id}`);
        } else {
          toast({
            text: 'Failed to duplicate assessment',
            variant: 'danger',
          });
        }
      } catch (error) {
        console.log({ error });
        captureException(error, {
          extra: { errorMessage: 'Failed to duplicate assessment' },
        });
        setIsLoading(false);
        toast({
          text: 'Failed to duplicate assessment',
          variant: 'danger',
        });
      }
    }
  };

  return (
    <Modal
      title={t('assessment:duplicateAssessmentModal.modal.header')}
      onClose={onClose}
      isOpen={isOpen}
      confirmText={t('common:button.confirm')}
      onConfirm={onConfirm}
      confirmButtonProps={{ isLoading }}
    >
      <VStack width="100%" alignItems="stretch" spacing="24px">
        {withYearSelector && (
          <VStack>
            <FormField id="newStartDate" label="Select reporting period">
              <DateInput
                value={newStartDate}
                setValue={(date) => setNewStartDate(date ?? new Date())}
                showYearPicker
                dateFormat="yyyy"
              />
            </FormField>
          </VStack>
        )}
        <FormField id="duplicationType" label="Select how you want to duplicate your report">
          <VStack spacing="8px">
            <VStack
              borderColor={type === 'standard' ? 'border.selected.accent' : 'border.default'}
              borderWidth={type === 'standard' ? '2px' : '1px'}
              padding="16px"
              borderRadius="8px"
              spacing="8px"
              cursor="pointer"
              _hover={{
                bg: 'bg.hover',
              }}
              onClick={() => {
                setType('standard');
                setOptions(defaultDuplicationOptions);
              }}
              alignItems="flex-start"
            >
              <Typography variant="h3">
                {t('assessment:duplicateAssessmentModal.modal.typography.standard.title')}
              </Typography>
              <Typography variant="body">
                {t('assessment:duplicateAssessmentModal.modal.typography.standard.body')}
              </Typography>
            </VStack>
            <VStack
              borderColor={type === 'custom' ? 'border.selected.accent' : 'border.default'}
              borderWidth={type === 'custom' ? '2px' : '1px'}
              padding="16px"
              borderRadius="8px"
              spacing="8px"
              cursor="pointer"
              _hover={{
                bg: 'bg.hover',
              }}
              onClick={() => setType('custom')}
              alignItems="flex-start"
            >
              <Typography variant="h3">
                {t('assessment:duplicateAssessmentModal.modal.typography.custom.title')}
              </Typography>
              <Typography variant="body">
                {t('assessment:duplicateAssessmentModal.modal.typography.custom.body')}
              </Typography>
              <Collapse in={type === 'custom'}>
                <VStack spacing="20px" alignItems="flex-start">
                  <VStack spacing="12px" alignItems="flex-start">
                    <SelectionItem
                      title={t('assessment:duplicateAssessmentModal.selection.selectEverything')}
                      isChecked={options === defaultDuplicationOptions}
                      onChange={() => setOptions(defaultDuplicationOptions)}
                    />
                  </VStack>
                  <VStack spacing="12px" alignItems="flex-start">
                    <Typography variant="detailStrong">
                      {t('assessment:duplicateAssessmentModal.modal.typography.companySetup')}
                    </Typography>
                    <SelectionItem
                      title={t('assessment:duplicateAssessmentModal.selection.reportingStructure')}
                      isChecked={options.withStructure}
                      onChange={(val) =>
                        setOptions((prev) => ({
                          ...prev,
                          withStructure: val,
                        }))
                      }
                    />
                    <SelectionItem
                      title={t('assessment:duplicateAssessmentModal.selection.companyActivities')}
                      isChecked={options.withActivities}
                      onChange={(val) =>
                        setOptions((prev) => ({
                          ...prev,
                          withActivities: val,
                        }))
                      }
                    />{' '}
                  </VStack>
                  <VStack spacing="12px" alignItems="flex-start">
                    <Typography variant="detailStrong">
                      {t('common:assessment.screening')}
                    </Typography>
                    <SelectionItem
                      title={t('assessment:duplicateAssessmentModal.selection.all')}
                      isChecked={
                        options.withAnswers &&
                        options.withComments &&
                        options.withDocuments &&
                        options.withNotes
                      }
                      onChange={(val) =>
                        setOptions((prev) => ({
                          ...prev,
                          withAnswers: val,
                          withComments: val,
                          withDocuments: val,
                          withNotes: val,
                        }))
                      }
                    />

                    <SelectionItem
                      title={t('assessment:duplicateAssessmentModal.selection.allAnswers')}
                      isChecked={options.withAnswers}
                      onChange={(val) =>
                        setOptions((prev) => ({
                          ...prev,
                          withAnswers: val,
                        }))
                      }
                    />

                    <SelectionItem
                      title={t('assessment:duplicateAssessmentModal.selection.documents')}
                      isChecked={options.withDocuments}
                      onChange={(val) =>
                        setOptions((prev) => ({
                          ...prev,
                          withDocuments: val,
                        }))
                      }
                    />

                    <SelectionItem
                      title={t('assessment:duplicateAssessmentModal.selection.notes')}
                      isChecked={options.withNotes}
                      onChange={(val) =>
                        setOptions((prev) => ({
                          ...prev,
                          withNotes: val,
                        }))
                      }
                    />

                    <SelectionItem
                      title={t('assessment:duplicateAssessmentModal.selection.comments')}
                      isChecked={options.withComments}
                      onChange={(val) =>
                        setOptions((prev) => ({
                          ...prev,
                          withComments: val,
                        }))
                      }
                    />
                  </VStack>
                  <VStack spacing="12px" alignItems="flex-start">
                    <Typography variant="detailStrong">
                      {t('common:assessment.financials')}
                    </Typography>
                    <SelectionItem
                      title={t('assessment:duplicateAssessmentModal.selection.financialNumbers')}
                      isChecked={options.withFinancials}
                      onChange={(val) =>
                        setOptions((prev) => ({
                          ...prev,
                          withFinancials: val,
                        }))
                      }
                    />
                  </VStack>
                </VStack>
              </Collapse>
            </VStack>
          </VStack>
        </FormField>
      </VStack>
    </Modal>
  );
};
